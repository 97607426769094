
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Sidebar from "../global/Sidebar";
import Topbar2 from "../global/Topbar2";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Form from "react-bootstrap/Form";
import YearPicker from "../Student_Exit.jsx/yearPicker"

import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "../student-login-page/feedback.css"
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';







import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import Rating, { IconContainerProps } from '@mui/material/Rating';

import { makeStyles } from '@material-ui/core/styles';

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));


  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  
  const useStyles = makeStyles({
    customButton: {
      borderRadius: '50%',
    },
  });
  
 

  const Questionset_api=[
    {Ques_id:0,Question:"The training met my expectations/(பயிற்சி எனது எதிர்பார்ப்பை   பூர்த்தி செய்கிறது)"},
    {Ques_id:1,Question:"I will be able to apply the knowledge learned/(நான் கற்ற அறிவைப் பயன்படுத்த முடிகிறது)  "},
    {Ques_id:2,Question:"The training objectives for each topic were identified and followed/(ஒவ்வொரு தலைப்புக்கும் பயிற்சி நோக்கங்கள் கண்டறியப்பட்டு பின்பற்றபட்டன) "},
    {Ques_id:3,Question:"The content was organized and easy to follow/(உள்ளடக்கம் ஒழுங்கமைக்கப்பட்டு பின்பற்ற எளிதானது) "},
    {Ques_id:4,Question:"The material distributed was pertinent and useful/(விநியோகிக்கப்பட்ட  புத்தகங்கள் பொருத்தமானதாகவும் பயனுள்ளதாகவும் இருந்தது)"},
    {Ques_id:5,Question:"The trainer was knowledgeable/(பயிற்சியாளர் திறமை)     "},
    {Ques_id:6,Question:"The quality of instruction was good/(போதனையின் தரம் நன்றாக இருந்தது)"},
    {Ques_id:7,Question:"The trainer met the training objectives/(பயிற்சியாயளர் பயிற்சி நோக்கங்களை பூர்த்தி செய்தார்)"},
    {Ques_id:8,Question:"Class participation and interaction were encouraged/(வகுப்பில் பங்கேற்பது மற்றும் உடனுக்குடன் சந்தேகங்களை கேட்பதற்கு ஊக்குவிக்கப்பட்டது)"},
    {Ques_id:9,Question:" Adequate time was provided for questions and discussion/(விளக்கங்கள்  மற்றும் விவாதங்களுக்கு போதுமான நேரம் வழங்கப்பட்டது) "},
    {Ques_id:10,Question:"Coverage is Enough/(பயிற்சி போதுமானதாக இருந்தது)"},
    {Ques_id:11,Question:"How do you rate the training overall?/(பயிற்சியை ஒட்டுமொத்தமாக எப்படி மதிப்பீடுகிறீர்கள்?) "},
   
  
   
     
  ]

 

// --------------------MONTHLY VIEW TABLE--------------------------//
const Placement = () => {


 const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [themes, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
     
     const isNonMobile = useMediaQuery("(min-width:600px)");
    
     let user_details = localStorage.getItem("user_details");
    

    


     const [selectedMenu, setSelectedMenu] = useState('');
     const [selectedYear1, setSelectedYear1] = useState('');
   
     const handleChangeMenu = (event) => {
       setSelectedMenu(event.target.value);
       setSelectedYear1('');
     };
   
     const handleChangeYear = (event) => {
       setSelectedYear1(event);
     };
   
     
     const menuOptions = [
      { label: '2000-2010', subMenu: Array.from({ length: 11 }, (_, index) => 2000 + index) },
      { label: '2011-2020', subMenu: Array.from({ length: 10 }, (_, index) => 2011 + index) },
      { label: '2021-2030', subMenu: Array.from({ length: 10 }, (_, index) => 2021 + index) },
      { label: '2031-2040', subMenu: Array.from({ length: 10 }, (_, index) => 2031 + index) },
      { label: '2041-2050', subMenu: Array.from({ length: 10 }, (_, index) => 2041 + index) },
      { label: '2051-2060', subMenu: Array.from({ length: 10 }, (_, index) => 2051 + index) },
      { label: '2061-2070', subMenu: Array.from({ length: 10 }, (_, index) => 2061 + index) },
      { label: '2071-2080', subMenu: Array.from({ length: 10 }, (_, index) => 2071 + index) },
      { label: '2081-2090', subMenu: Array.from({ length: 10 }, (_, index) => 2081 + index) },
      { label: '2091-3000', subMenu: Array.from({ length: 10 }, (_, index) => 2091 + index) },

      // Add more menu options as needed
    ];
  
   
     // Generate a list of years
     const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() + index);
     const questionsPerPage = 7;
  
     const [currentPage, setCurrentPage] = useState(1);
     const indexOfLastQuestion = currentPage * questionsPerPage;
     const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
     const [currentQuestions,setCurrentQuestions]=useState([]);
     const [totalPages,setTotalPages]=useState();
    
     const classes = useStyles();


     


     
     if (user_details) {
      user_details = JSON.parse(user_details);
      console.log(user_details.Email);
    } else {
      console.log("user_details not found in local storage");
    }
    // const [selectedYear1, setSelectedYear1] = useState(null);

    // const handleYearChange = (date) => {
    //   setSelectedYear1(date.getFullYear());
    // };

   
    const [graduate,setGraduate]=useState("");
    const [branch,setBranch]=useState("");
  const [ratings, setRatings] = useState({});
  const [ratings2, setRatings2] = useState({});
  const [staff,setStaff]=useState("");
  const [staffname,setStaffName]=useState("");
  const [lab,setLab]=useState("");
  const [date,setDate]=useState("")
  const [comment,setComment]=useState("")
  const [training,setTraining]=useState("")
  const [batch,setBatch]=useState("")
  const [topic,setTopic]=useState("")
  const [trainer,setTrainer]=useState("")
  const [session,setSession]=useState("")
   const [time,setTime]=useState("")
   const [aspect,setAspectS]=useState("")
   const [help,setHelp]=useState("")
   const [adequate,setAdequate]=useState("")
   const [questionLength,setQuestionLength]=useState();

  useEffect(()=>{
    const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
    setCurrentQuestions(currentQuestions_1);
    const totalPages_1 = Math.ceil(Questionset_api.length / questionsPerPage);
    setTotalPages(totalPages_1)
    setQuestionLength(Questionset_api.length)
  
  },[]);
  
  


          const handleStaff = (event) => {
            setStaff(event)
         
        
          };
          const handleAspect = (event) => {
            setAspectS(event)
         
        
          };
          const handleHelp = (event) => {
            setHelp(event)
         
        
          };
          const handleAdequate = (event) => {
            setAdequate(event)
         
        
          };
          
          
          const handleSession= (event) => {
            setSession(event)
         
        
          };
          
          const handleTime= (event) => {
            setTime(event)
         
        
          };
          const handleTraining = (event) => {
            setTraining(event)
         
        
          };
          const handleTopic = (event) => {
            setTopic(event)
         
        
          };
          const handleTrainer = (event) => {
            setTrainer(event)
         
        
          };
          const handleBatch = (event) => {
            setBatch(event)
         
        
          };
          const handleComment = (event) => {
            setComment(event)
         
        
          };
          
          
          const handleDate = (event) => {
            setDate(event)
         
        
          };
          
          const handleStaffName = (event) => {
            setStaffName(event)
         
        
          };
          const handleLab= (event) => {
            setLab(event)
         
        
          };
          const handleProgramme = (event) => { 
         
        
          };
          
        
          const handleGraduate=(event)=>{
            setGraduate(event)
          }
          const handlebranch=(event)=>{
            setBranch(event)
          }

          

          const handleRatingChange = (quesId, value) => {
           
            
            const questionKey = `q${quesId + 1}`;
            setRatings((prevRatings) => ({
              ...prevRatings,
              [questionKey]: value,
            }));

            setRatings2((prevRatings2)=>({
              ...prevRatings2,
              [quesId]: value,
            }))
            console.log(ratings, "ratingsss");
          };
        

        
        

         const handleSubmit=()=>{
       


          const isAnyRatingNull = Object.values(ratings).some((rating) => rating === "");
          // alert(isAnyRatingNull)


          let nullRating=true;
  
          // Loop through the questions from q1 to q16
          for (let i = 1; i <= questionLength; i++) {
            const question = "q" + i;
            const rating = ratings[question];
            if (rating==undefined){
              nullRating=false;
              break
            }
          }
          
      if(training!=""){
        if(batch!=""){
          if(branch!=""){
            if(topic!=""){
              if(trainer!=""){
                // if(selectedYear1!=""){
                  if(date!=""){
                    if(session!=""){
                      if(time!=""){
                        if(nullRating){
 
          
          fetch('https://feedbackapi.rcktechiees.com/api/student/placement_training', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              "name_training":(training),
              "batch":(batch),
          "year":(selectedYear1),
          "session":(session),
          "time":(time),
          "topic":(topic),
          "name_of_trainer":(trainer),
          "branch": (branch),
          "date":(date),
          "aspects":(aspect),
          "help":(help),
          "comments":(comment),
          
          ...ratings
          
         

            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data,"responseupload")}
            if(data["Status"]=="1"){
              toast.success("Successfully Submitted")
            }
            else if(data["Status"]=="0"){
              toast.error("Something went wrong")
            }
   
          
          
          })
           .catch(error=>console.log(error))
        }
        else{
          toast.error("Please fill all the rating")
        }
        }
        else{
          toast.error("Please fill the time")
        }
        }
        else{
          toast.error("Please fill the session")
        }
        }
        else{
          toast.error("Please fill the date")
        }
        // }
        // else{
        //   toast.error("Please fill the year")
        // }
        }
        else{
          toast.error("Please fill the trainer name")
        }

        }
        else{
          toast.error("Please fill the topic")
        }
        }
        else{
          toast.error("Please choose your branch")
        }
        }
        else{
          toast.error("Please fill the batch")
        }
        
        }
        else{
          toast.error("Please fill the training name")
        }

          //  toast.error("Something went wrong")


         
        }
 
         const handlePageChange = (pageNumber) => {
         
          setCurrentPage(pageNumber);
  const indexOfLastQuestion = pageNumber * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
  setCurrentQuestions(currentQuestions_1);
        };
      
      
         const Pagination = ({ currentPage, totalPages, onPageChange }) => {
          const pageNumbers = [];
      
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
      
          return (

         
          
            <Box
           
            display="flex" justifyContent="center" mt="20px"
           
          >
              {pageNumbers.map((pageNumber) => (
                 <Button variant="contained"
                  key={pageNumber}
                  onClick={() => onPageChange(pageNumber)}
                  disabled={currentPage === pageNumber}
                  className={classes.customButton}
                  sx={{  justifyContent: "center"}}
   
                >
                  {pageNumber}
                  </Button>
              ))}
            </Box>
          );
        };
      
      
      
      
      
      
      


  return (
   <>
      <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <div className="app1">
                <Sidebar isSidebar={isSidebar} feedback_type={"placement"}/>
                <main className="content1">
                    <Topbar2 setIsSidebar={setIsSidebar} feedback_type={"Placement Feedback"}/>
 

                    <Box m="20px"
       display="grid"
       gap="7px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      //  sx={{
      //    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      //  }
      //  }
       >

              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Name of the Training "
                   value={training}             
                  
                  
                   onChange={(event) => {handleTraining(event.target.value)}}
                   name="training"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Batch "
                   value={batch}             
                  
                  
                   onChange={(event) => {handleBatch(event.target.value)}}
                   name="Batch"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
               <FormControl variant="filled" sx={{ gridColumn: "span 1"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={branch}
          onChange={(e) => {handlebranch(e.target.value)}}
          sx={{
            height: '42px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          <MenuItem value={"Civil"}>Civil</MenuItem>
          <MenuItem value={"Mechanical"}>Mechanical</MenuItem>
          <MenuItem value={"ECE"}>ECE</MenuItem>
          <MenuItem value={"EEE"}>EEE</MenuItem>
          <MenuItem value={"CSE"}>CSE</MenuItem>
         
        </Select>
      </FormControl>
                  <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Topic"
                   value={topic}             
                  
                  
                   onChange={(event) => {handleTopic(event.target.value)}}
                   name="topic"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />



    <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Name of the Trainer"
                   value={trainer}
                  

                   onChange={(e) => {handleTrainer(e.target.value)}}
                   name="Trainer Name"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                     
      {/* <FormControl variant="filled" sx={{ gridColumn: "span 1"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
      <InputLabel id="demo-simple-select-filled-label">Select Year</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={selectedMenu}
        onChange={handleChangeMenu}
        style={{ height: '42px' }} 
        
      >
        {menuOptions.map((option, index) => (
          <MenuItem key={index} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {selectedMenu && (
        <Select
          value={selectedYear1}
          onChange={handleChangeYear}
        
        >
          {menuOptions.find((option) => option.label === selectedMenu).subMenu.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl> */}

    {/* <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Year"
                   value={selectedYear1}
                 

                   onChange={(e) => {handleChangeYear(e.target.value)}}
                   name="Date"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              /> */}
              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Date"
                   value={date}
                 

                   onChange={(e) => {handleDate(e.target.value)}}
                   name="Date"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Session"
                   value={session}
                 

                   onChange={(e) => {handleSession(e.target.value)}}
                   name="Session"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Time: from ________ to_________"
                   value={time}
                 

                   onChange={(e) => {handleTime(e.target.value)}}
                   name="Time"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                
            

     
     
    
   
                <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" ,paddingTop:"1%",paddingBottom:"1%"}} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>


      {isNonMobile?
      <div className="stars" style={{display: 'flex', gap: '10px'}}>
      <div className="">
      <h6>5 - Excellent</h6>
      </div>
      <div className="">
      <h6>4 - Satisfied</h6>
      </div>
      <div className="">
      <h6>3 - Good</h6>
      </div>
      <div className="">
      <h6>2 - Not Satisfied</h6>
      </div>
      <div className="">
      <h6>1 - Poor</h6>
      </div>
      </div>:

<div className="stars" style={{display: 'block', gap: '10px'}}>
<div className="">
<h6>5 - Excellent</h6>
</div>
<div className="">
<h6>4 - Satisfied</h6>
</div>
<div className="">
<h6>3 - Good</h6>
</div>
<div className="">
<h6>2 - Not Satisfied</h6>
</div>
<div className="">
<h6>1 - Poor</h6>
</div>
</div>
      
      }
      <h6 style={{fontSize: '18px', fontWeight: '600'}}>Please give your valuable feedback in a scale</h6>
      {/* <h6 style={{fontSize: '15px'}}> &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest</h6> */}
        
      
      </FormControl>

      {currentQuestions.map((item) => {
  const questionParts = item.Question.split("/");
  const firstPart = questionParts.shift(); 
  return (
    <FormControl variant="filled" sx={{ gridColumn: 'span 4' }} key={item.Ques_id}>
    
      <div className="forform" style={{ gap: '15px'}}>
            <div className="col-md-7 col-lg-7" >
              <div className="English-fnt" style={{fontSize: '16px', lineHeight: '2'}}>
            {/* <Typography variant="body1"> */}
        {`${item.Ques_id + 1}. ${firstPart}`}
      {/* </Typography> */}
       </div>
      {questionParts.map((part, index) => (
          // <Typography key={index} variant="body1" style={{ whiteSpace: 'pre-line' }}>
           <div className="Tamil-fnt" style={{fontSize: '15px', lineHeight: '2'}}>
          {part}
          </div>
        //  {/* </Typography> */}
      ))}

</div>
<div className="col">
              <div className="forinsidestar" style={{display: 'flex', gap: '7px'}}>
                <div className="col">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
    
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '1' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '1')}
            >
              <i
                className={`bi bi-1-circle${ratings2[item.Ques_id] === '1' ? ' selected' : ''}`}
                aria-label="1"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '2' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '2')}
            >
              <i
                className={`bi bi-2-circle${ratings2[item.Ques_id] === '2' ? ' selected' : ''}`}
                aria-label="2"
              />
         
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '3' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '3')}
            >
              <i
                className={`bi bi-3-circle${ratings2[item.Ques_id] === '3' ? ' selected' : ''}`}
                aria-label="3"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '4' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '4')}
            >
              <i
                className={`bi bi-4-circle${ratings2[item.Ques_id] === '4' ? ' selected' : ''}`}
                aria-label="4"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '5' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '5')}
            >
              <i
                className={`bi bi-5-circle${ratings2[item.Ques_id] === '5' ? ' selected' : ''}`}
                aria-label="5"
              />
             
             </div>
            </div>
                </div>
                <div className="col">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{fontSize: '18px', color: "red"}}>{ratings2[item.Ques_id] === '1' && <Box>{labels[0]}</Box>}</div>
      <div   style={{fontSize: '18px', color: "#75746f"}}>{ratings2[item.Ques_id] === '2' && <Box>{labels[1]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#0039e6"}}>{ratings2[item.Ques_id] === '3' && <Box>{labels[2]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#e5a004"}}>{ratings2[item.Ques_id] === '4' && <Box>{labels[3]}</Box>}</div>
       <div  style={{fontSize: '18px', color: "#03b634"}}>{ratings2[item.Ques_id] === '5' && <Box>{labels[4]}</Box>}</div>
     
       </div>
                </div>
              
            
            
            </div>
            </div>
          </div>
          
     
    </FormControl>
  );
})} 
       {currentPage==totalPages && (
        
        <TextField
        fullWidth
        variant="filled"
        type="text1"
        label="What aspects of the training could be improved?"
        value={aspect}
      
  
        onChange={(e) => {handleAspect(e.target.value)}}
        name="Aspect"
        sx={{ gridColumn: "span 2",
        "& .MuiInputBase-root.MuiFilledInput-root": {

                                border: "1px solid grey",
          
                                borderRadius: "3px",
          
                                backgroundColor: "transparent",
          
                              },
          
                              "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
          
                                backgroundColor: "transparent",
          
                                borderColor: "#01134d",
          
                              },
          
                              "& .MuiInputBase-root.MuiFilledInput-root:hover": {
          
                                backgroundColor: "transparent",
          
                                borderColor: "#01134d",
          
                              },
          
                              "& .MuiInputBase-root.MuiFilledInput-root:before": {
          
                                borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
          
                              },
          
                              "& .MuiInputBase-root.MuiFilledInput-root:after": {
          
                                borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
          
                              },
      }}
        inputProps={{
         style: {
           height: "9px",
         },
       }}
   />
      )} 
       {currentPage==totalPages && (
        <FormControl variant="filled" sx={{ gridColumn: "span 2"  }} inputProps={{
            style: {
              height: "9px",
            },
          }}>
<InputLabel id="demo-simple-select-filled-label">Was the Training Adequate?</InputLabel>
<Select
required
  labelId="demo-simple-select-filled-label"
  id="demo-simple-select-filled"
  value={adequate}
  onChange={(e) => {handleAdequate(e.target.value)}}
  sx={{
    height: '42px',
    '&.MuiFilledInput-root': {
      border: '1px solid grey',
      borderRadius: '3px',
      backgroundColor: 'transparent',
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        borderColor: '#01134d',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#01134d',
      },
      '&::before': {
        borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
      },
      '&::after': {
        borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
      },
    },
  }}
 

>

  <MenuItem value={"yes"}>Yes</MenuItem>
  <MenuItem value={"no"}>No</MenuItem>
  
 
</Select>
</FormControl>
      )} 
       {currentPage==totalPages && (
        
        <TextField
        fullWidth
        variant="filled"
        type="text1"
        label="Any extra help needed"
        value={help}
      
  
        onChange={(e) => {handleHelp(e.target.value)}}
        name="Help"
        sx={{ gridColumn: "span 2",
        "& .MuiInputBase-root.MuiFilledInput-root": {

                                border: "1px solid grey",
          
                                borderRadius: "3px",
          
                                backgroundColor: "transparent",
          
                              },
          
                              "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
          
                                backgroundColor: "transparent",
          
                                borderColor: "#01134d",
          
                              },
          
                              "& .MuiInputBase-root.MuiFilledInput-root:hover": {
          
                                backgroundColor: "transparent",
          
                                borderColor: "#01134d",
          
                              },
          
                              "& .MuiInputBase-root.MuiFilledInput-root:before": {
          
                                borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
          
                              },
          
                              "& .MuiInputBase-root.MuiFilledInput-root:after": {
          
                                borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
          
                              },
      }}
        inputProps={{
         style: {
           height: "9px",
         },
       }}
   />
      )} 
       {currentPage==totalPages && (
        
      <TextField
      fullWidth
      variant="filled"
      type="text1"
      label="Comment"
      value={comment}
    

      onChange={(e) => {handleComment(e.target.value)}}
      name="Comment"
      sx={{ gridColumn: "span 2",
      "& .MuiInputBase-root.MuiFilledInput-root": {

                              border: "1px solid grey",
        
                              borderRadius: "3px",
        
                              backgroundColor: "transparent",
        
                            },
        
                            "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
        
                              backgroundColor: "transparent",
        
                              borderColor: "#01134d",
        
                            },
        
                            "& .MuiInputBase-root.MuiFilledInput-root:hover": {
        
                              backgroundColor: "transparent",
        
                              borderColor: "#01134d",
        
                            },
        
                            "& .MuiInputBase-root.MuiFilledInput-root:before": {
        
                              borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        
                            },
        
                            "& .MuiInputBase-root.MuiFilledInput-root:after": {
        
                              borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        
                            },
    }}
      inputProps={{
       style: {
         height: "9px",
       },
     }}
 />
    )} 

      {currentPage==totalPages && (
      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>

<Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'},}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button}`} 
   onClick={handleSubmit}>
                          Submit
                          </Button> 
                          <ToastContainer />
                      {/* </Box> 
              <ToastContainer /> */}
    </Box>
    </FormControl>
    )} 

<FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>
   {console.log(currentPage,"cureent",totalPages)}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
     </FormControl>
     
             
    </Box>   
    </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
 
  
   </>
  );
}
export default Placement;
