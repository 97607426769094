import React, { useState } from "react"
import "./header.css"
import logo from './Logo-ACET.png'; // with import
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
// import logo from "../pic/accet_bg.png"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ColorModeContext,tokens } from "../../../theme";
import { useContext } from "react";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = "light";
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};
const Header = () => {
  // fixed Header
  const theme = useTheme();
  const colors = "light";
  const colorMode = useContext(ColorModeContext);


// Remove the existing event listener if it exists
// window.removeEventListener("scroll", handleScroll);

// // Add the new event listener
// window.addEventListener("scroll", handleScroll);

// function handleScroll() {
//   const header = document.querySelector(".header");

//   if (theme.palette.mode === "dark") {
//     header.classList.toggle("activeblack", window.scrollY > 100);
//     header.classList.remove("active");
  
//   } else if (theme.palette.mode === "light") {
//     header.classList.toggle("active", window.scrollY > 100);
//     header.classList.remove("activeblack");
//   }
// }
// function handleScroll() {
//   const header = document.querySelector(".header");


//     header.classList.toggle("active", window.scrollY > 100);
   
  
// }

  
  // Toogle Menu
  const [Mobile, setMobile] = useState(false)

  const [selected, setSelected] = useState("");
 
 
  return (
    <>
      <header className='header'>
        <div className='container d_flex'>
          <div className='logo' style={{display: 'flex', gap: '15px'}}>
            <div className="col">
            {console.log(selected,"hi")}
          <img src={logo} width={"80px"} height={"70px"}/>
            </div>
            <div className="col">
            <p className="apply-color" style={{whiteSpace: 'nowrap', marginTop: '10px', fontSize: '18px', fontWeight: '600'}}> ACGCET FEEDBACK PORTAL</p>
            </div>
          
          </div>
        

          <div className='navlink'>
            {/* <ul className={Mobile ? "nav-links-mobile" : "link f_flex uppercase"} onClick={() => setMobile(false)}> */}
              {/*<ul className='link f_flex uppercase {Mobile ? "nav-links-mobile" : "nav-links"} onClick={() => setMobile(false)}'>*/}
              {/* <Item
              title=""
             
              icon={theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
              selected={colorMode.toggleColorMode}
              setSelected={colorMode.toggleColorMode}
             
            /> */}
          
           
              {/* <li>
                <a href='#home'>home</a>
              </li> */}
              {/* <li>
                <a href='#features'>features</a>
              </li>
              <li>
                <a href='#portfolio'>portfolio</a>
              </li>
              <li>
                <a href='#resume'>resume</a>
              </li>
              <li>
                <a href='#clients'>clients</a>
              </li>
              <li>
                <a href='#blog'>blog</a>
              </li>
              <li>
                <a href='#contact'>contact</a>
              </li> */}
              {/* <li>
                <button className='home-btn'>BUY NOW</button>
              </li> */}
            {/* </ul> */}

            {/* <button className='toggle' onClick={() => setMobile(!Mobile)}>
              {Mobile ? <i className='fas fa-times close home-btn'></i> : <i className='fas fa-bars open'></i>}
            </button> */}
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
