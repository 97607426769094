


import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Sidebar from "../global/Sidebar";
import TopbarAdmin from "../global/Topbar-admin";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Form from "react-bootstrap/Form";
import YearPicker from "../Student_Exit.jsx/yearPicker"
import { TextareaAutosize } from '@mui/material';
import "./faculty.css"
import { Navigate,useNavigate } from "react-router-dom";

import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "../student-login-page/feedback.css"
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';







import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import Rating, { IconContainerProps } from '@mui/material/Rating';

import { makeStyles } from '@material-ui/core/styles';

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));


  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  const useStyles = makeStyles(() => ({
    customButton: {
        borderRadius: '50%',
      },
    root: {
      width: '60%',
      padding: '2%',
      fontSize: '12px',
      fontFamily: 'Arial',
      borderRadius: '2%',
      border: '1px solid #ccc',
      resize: 'both',
      overflow: 'auto',
      '&:focus': {
        outline: 'none',
      },
    },
    darkTheme: {
        backgroundColor: '#293040',
      color: '#ffffff',
    },
    lightTheme: {
      backgroundColor: '#f2f2f2',
      color: '#000',
    },
  }));
  
  

  
 

  

 

// --------------------MONTHLY VIEW TABLE--------------------------//
const ChangePassword = () => {
  const navigate = useNavigate();
  let user_details = localStorage.getItem("employee_details");
  if (user_details) {
    user_details = JSON.parse(user_details);
  

 
}
const classes = useStyles();
  useEffect(() => {
   
    if (user_details) {
       
      
  
     
    } else {
    
      navigate("/login/admin")
    
      console.log("user_details not found in local storage");
    }
  
  }, [])
    const [themes, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    

    const[FacultyName,setFacultyName]=useState([]);
   
    const [name_faculty,setNameFaculty]=useState("")
    const [id,setID]=useState("")
    const [branch,setBranch]=useState("")
    const [designation,setDesignation]=useState("")
    const [old_pass,setOldPass]=useState("")
    const [new_pass,setNewPass]=useState("")

    const isNonMobile = useMediaQuery("(min-width:600px)");
           
         
           const handleName = (e) => {
               setNameFaculty(e)
               
             };
             
           const handleDesignation = (e) => {
               setDesignation(e)
               
             };
          
             const handleID = (e) => {
            setID(e)
               
             };
             
           const handlebranch = (e) => {
            setBranch(e)
              
             };
             const handleOldPass=(e)=>{
                setOldPass(e)

             }
  const handleNewPass=(e)=>{
    setNewPass(e)
             }


         const handleSubmit=()=>{
       
 
          
          fetch('https://feedbackapi.rcktechiees.com/api/Admin/ChangePassword', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
             
              "emp_id":(user_details.Employee_id),
         "old_password":(old_pass),
          "new_password":(new_pass),
          
          
         
          
         

            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data,"faculty")}
            if(data["Status"]=="1"){
              toast.success("Password Changed Successfully ")
            }
            else if(data["Status"]=="0"){
              toast.error("Something went wrong")
            }
          
           
          
           
          
          
          })
           .catch(error=>console.log(error))
        
        

       


         
        }

      
      
      
      
      


  return (
   <> {user_details?
      <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <div className="app1">
                {/* <Sidebar isSidebar={isSidebar} feedback_type={"suggestion"}/> */}
                <main className="content1">
                    <TopbarAdmin setIsSidebar={setIsSidebar} feedback_type={"Change Password"}/>
 
<div className="container container1">
                    <Box m="20px"
       display="grid"
       gap="14px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
       sx={{
         "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
       }
       }
       >
           <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="User Name"
                   value={user_details.Name}             
                   disabled
                  
                   onChange={(event) => {handleName(event.target.value)}}
                   name=""
                   sx={{ gridColumn: "span 4",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "25px",
                    },
                  }}
              />


          
    <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="User ID"
                   value={user_details.Employee_id}
                   
disabled
                   onChange={(e) => {handleID(e.target.value)}}
                   name="id"
                   sx={{ gridColumn: "span 4",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "25px",
                    },
                  }}
              />
                <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Old Password"
                   value={old_pass}

                   onChange={(e) => {handleOldPass(e.target.value)}}
                   name="id"
                   sx={{ gridColumn: "span 4",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "25px",
                    },
                  }}
              />
                 <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="New Password"
                   value={new_pass}
                   

                   onChange={(e) => {handleNewPass(e.target.value)}}
                   name="id"
                   sx={{ gridColumn: "span 4",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "25px",
                    },
                  }}
              />
        
     
             
                
                 

    
   
     
      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>



<Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
{(old_pass=="" || new_pass=="")?                    
<Button  type="button" color="secondary" disabled variant="contained" minWidth="150px !important" className={`${classes.button}`} 
   onClick={handleSubmit}>
                          Submit
                          </Button> 
                          : <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'},}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button}`} 
                          onClick={handleSubmit}>
                          Submit
                          </Button> }
                            <ToastContainer />
                      {/* </Box> 
              <ToastContainer /> */}
    </Box>
      {/* <Box display="flex" justifyContent="center" mt="20px">
        {(old_pass=="" || new_pass=="")?
              <Button type="button" color="secondary" variant="contained" disabled  onClick={handleSubmit}>
              Submit
              </Button>: <Button type="button" color="secondary" variant="contained"  onClick={handleSubmit}>
              Submit
              </Button>}
              <ToastContainer />
    </Box> */}
    </FormControl>
  


     
             
    </Box>   </div>
    </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
 :null}
  
   </>
  );
}
export default ChangePassword;
