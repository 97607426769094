import React from "react"
import "./Home.css"

import { Typewriter } from "react-simple-typewriter"
import { Box, Typography, useTheme } from "@mui/material";

import { ColorModeContext,tokens } from "../../../theme";

const Home = () => {
  const theme = useTheme();
  const colors = "light";
  return (
    <>
      <section className='hero1' style={{paddingTop: "30px"}} id=''>
        <div className='container my_custom_class'>
          <div className='content'>
            <h3 style={{paddingTop: "10px"}}>
            WELCOME TO ACGCET FEEDBACK PORTAL</h3>
            <h1 className="">
              It's time for us to give <span>feedback</span>
            </h1>
            <h2>
              <span>
                <Typewriter words={["We value your time.", "Your data is highly confidential."]} loop cursor cursorStyle='|' typeSpeed={70} deleteSpeed={50} delaySpeed={1000} />
              </span>
            </h2>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

