import React from "react"
import Header from "./component/Header/Header"
import Features from "./component/Features/Features"
import Home from "./component/Home/Home"

import "./Main.css"
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../theme";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiButtonBase-root.MuiRadio-root.Mui-checked':{
      color: 'red !important',     
    },
  },
  button: {
    width: "150px !important",
  },
}));

const Main = () => {

  const classes = useStyles();
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const colors = "light";
  const [themes, colorMode] = useMode();
  console.log(colorMode,"themes")
  return (
    <>
     <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={themes}>
      <CssBaseline />
      <Header />
      <Home />
      <Features />
    
    
      </ThemeProvider>
    </ColorModeContext.Provider>
    </>
  )
}

export default Main;