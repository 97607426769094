// import './Spinner.css';
import ReactLoading from 'react-loading';
import RotateLoader from 'react-loading';

export const Spinner = props => {
    const loaderStyles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "20vh",
      };
      

    return (
        <div className='fp-container' style={loaderStyles}>
              <h6>Loading Content Please wait</h6>
            <span className="fp-loader">
              
                <RotateLoader size={15} color={"#36d7b7"} speedMultiplier={2} 
                // type={'balls'} color={'Green'} height={30} width={50}
                 />
            </span>
        </div>
    );
}