import "bootstrap/dist/css/bootstrap.min.css";
// import "../Home/Home.css";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {Stack} from  "react-bootstrap"
import Button from "@mui/material/Button";
// import Budget from "../Tab/Budget";
// import DailySales from "../Tab/DailySales";
// import Reports from "../Tab/Reports";
import Dropdown from "react-bootstrap/Dropdown";
// import P_L from "../Tab/P_L";
import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// import { mainAxios } from "../middleware/interceptor";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { BsExclamationTriangle } from "react-icons/bs";
// import { fobeAxios } from "../middleware/interceptor";
import { ToastContainer } from "react-toastify";

import { makeStyles } from '@material-ui/core/styles';

import Sidebar from "../global/Sidebar";
import Topbar2 from "../global/Topbar2";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import { tokens } from "../../theme";

import {  TextField,Select ,MenuItem,InputLabel,OutlinedInput,FormControl} from "@mui/material";
import { Formik,Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Basic_tab from "./Basic_tab";
import Subject from "./Subject_List"
import { Box, Typography, useTheme } from "@mui/material";
import "./Tabs.css"
import Feedback_tab from "./Feedback"





const useStyles = makeStyles((theme) => ({
    root: {
      '&.MuiButtonBase-root.MuiRadio-root.Mui-checked':{
        color: 'red !important',     
      },
    },
    button: {
      width: "150px !important",
    },
  }));

// import "./../index.css";
function Tabs_student() {
    const classes = useStyles();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [themes, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [showOTPField,setShowOTPFIeld]=useState(false);
    const [showFirstButton, setShowFirstButton] = useState(true);
    const [showSecondButton, setShowSecondButton] = useState(false);
    const [regno,setRegNo]=useState("");
    const [email,setEmailID]=useState("");
    const [otp,setOTP]=useState("");
    const [ug,setUG]=useState("");
    const [tab, setTab] = useState(0);
    const [selectDepartment, SetselectDepartment] = useState("");
    const [selectRegulation, SetselectRegulation] = useState("");
    const [selectSemester, SetselectSemester] = useState();
    const [selectUG, SetselectUG] = useState("");
    const [selectFT, SetselectFT] = useState("");
    const [selectCourse, SetselectCourse] = useState("");
    const [selectSubject, SetselectSubject] = useState("");
    const  [courseID,SetCourseID]=useState("");
   
    const [selectCode, SetselectCode] = useState("");
   

    const [currentTab, setCurrentTab] = useState(0);
    const [enableNext,setenableNext]=useState(0);
    const [enableNext1,setenableNext1]=useState(0);
    let ValidEmail="";
    let course_list=[]
    let filteredMenuItems=[]
    let elective=[]
  
    const emailRegex = 
    new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
    const regRegex=new RegExp(/[0-9]/)
  
    const isNonMobile = useMediaQuery("(min-width:600px)");
  
    const handleRegChange=(e)=>{
      setRegNo(e.target.value)
    }
  
   const handleOTPChange=(e)=>{
      setOTP(e.target.value)
    }
  
    const handleUGChange=(e)=>{
      setUG(e.target.value)
    }
  
    const handleEmailChange=(e)=>{
       setEmailID(e.target.value)
    }
  
    const handleOTPSubmit=(e)=>{
  
      if(emailRegex.test(email)){   
  
        if(regRegex.test(regno)){
      {console.log("otp",otp)}
      //Get response from api
      if(otp=="123456"){
        alert("login_successful")
      }
      else{
        alert("not successful")
      }
    }}}
  
    const handleFirstButtonClick = (e) => {
      if(emailRegex.test(email)){   
  
        if(regRegex.test(regno)){
          let student_login_details={
                RegisterNo: (regno),
                UG_PG:(ug),
                Email_ID: (email)
      }
  
  
      //APICALL
      {console.log("values",student_login_details)}
  
      setShowFirstButton(false);
      setShowSecondButton(true);}
    
    }
    }
     
    // useEffect(() => {
  
    // }, [selectDepartment]);

    const handleResend= (e) => {
      //call the same api
      let student_login_details={
        RegisterNo: (regno),
        UG_PG:(ug),
        Email_ID: (email)
      }
      {console.log("values",student_login_details)}
    }
  
    const handleBasic=(k)=>{
    alert(k);
    setTab(k);
    setCurrentTab(k)
    }
  


  
  
    return (
       <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        <div className="app1">
          <Sidebar isSidebar={isSidebar} feedback_type={"student"}/>
          <main className="content1">
              <Topbar2 setIsSidebar={setIsSidebar} feedback_type={"Student Feedback"}/>
    
      <div className="container">
      <ToastContainer autoClose={3000} />
      <div className="tab-sections"></div>
      {/* <div className="row"> */}
        <div className="col-md-12 col-sm-6 col-lg-12">
          <div className="tab-sections">
            <Tabs
              defaultActiveKey={tab}
              activeKey={currentTab}
              id="uncontrolled-tab-example"
              onSelect={(k) => handleBasic(k)}
              className="mb-3"
              style={{fontSize: '18px', fontWeight: '600'}}
            >
              <Tab eventKey={0} title="Basic Details" disabled={currentTab !== 0}>
                <Basic_tab
                 
                  tabss={tab}
                  selectDepartment={selectDepartment}
                  SetselectDepartment={SetselectDepartment}
                  selectRegulation={selectRegulation}
                  SetselectRegulation={SetselectRegulation}
                  selectSemester={selectSemester}
                  SetselectSemester={SetselectSemester}
                  selectUG={selectUG}
                  SetselectUG={SetselectUG}
                  selectFT={selectFT}
                  SetselectFT={SetselectFT}
                  enableNext={enableNext}
                  setenableNext={setenableNext}
                  
                  
            
                  

                 
                />
              </Tab>
              <Tab eventKey={1} title="Subject List" disabled={currentTab !== 1}>
                <Subject
                  tabss={tab}
                  selectDepartment={selectDepartment}
                  SetselectDepartment={SetselectDepartment}
                  selectRegulation={selectRegulation}
                  SetselectRegulation={SetselectRegulation}
                  selectSemester={selectSemester}
                  SetselectSemester={SetselectSemester}
                  selectUG={selectUG}
                  SetselectUG={SetselectUG}
                  selectFT={selectFT}
                  SetselectFT={SetselectFT}
                  selectCourse={selectCourse}
                  SetselectCourse={SetselectCourse}
                  selectSubject={selectSubject}
                  SetselectSubject={SetselectSubject}
                  enableNext1={enableNext1}
                  setenableNext1={setenableNext1}
                  course_list={course_list}
                  filteredMenuItems={filteredMenuItems}
                  elective={elective}
                  selectCode={selectCode}
                  SetselectCode={SetselectCode}
                  courseID={courseID}
                  SetCourseID={SetCourseID}
 

                />
              </Tab>
              {/* <Tab eventKey="Reports" title="Reports">
      <Reports />
      </Tab>  */}
              <Tab eventKey={2} title="Feedback" disabled={currentTab !== 2}>
                <Feedback_tab
                  tab={tab}
                  selectDepartment={selectDepartment}
                  SetselectDepartment={SetselectDepartment}
                  selectRegulation={selectRegulation}
                  SetselectRegulation={SetselectRegulation}
                  selectSemester={selectSemester}
                  SetselectSemester={SetselectSemester}
                  selectUG={selectUG}
                  SetselectUG={SetselectUG}
                  selectFT={selectFT}
                  SetselectFT={SetselectFT}
                  selectCourse={selectCourse}
                  SetselectCourse={SetselectCourse}
                  selectSubject={selectSubject}
                  SetselectSubject={SetselectSubject}
                  course_list={course_list}
                  filteredMenuItems={filteredMenuItems}
                  elective={elective}
                  selectCode={selectCode}
                  SetselectCode={SetselectCode}
                  courseID={courseID}
                  SetCourseID={SetCourseID}

                 
                />
              </Tab>
            </Tabs>
            <Stack gap={3} direction="horizontal" className="mt-3 button-class" >
        <Button
        type="button" className='tabbuttons' color="secondary" variant="contained"
          // className="success"
          disabled={currentTab === 0}
          onClick={() => setCurrentTab((prev) => prev - 1)}
        >
          Prev
        </Button>
        <Button className='tabbutton' type="button" color="secondary" variant="contained" disabled={currentTab === 2 || (currentTab===0 && enableNext==0) || (currentTab===1 && enableNext1==0)}
          onClick={() => setCurrentTab((prev) => prev + 1)}
        >
          Next
        </Button>
      </Stack>
          </div>
        </div>
    
      {/* </div> */}
    </div>            
  
          
      </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  };
  

export default Tabs_student;






 



