import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,  TextField,Typography, useTheme,MenuItem,InputLabel,FormControl,Select } from "@mui/material";
import Button from "@mui/material/Button";
import { Spinner } from "../../Spinner/Spinner.js"
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
// import Button from 'react-bootstrap/Button';
import ListSubheader from '@mui/material/ListSubheader';



import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";



// --------------------MONTHLY VIEW TABLE--------------------------//
function Subject({
  tabss,
  selectDepartment,
  SetselectDepartment,
  selectRegulation,
  SetselectRegulation,
  selectSemester,
  SetselectSemester,
  selectUG,
 SetselectUG,
 selectFT,
 SetselectFT,
 selectCourse,
 SetselectCourse,
 selectSubject,
 SetselectSubject,
 enableNext1,
 setenableNext1,
 selectCode,
 SetselectCode,
 SetCourseID,
 courseID,
//  elective=[],
//  course_list=[],
//  filteredMenuItems=[]




}) 
{

  let course_list=[];
  let filteredMenuItems=[];
  let elective=[];
  let department_id;
  let regulation_id;
  console.log(course_list,"helo course")
  console.log(filteredMenuItems,"helo course22222")
  console.log(elective,"helo course333333")
  const [data_nature,setDataNature]=useState({});
  const [sub_list,setSubList]=useState([]);
  const [status,setStatus]=useState(0)
  const [showSub,setShowSub]=useState();
  const [showElective,setshowElective]=useState(false);
  
  let user_details = localStorage.getItem("user_details");


  const[options,setOptions]=useState({array1:[],array2:[]})
  

    const [isLoading,setIsLoading]=useState(true);
    // const[regulation,setRegulation]=useState("");
    // const[department,setDepartment]=useState("");
    const[semester,setSemester]=useState("");
   
if (user_details) {
  user_details = JSON.parse(user_details);
  console.log(user_details.Email);
} else {
  console.log("user_details not found in local storage");
}



// const getSubject=()=>{
//   setIsLoading(true)
//   alert(user_details.Register_Number)
    

//   fetch(' https://feedbackapi.rcktechiees.com/api/student/UploadData', {
//   method: 'POST',
//   headers: {
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*'
//   },
//   body: JSON.stringify({
//     "regulation_id": (regulation_id),
//     "department_id": (department_id),
//     "ug_pg": (selectUG),
//     "semester": (selectSemester),
//     "reg_no": (user_details.Register_Number)
//   })
// })
// .then(response => response.json())

// .then(data => {

//   console.log(data);
//   console.log(data,"resposne1111111111")

useEffect(()=>{

  SetselectCourse("")
  SetselectSubject("")
  SetselectCode("")

  fetch(' https://feedbackapi.rcktechiees.com/api/student/UploadData', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  body: JSON.stringify({
    "regulation_id": (selectRegulation),
    "department_id": (selectDepartment),
    "ug_pg": (selectUG),
    "semester": (selectSemester),
    "reg_no": (user_details.Register_Number)
  })
})
.then(response => response.json())

.then(data => {

  console.log(data,"subj");
  console.log(typeof(data),"resposne1111111111")
  setDataNature(data);
setStatus(1);
  for (const key in data) {
         {console.log(key,"key")}
         course_list.push(key);
        console.log(course_list,"course123")
       
    }
  const array1Data=course_list.filter(item=>item !== 'Professional Elective Group A' && item !== 'Open Elective' && item !== 'Professional Elective Group B')
  const  array2Data=course_list.filter(item=>item === 'Professional Elective Group A' || item === 'Open Elective' || item === 'Professional Elective Group B')
  {console.log(array2Data,"array2dta")}
     {console.log(array2Data.length,"array2dta")}
  if(array2Data.length>0){
   
    setshowElective(true)
  }
  else{
    setshowElective(false)
  }
 

  setOptions({array1:array1Data,array2:array2Data});
  {console.log(options,"options")}
  setIsLoading(false)
})
 .catch(error=>{console.log(error)
  setStatus(0);})
},[selectDepartment,selectRegulation,selectSemester,selectFT,selectUG]);

  

useEffect(()=>{

  SetselectSubject("")
  SetselectCode("")

  if(selectCourse!==""){
   
    {console.log(selectCourse,"selected")}
    {console.log(data_nature[selectCourse],"selected")}
    

    setIsLoading(true)
    setSubList(data_nature[selectCourse]);

//     {console.log(sub_list,"selected")}
//     if(sub_list.length==0){
//       setIsLoading(true)
// setShowSub(false)
// setIsLoading(false)
//     }
//     else{
//       setIsLoading(true)
// setShowSub(true)
// setIsLoading(false)
//     }
    setIsLoading(false)


  }

},[selectCourse,selectDepartment,selectRegulation,selectSemester,selectUG,selectCourse]);
//   const result = {};

//   for (const key in data) {
//     {console.log(key,"key")}
//     course_list.push(key);
//     console.log(course_list,"course123")
   
//   }
 
//   filteredMenuItems = course_list.filter(item => item !== 'Professionl elective Group A' && item !== 'OPEN ELECTIVE' && item !== 'Professionl elective Group B');
//   elective=course_list.filter(item => item === 'Professionl elective Group A' || item === 'OPEN ELECTIVE' || item === 'Professionl elective Group B');
//   console.log(course_list,"course",filteredMenuItems,"filter11",elective)


  
//   console.log(result,"result");
//   if(result["OPEN ELECTIVE"]){
//     console.log(result["OPEN ELECTIVE"],"sub2")
//   }
 
//   setIsLoading(false)
  
// })
// .catch(error => console.error(error,"err"))

 
// }




// useEffect(() => {



// if(selectDepartment!=="" && selectRegulation!=="" && selectSemester!=="" && selectUG!==""){

//   getSubject();
// }
  
// },[selectDepartment,selectRegulation,selectSemester,selectUG]);
    // let user_details=localStorage.getItem('user-details');
    // console.log(user_details,"hello")
  
   
     const handleCourse=(e)=>{
    // alert(e)
      SetselectCourse(e)
      if(e=="Theory" || e=="Professional Elective Group A" || e=="Professional Elective Group B" || e=="Open Elective"){
        SetCourseID("1")
      }
      else if(e=="Practical"){
        SetCourseID("2")
      }
      else if(e=="Technical Seminar I"){
        SetCourseID("3")
      }
      else if(e=="Technical Seminar II"){
        SetCourseID("4")
      }
      else if(e=="Mini Project"){
        SetCourseID("5")
      } else if(e=="Project"){
        SetCourseID("6")
      }
      else{
        SetCourseID("")
      }

   }
   const handleSubject=(e)=>{
    console.log(e,"sub_name");
    console.log(sub_list,"sub_name");
    SetselectSubject(e)
    let sub_name=sub_list.find((item)=>item.Subject_code===e);
    console.log(sub_name,"subject_name");
    SetselectCode(sub_name.Subject_name)

 }
 useEffect(() => {


  if(selectCourse!=="" && selectSubject!==""){
    setenableNext1(1)

  }
  else if(selectCourse==="" || selectSubject==="" ){
    setenableNext1(0)

  }

 
},[selectCourse,selectSubject]);

// const menuItems = ['Project', 'Professionl elective Group A', 'OPEN ELECTIVE'];
  




    

     const handleSemester=(e)=>{
        setSemester(e.target.value)
     }
     const isNonMobile = useMediaQuery("(min-width:600px)");
  
      
    //  useEffect(() => {
  
    //  }, [selectDepartment]);
  /////////////////////////////////////////////

  return (
   <>
  {isLoading ? <Spinner/>: (
<Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >

<FormControl variant="filled" sx={{ gridColumn: "span 4"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Course Type</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectCourse}
        
          onChange={(e) => {handleCourse(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
           
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
          {console.log(options,'helo list')}
         {options.array1.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
{console.log(showElective,"elective")}
{showElective ?  
   <>
    <ListSubheader>Electives</ListSubheader> </>
:null}
    {options.array2.map((item) => (
      <MenuItem key={item} value={item} >
        {item}
      </MenuItem>
   ))}

      {/* {showElective? <>
          <ListSubheader>Electives</ListSubheader>
          {options.array2.map((item) => (
           
        <MenuItem key={item} value={item}>
           {console.log(item,"item")}
          {item}
        </MenuItem>
      ))}
       </>  :null } */}
        </Select>
      </FormControl>

      {/* {showSub==true ? */}
      <FormControl variant="filled" sx={{ gridColumn: "span 4"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Choose Subject</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectSubject}
          onChange={(e) => {handleSubject(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
            
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
        
          {sub_list.map((item) => (
          <MenuItem 
          value={item.Subject_code} 
          >{`${item.Subject_code} ${item.Subject_name}`}</MenuItem>
          ))}
       
         
        </Select>
      </FormControl>
      {/* :null} */}
    
      
   
         
    </Box>
         )
        }

   </>
  );
}
export default Subject;
