import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { useState } from "react";
import Sidebar2 from "../../scenes/global/Sidebar2";
import Topbar from "../../scenes/global/Topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import { tokens } from "../../theme";

import {  Button, TextField,Select ,MenuItem,InputLabel,OutlinedInput,FormControl} from "@mui/material";
import { Formik,Form,Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate,useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiButtonBase-root.MuiRadio-root.Mui-checked':{
      color: 'red !important',     
    },
  },
  button: {
    width: "150px !important",
  },
}));

const Admin_Login_Form = () => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [themes, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
 

  const [student_name,setStudentName]=useState("");
  const [id,setID]=useState("")
  const [pass,setPass]=useState("")
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();
 

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleIDChange=(e)=>{
    setID(e.target.value)
  }

 const handlePasswordChange=(e)=>{
    setPass(e.target.value)
  }

 
  const handleSubmit=(e)=>{

    setIsLoading(true)
     
    fetch('https://feedbackapi.rcktechiees.com/api/Admin/UserCheck', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        "emp_id":(id),
        "password":(pass),
   
  

      })
    })
    .then(response => response.json())
    
    .then(data => {
    
      {console.log(data,"responseupload")}

      if(data["Status"]=="1"){
        
        toast.success("Successfully Submitted")
        let admin_values={
          Employee_id: (id), 
         Department:(data["Department"]),
          Name: (data["Name"]),

        }
        localStorage.setItem("employee_details", JSON.stringify(admin_values));
        navigate("/admin_form")
        setIsLoading(false)

      }
      else if(data["Status"]=="0"){
        toast.error("Invalid Credentials")
        setIsLoading(false)
      }
       
    
    
    })
     .catch(error=>console.log(error))


  


  
  
   

   
   
  
  }


  



  return (
     <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={themes}>
      <CssBaseline />
      <div className="app1">
        <Sidebar2 isSidebar={isSidebar} feedback_type={"admin_login"}/>
        <main className="content1">
        <div className="forstudentlogin" style={{width: '40%', padding: '5px 30px', margin: '85px auto', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', background: '#fff', borderRadius: '3px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Topbar setIsSidebar={setIsSidebar} feedback_type={"Admin Login"}/>
            </div>    
    <Box m="20px"
       display="grid"
       gap="7px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      //  sx={{
      //    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      //  }
      //  }
       >


              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Employee ID"
                   value={id}
                   onChange={handleIDChange}  
                   name="regno"
                   sx={{ gridColumn: "span 12",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
              /><br></br>
              
            
         
    
              <TextField
                fullWidth
                variant="filled"
                type="text1"
                label="Password"
                value={pass}
                onChange={handlePasswordChange}
                name="email"
                sx={{ gridColumn: "span 12",
                "& .MuiInputBase-root.MuiFilledInput-root": {

                                        border: "1px solid grey",
                  
                                        borderRadius: "3px",
                  
                                        backgroundColor: "transparent",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                  
                                        backgroundColor: "transparent",
                  
                                        borderColor: "#01134d",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                  
                                        backgroundColor: "transparent",
                  
                                        borderColor: "#01134d",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:before": {
                  
                                        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:after": {
                  
                                        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                  
                                      },
              }}
              />
            

            
            
     </Box>
     <Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, ...(isLoading && {
          '&.button--loading': { /* styles for loading state */ },
        }),}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button} ${isLoading ? 'button--loading' : ''}`} 
        onClick={handleSubmit}>
                                Submit
                          </Button> 
                          <ToastContainer />
                      </Box> 
         

                      {/* <Box display="flex" justifyContent="center" mt="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'},}} type="button" color="secondary" variant="contained" minWidth="150px !important" 
    
                            className={classes.button}  onClick={handleSubmit}>
                             Submit
                          </Button> 
                          <ToastContainer />
                      </Box>  */}
              
                      </div>
        
    </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};


//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;



 

export default Admin_Login_Form;
