import { Box, IconButton, useTheme , Typography,Grid,Menu,MenuItem} from "@mui/material";
import { useContext } from "react";
import { useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useMode } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Topbar2 = ({ feedback_type }) => {
    let user_details = localStorage.getItem("user_details");

    if (user_details) {
        user_details = JSON.parse(user_details);
        console.log(user_details.Email);
      } else {
        console.log("user_details not found in local storage");
      }
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
 

  const handleMenuItemClick = () => {
    // Handle menu item click here
    localStorage.removeItem('user_details');
    toast.success('Logged out successfully!', {
      position: toast.POSITION.TOP_CENTER,
    });

    navigate('/');
   
  };
 
  return (
   
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
       <Box
        display="flex"
        // backgroundColor={colors.primary[400]}
        borderRadius="3px"
        fontSize="25px"
        color="01134d"
        opacity="10%"
        fontWeight="bold"
      > 
      { feedback_type }
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" /> */}
        {/* <IconButton type="button" sx={{ p: 1 }}> */}
          {/* <SearchIcon /> */}
        {/* </IconButton> */}
       </Box> 


      {/* ICONS */}
      <Box display="flex" alignItems="center" style={{paddingTop: "0px"}}>
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <IconButton sx={{background: 'grey', color: 'white'}} onClick={handleMenuOpen}>
              <PersonOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item style={{paddingTop: "0px"}}>
            <Typography variant="caption" style={{paddingTop: "0px", fontWeight: '600', fontSize: '15px'}}>{user_details.Student_Name}</Typography>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleMenuItemClick}>Logout</MenuItem>
        
        </Menu>
      </Box>  </Box>
   
  );
};

export default Topbar2;
