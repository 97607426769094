
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
// import Button from "@mui/material/Button";
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Sidebar2 from "../global/Sidebar2";
import TopbarAdmin from "../global/Topbar-admin";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Form from "react-bootstrap/Form";
import YearPicker from "../Student_Exit.jsx/yearPicker"


import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "../student-login-page/feedback.css"
import "./form-admin.css"
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
    MDBBtn,
    MDBBtnGroup
  } from 'mdb-react-ui-kit';

// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';







import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Navigate,useNavigate } from "react-router-dom";


import Rating, { IconContainerProps } from '@mui/material/Rating';

import { makeStyles } from '@material-ui/core/styles';
import Card_Admin from "./Card-admin";

  

  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }


  
  const data = [
    {
      id: 1,
      image: "https://img.icons8.com/ios/64/000000/menu.png",
      title: "Student Feedback",
      // desc: "I throw myself down among the tall grass by the stream as I lie close to the earth.",
    },
    {
      id: 2,
      image: "https://img.icons8.com/ios/40/000000/open-book.png",
      title: "Faculty Feedback",
      // desc: " It uses a dictionary of over 200 Latin words, combined witha handful of model sentence.",
    },
    {
      id: 3,
      image: "https://img.icons8.com/fluency-systems-regular/64/000000/retro-tv.png ",
      title: "Alumni Feedback",
      // desc: "I throw myself down among the tall grass by the stream as I lie close to the earth.",
    },
    {
      id: 4,
      image: "https://img.icons8.com/material-outlined/64/000000/topic--v1.png ",
      title: "Employer Feedback",
      // desc: "There are many variations of passages of Lorem Ipsum	available, but the majority.",
    },
    {
      id: 5,
      image: "https://img.icons8.com/ios-filled/64/000000/wifi.png ",
      title: "Parent's Feedback",
      // desc: "always free from repetition, injected humour, or non-characteristic words etc.",
    },
    {
      id: 6,
      image: "https://img.icons8.com/glyph-neue/50/000000/polyline.png ",
      title: "Consultancy Feedback",
      // desc: " It uses a dictionary of over 200 Latin words, combined with a handful of model sentence.",
    },
    {
      id: 7,
      image: "https://img.icons8.com/glyph-neue/50/000000/polyline.png ",
      title: "Collaborator Feedback",
      // desc: " It uses a dictionary of over 200 Latin words, combined with a handful of model sentence.",
    },
    {
      id: 8,
      image: "https://img.icons8.com/glyph-neue/50/000000/polyline.png ",
      title: "Supplier Feedback",
      // desc: " It uses a dictionary of over 200 Latin words, combined with a handful of model sentence.",
    },
    {
      id: 9,
      image: "https://img.icons8.com/glyph-neue/50/000000/polyline.png ",
      title: "Admin Login",
      // desc: " It uses a dictionary of over 200 Latin words, combined with a handful of model sentence.",
    },
  ]
 


 
 

// --------------------MONTHLY VIEW TABLE--------------------------//
const Admin_Form = () => {

  const navigate = useNavigate();
  let user_details = localStorage.getItem("employee_details");

  useEffect(() => {
   
    if (user_details) {

      
      
  
     
    } else {
    
      navigate("/login/admin")
    
      console.log("user_details not found in local storage");
    }
  
  }, [])

 



  const [themes, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
     
     const isNonMobile = useMediaQuery("(min-width:600px)");
    
    
     const [feedbackcount,setFeedbackresponse]=useState("")
    

    
     const [isLoading,setIsLoading]=useState(true)

     const [showExit,setShowExit]=useState(true);
     const [downloadExit,setDownloadExit]=useState(false);
     const [urlexit,setURLExit]=useState("")

     const [showDiscontinue,setDiscontinue]=useState(true);
     const [downloadDiscontinue,setDownloadDiscontinue]=useState(false);
     const [urlDiscontinue,setURLDiscontinue]=useState("")

     const [showTeach,setTeach]=useState(true);
     const [downloadTeach,setDownloadTeach]=useState(false);
     const [urlTeach,setURLTeach]=useState("")

     const [showPla,setPla]=useState(true);
     const [downloadPla,setDownloadPla]=useState(false);
     const [urlPla,setURLPla]=useState("")

     const [showAlu,setAlu]=useState(true);
     const [downloadAlu,setDownloadAlu]=useState(false);
     const [urlAlu,setURLAlu]=useState("")

     const [showCol,setCol]=useState(true);
     const [downloadCol,setDownloadCol]=useState(false);
     const [urlCol,setURLCol]=useState("")

     const [showCon,setCon]=useState(true);
     const [downloadCon,setDownloadCon]=useState(false);
     const [urlCon,setURLCon]=useState("")


     const [showSup,setSup]=useState(true);
     const [downloadSup,setDownloadSup]=useState(false);
     const [urlSup,setURLSup]=useState("")
     const [showSug,setSug]=useState(true);
     const [downloadSug,setDownloadSug]=useState(false);
     const [urlSug,setURLSug]=useState("")

     const [showE,setE]=useState(true);
     const [downloadE,setDownloadE]=useState(false);
     const [urlE,setURLE]=useState("")

     const [showP,setP]=useState(true);
     const [downloadP,setDownloadP]=useState(false);
     const [urlP,setURLP]=useState("")
     const [showF,setF]=useState(true);
     const [downloadF,setDownloadF]=useState(false);
     const [urlF,setURLF]=useState("")


     useEffect(() => {
      setURLExit("")
      setDownloadExit("")
      setURLDiscontinue("")
      setDownloadDiscontinue("")

      setURLTeach("")
      setDownloadTeach("")
      setURLPla("")
      setDownloadPla("")

      setURLAlu("")
      setDownloadAlu("")
      setURLCol("")
      setDownloadCol("")
      setURLCon("")
      setDownloadCon("")
      setURLSup("")
      setDownloadSup("")


      setURLSug("")
      setDownloadSug("")
      setURLE("")
      setDownloadE("")
      setURLP("")
      setDownloadP("") 
      setURLF("")
      setDownloadF("")
   
     
    
    }, [])

 
  
    


     


   

   

  

  
  
  


          useEffect(()=>{
            setIsLoading(true)
           

            fetch('https://feedbackapi.rcktechiees.com/api/Admin/Cout_feedback', {
            method: "GET",
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
          
          })
          .then(response => response.json())
          
          .then(data => {
          
            console.log(data.Counts[0],"count");
            console.log(typeof(data),"count type")
          setFeedbackresponse(data.Counts[0])

          
            setIsLoading(false)
         
          })
           .catch(error=>console.log(error))
          },[]);
          
         
        

      
 
      
        
      
      
        const [isClicked, setIsClicked] = useState(false);

        const handleClick = () => {
         
        navigate("/admin/student_excel")
        };
        const handleClickExit = () => {
         
          alert("hi exit")

          const downloadExcel = async () => {
            try {
              const url = 'https://feedbackapi.rcktechiees.com/api/Admin/Student_exit_feedback_excel';
              
          
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*'
                  // Add any additional headers if required
                },
               
              });
          
              if (response.ok) {
                {console.log(response)}
                // Request was successful
                const blob = await response.blob();
                const filename = getFilenameFromResponse(response);
          
                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);
          
                // Create a link element and simulate a click to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
          
                // Revoke the temporary URL to release memory
                window.URL.revokeObjectURL(url);
              } else {
                // Request failed
                console.error('Error:', response.status, response.statusText);
              }
            } catch (error) {
              console.error('Error:', error);
            }
          };
          
          const getFilenameFromResponse = (response) => {
            {console.log(response.headers,"heade")}
            const dispositionHeader = response.headers.get('content-disposition');
            alert(dispositionHeader)
            const filenameMatch = dispositionHeader.match(/filename="(.+)"/);
            return filenameMatch ? filenameMatch[1] : 'download.xlsx';
          };
          
          downloadExcel();
          
        };
        const handleClickDiscontinue = () => {
         
          alert("Discontnue")
        };
        const handleClickEmployer = () => {
         
          alert("hi employer")
        };
        const handleClickAlumni = () => {
         
          alert("hi alumni")
        };
        const handleClickTechnician = () => {
         
          alert("hi tech")
        };
        const handleClickFaculty = () => {
         
          alert("hi faculty")
        };
        const handleClickPlacement = () => {
         
          alert("hi placement")
        };
        const handleClickCollaborator = () => {
         
          alert("hi col")
        };
        const handleClickConsultancy = () => {
         
          alert("hi cons")
        };
        const handleClickSuggestion = () => {
         
          alert("hi sug")
        };
        const handleClickSupplier = () => {
         
          alert("hi sup")
        };
        const handleClickParent = () => {
         
          alert("hi parent")
        };
        const  handleClickExitGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/Student_exit_feedback_excel', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.fileUrl,"response excel")}
            setURLExit(data.fileUrl)

      
            setShowExit(false)

            setDownloadExit(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };
        const  handleClickDiscontinueGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/student_discontinue', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLDiscontinue(data.file)

      
            setDiscontinue(false)

            setDownloadDiscontinue(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };
        const  handleClickTeachGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/non_teaching', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLTeach(data.file)

      
            setTeach(false)

            setDownloadTeach(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };
        const  handleClickPlaGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/placement_training', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLPla(data.file)

      
            setPla(false)

            setDownloadPla(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };
        const  handleClickAluGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/alimni_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLAlu(data.file)

      
            setAlu(false)

            setDownloadAlu(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };
        const  handleClickColGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/collabarator_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLCol(data.file)

      
            setCol(false)

            setDownloadCol(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };

        const  handleClickSupGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/supplier_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLSup(data.file)

      
            setSup(false)

            setDownloadSup(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };

        const  handleClickConGenerate = () => {
          fetch('https://feedbackapi.rcktechiees.com/api/Admin/consultancy_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLCon(data.file)

      
            setCon(false)

            setDownloadCon(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };

        const  handleClickSugGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/student_sugession', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLSug(data.file)

      
            setSug(false)

            setDownloadSug(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };

        const  handleClickEGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/employer_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLE(data.file)

      
            setE(false)

            setDownloadE(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };
        const  handleClickFGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/faculty_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLF(data.file)

      
            setF(false)

            setDownloadF(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };
        const  handleClickPGenerate = () => {

          fetch('https://feedbackapi.rcktechiees.com/api/Admin/parent_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data.file,"response excel")}
            setURLP(data.file)

      
            setP(false)

            setDownloadP(true)
          
          
          })
           .catch(error=>console.log(error))
        
         
        
        };
        const handleClickPDownload = () => {

         
        
        };
        const handleClickFDownload = () => {

         
        
        };
        const handleClickColDownload = () => {

         
        
        };
        const handleClickEDownload = () => {

         
        
        };
        const handleClickSupDownload = () => {

         
        
        };
        const handleClickSugDownload = () => {

         
        
        };
        
        const handleClickConDownload = () => {

         
        
        };
        const handleClickPlaDownload = () => {

         
        
        };
        const handleClickAluDownload = () => {

         
        
        };


        const handleClickExitDownload = () => {

         
        
        };
        const handleClickDiscontinueDownload = () => {
         
         
       
      
      };
      const handleClickTeachDownload = () => {
         
         
       
      
      };


       
  return (
   <>
   {user_details?
      <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <div className="app1">
                {/* <Sidebar2 isSidebar={isSidebar} feedback_type={"admin_login"}/> */}
                <main className="content1">
                    <TopbarAdmin setIsSidebar={setIsSidebar} feedback_type={"Admin Page"}/>
 
                    <Box m="20px"
                    paddingRight="5%"
                    paddingLeft="5%"
                    paddingTop="1%"
                    paddingBottom="1%"
      
       gap="7px"
     
      //  sx={{
      //    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      //  }
      //  }
       >
{/* <div className="row"> */}

{/* <div className='heading'>  
            <input className="text-placeholder"  placeholder="Search Feedback..." onChange={event=>setSearch(event.target.value)}/> */}
            {/* <h1>What I Do</h1> */}
          {/* </div> </div> */}
{/* 
          <div className='content grid content-grid'>
            {data.filter((val)=>{
              if(setSearch==""){
                return val;
              }
              else if(val.title.toLowerCase().includes(search.toLowerCase())){
                return val;
              }
            }).map((val, index) => {
              return <Card_Admin key={index}  title={val.title} desc={val.desc} />
            })}

           
          </div> */}
          <div className='content grid content-grid'>
          <MDBCard ><div class="card-body">
      <MDBCardBody style={{ maxWidth: '300px' }}>
        <MDBCardTitle><div class="title-card">Student Feedback </div></MDBCardTitle>
        <MDBCardText><div class="card-text">
         Total Count: {feedbackcount.Student_feedback}
          </div> </MDBCardText>
          <Button variant="primary" onClick={handleClick}>Generate Excel</Button>
          {/* <Button variant="primary">Go somewhere</Button>
          <Button variant="primary">Go somewhere</Button>
       */}
       
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Student Exit Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Student_exit}
          </div> </MDBCardText>
          {showExit?
          <Button variant="primary" onClick={handleClickExitGenerate}>Generate Excel</Button>:null}
             {downloadExit?
          <Button variant="success" onClick={handleClickExitDownload}><a href={urlexit} download style={{color:"white" }}>
        Download Excel 
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Student Discontinue Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Student_discontinue}
          </div> </MDBCardText>
          {showDiscontinue?
          <Button variant="primary" onClick={handleClickDiscontinueGenerate}>Generate Excel</Button>:null}
             {downloadDiscontinue?
          <Button variant="success" onClick={handleClickDiscontinueDownload}><a href={urlDiscontinue} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Non-Teaching/Technician</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Student_nonteaching}
          </div> </MDBCardText>
          {showTeach?
          <Button variant="primary" onClick={handleClickTeachGenerate}>Generate Excel</Button>:null}
             {downloadTeach?
          <Button variant="success" onClick={handleClickTeachDownload}><a href={urlTeach} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Placement Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Student_placement}
          </div> </MDBCardText>
           {showPla?
          <Button variant="primary" onClick={handleClickPlaGenerate}>Generate Excel</Button>:null}
             {downloadPla?
          <Button variant="success" onClick={handleClickPlaDownload}><a href={urlPla} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Alumni Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Alumni_feedback}
          </div> </MDBCardText>
          {showAlu?
          <Button variant="primary" onClick={handleClickAluGenerate}>Generate Excel</Button>:null}
             {downloadAlu?
          <Button variant="success" onClick={handleClickAluDownload}><a href={urlAlu} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Collaborator Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Collabarator_feedback
}
          </div> </MDBCardText>
          {showCol?
          <Button variant="primary" onClick={handleClickColGenerate}>Generate Excel</Button>:null}
             {downloadCol?
          <Button variant="success" onClick={handleClickColDownload}><a href={urlCol} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Consultancy Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Consultancy_feedback
}
          </div> </MDBCardText>
          {showCon?
          <Button variant="primary" onClick={handleClickConGenerate}>Generate Excel</Button>:null}
             {downloadCon?
          <Button variant="success" onClick={handleClickConDownload}><a href={urlCon} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Supplier Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text" >
        Total Count:  {feedbackcount.Supllier_feedback
}
          </div> </MDBCardText>
          {showSup?
          <Button variant="primary" onClick={handleClickSupGenerate}>Generate Excel</Button>:null}
             {downloadSup?
          <Button variant="success" onClick={handleClickSupDownload}><a href={urlSup} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Suggestion Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Student_sugession
}
          </div> </MDBCardText>
          {showSug?
          <Button variant="primary" onClick={handleClickSugGenerate}>Generate Excel</Button>:null}
             {downloadSug?
          <Button variant="success" onClick={handleClickSugDownload}><a href={urlSug} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Employer Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text" >
        Total Count:  {feedbackcount.Employer_feedback}
          </div> </MDBCardText>
          {showE?
          <Button variant="primary" onClick={handleClickEGenerate}>Generate Excel</Button>:null}
             {downloadE?
          <Button variant="success" onClick={handleClickEDownload}><a href={urlE} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Parent Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">
        Total Count:  {feedbackcount.Parent_feedback
}
          </div> </MDBCardText>
          {showP?
          <Button variant="primary" onClick={handleClickPGenerate}>Generate Excel</Button>:null}
             {downloadP?
          <Button variant="success" onClick={handleClickPDownload}><a href={urlP} download style={{color:"white" }}>
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    <MDBCard><div class="card-body">
      <MDBCardBody>
        <MDBCardTitle><div class="title-card">Faculty Feedback</div></MDBCardTitle>
        <MDBCardText><div class="card-text">   Total Count:  {feedbackcount.Faculty_feedback}
          </div> </MDBCardText>
          {showF?
          <Button variant="primary" onClick={handleClickFGenerate}>Generate Excel</Button>:null}
             {downloadF?
          <Button variant="success" onClick={handleClickFDownload}><a href={urlF} download style={{color:"white" }}> 
        Download Excel
        </a></Button>:null}
      </MDBCardBody>
      </div>
    </MDBCard>
    </div>






       </Box>

       
    </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
:null}
  
   </>
  );
}
export default Admin_Form;









const NumberAnimation = ({ originalValue, duration }) => {
  const [currentValue, setCurrentValue] = useState(originalValue);

  useEffect(() => {
    let interval;

    const updateNumber = () => {
      setCurrentValue((prevValue) => {
        if (prevValue < originalValue) {
          return prevValue + 1;
        } else {
          clearInterval(interval);
          return originalValue;
        }
      });
    };

    interval = setInterval(updateNumber, duration / originalValue);

    return () => {
      clearInterval(interval);
    };
  }, [originalValue, duration]);

  return <div>{currentValue}</div>;
};





