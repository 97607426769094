import React from "react"
import { ColorModeContext,tokens } from "../../../theme";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
const Card = (props) => {
  const theme = useTheme();
  let location="";
  if(props.title=="Student Feedback"){
    location="/login/student";
    
  }
  else if(props.title=="Faculty Feedback") {
    location="/login/faculty";
  }
  else if (props.title=="Alumni Feedback") {
    location="/alumni_feedback";
  }
  else if (props.title=="Employer Feedback") {
    location="/employer_feedback";
  }
else if (props.title=="Parent's Feedback") {
    location="/parent_feedback";
  }
  else if (props.title=="Consultancy Feedback") {
    location="/consultancy_feedback";
  }  
  else if (props.title=="Collaborator Feedback") {
    location="/collaborator_feedback";
  }
  else if(props.title=="Supplier Feedback"){
    location="/supplier_feedback";
  }
  else if(props.title=="Admin Login"){
    location="/login/admin";
  }
  else {
    location="#";
  }
 
  return (
    <>
     <a href={location}>
      <div className={theme.palette.mode=="light"?'box btn_shadow':"box btn_no_shadow"}>
         
        {/* <img src={props.image} alt='' /> */}
        <h2>{props.title}</h2>
        <p>{props.desc}</p>
    
          <i className='fas fa-arrow-right'></i>
      
      </div>
      </a>
    </>
  )
}

export default Card
