
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Sidebar from "../global/Sidebar";
import Topbar2 from "../global/Topbar2";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Form from "react-bootstrap/Form";
import YearPicker from "../Student_Exit.jsx/yearPicker"

import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "../student-login-page/feedback.css"
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';







import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import Rating, { IconContainerProps } from '@mui/material/Rating';

import { makeStyles } from '@material-ui/core/styles';

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));


  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  
  const useStyles = makeStyles({
    customButton: {
      borderRadius: '50%',
    },
  });
  
 

  const Questionset_api=[
    {Ques_id:0,Question:"Discipline in the lab classes?//(ஆய்வக வகுப்புகளில் ஒழுக்கம்?) "},
    {Ques_id:1,Question:" Comment on the % attendance of the Non-Teaching staff?//(ஆசிரியர் அல்லாத பணியாளர்களின் % வருகை குறித்து கருத்து தெரிவிக்கவும்? ) "},
    {Ques_id:2,Question:"Time keeping attitude and regularity of the Non-Teaching staff in the lab classes?//(ஆய்வக வகுப்புகளில் ஆசிரியர் அல்லாத பணியாளர்களின் நேரத்தைக் கடைப்பிடிக்கும் அணுகுமுறை மற்றும் ஒழுங்குமுறை? )"},
    {Ques_id:3,Question:"How far the Non-Teaching staff is is ready to take the responsibility during lab sessions?//(ஆய்வக அமர்வுகளின் போது ஆசிரியர் அல்லாத பணியாளர்கள்  பொறுப்பேற்கத் தயாராக உள்ளனரா?)  "},
    {Ques_id:4,Question:" What % of skill & knowledge does the Non-Teaching staff have about all the experiments?//(அனைத்து சோதனைகளிலும் ஆசிரியர் அல்லாத பணியாளர்களுக்கு எவ்வளவு% திறன் மற்றும் அறிவு உள்ளது?) "},
    {Ques_id:5,Question:"How is the behavior of the Non-Teaching staff towards the students?//(மாணவர்களிடம் ஆசிரியர் அல்லாத பணியாளர்களின் நடத்தை எப்படி இருக்கிறது?)  "},
    {Ques_id:6,Question:"How much is the Non-Teaching staff motivated & dedicated towards his work?//(ஆசிரியர் அல்லாத பணியாளர்கள் எவ்வளவு உந்துதலுடனும் அர்ப்பணிப்புடனும் பணிபுரிகின்றனர்? )"},
    {Ques_id:7,Question:"How is the relationship of the Non-Teaching staff with his subordinates and superiors?//( ஆசிரியர் அல்லாத பணியாளர்களுக்கு அவருக்குக் கீழ் உள்ளவர்கள் மற்றும் மேலதிகாரிகளின் உறவு எப்படி இருக்கிறது?) "},
  
   
     
  ]

 

// --------------------MONTHLY VIEW TABLE--------------------------//
const Non_Teaching = () => {


 const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [themes, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
     
     const isNonMobile = useMediaQuery("(min-width:600px)");
    
     let user_details = localStorage.getItem("user_details");
    

    


     const [selectedMenu, setSelectedMenu] = useState('');
     const [selectedYear1, setSelectedYear1] = useState('');
   
     const handleChangeMenu = (event) => {
       setSelectedMenu(event.target.value);
       setSelectedYear1('');
     };
   
     const handleChangeYear = (event) => {
       setSelectedYear1(event);
     };
   
     
     const menuOptions = [
      { label: '2000-2010', subMenu: Array.from({ length: 11 }, (_, index) => 2000 + index) },
      { label: '2011-2020', subMenu: Array.from({ length: 10 }, (_, index) => 2011 + index) },
      { label: '2021-2030', subMenu: Array.from({ length: 10 }, (_, index) => 2021 + index) },
      { label: '2031-2040', subMenu: Array.from({ length: 10 }, (_, index) => 2031 + index) },
      { label: '2041-2050', subMenu: Array.from({ length: 10 }, (_, index) => 2041 + index) },
      { label: '2051-2060', subMenu: Array.from({ length: 10 }, (_, index) => 2051 + index) },
      { label: '2061-2070', subMenu: Array.from({ length: 10 }, (_, index) => 2061 + index) },
      { label: '2071-2080', subMenu: Array.from({ length: 10 }, (_, index) => 2071 + index) },
      { label: '2081-2090', subMenu: Array.from({ length: 10 }, (_, index) => 2081 + index) },
      { label: '2091-3000', subMenu: Array.from({ length: 10 }, (_, index) => 2091 + index) },

      // Add more menu options as needed
    ];
  
   
     // Generate a list of years
     const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() + index);
     const questionsPerPage = 7;
  
     const [currentPage, setCurrentPage] = useState(1);
     const indexOfLastQuestion = currentPage * questionsPerPage;
     const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
     const [currentQuestions,setCurrentQuestions]=useState([]);
     const [totalPages,setTotalPages]=useState();
    
     const classes = useStyles();


     


     
     if (user_details) {
      user_details = JSON.parse(user_details);
      console.log(user_details.Email);
    } else {
      console.log("user_details not found in local storage");
    }
   

    const handleYearChange = (date) => {
      setSelectedYear1(date.getFullYear());
    };

    const handleYear=(event)=>{
      setSelectedYear1(event);
    }
   
    const [graduate,setGraduate]=useState("");
    const [branch,setBranch]=useState("");
  const [ratings, setRatings] = useState({});
  const [ratings2, setRatings2] = useState({});
  const [staff,setStaff]=useState("");
  const [staffname,setStaffName]=useState("");
  const [lab,setLab]=useState("");
  const [date,setDate]=useState("")
  const [comment,setComment]=useState("")
  const [questionLength,setQuestionLength]=useState();

  useEffect(()=>{
    const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
    setCurrentQuestions(currentQuestions_1);
    const totalPages_1 = Math.ceil(Questionset_api.length / questionsPerPage);
    setTotalPages(totalPages_1)
    setQuestionLength(Questionset_api.length)
  
  },[]);
  
  


          const handleStaff = (event) => {
            setStaff(event)
         
        
          };
          const handleComment = (event) => {
            setComment(event)
         
        
          };
          
          
          const handleDate = (event) => {
            setDate(event)
         
        
          };
          
          const handleStaffName = (event) => {
            setStaffName(event)
         
        
          };
          const handleLab= (event) => {
            setLab(event)
         
        
          };
          const handleProgramme = (event) => { 
         
        
          };
          
        
          const handleGraduate=(event)=>{
            setGraduate(event)
          }
          const handlebranch=(event)=>{
            setBranch(event)
          }

          

          const handleRatingChange = (quesId, value) => {
           
            
            const questionKey = `q${quesId + 1}`;
            setRatings((prevRatings) => ({
              ...prevRatings,
              [questionKey]: value,
            }));

            setRatings2((prevRatings2)=>({
              ...prevRatings2,
              [quesId]: value,
            }))
            console.log(ratings, "ratingsss");
          };
        

        
        

         const handleSubmit=()=>{
       


          const isAnyRatingNull = Object.values(ratings).some((rating) => rating === "");
          
          let nullRating=true;
  
          // Loop through the questions from q1 to q16
          for (let i = 1; i <= questionLength; i++) {
            const question = "q" + i;
            const rating = ratings[question];
            if (rating==undefined){
              nullRating=false;
              break
            }
          }  

          if(staffname!=""){
            if(lab!=""){
              if(branch!=""){
                 if(selectedYear1!=""){
                  if(date!=""){
                    if(nullRating){
      
 
          
          fetch('https://feedbackapi.rcktechiees.com/api/student/non_teaching_staff', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              // "staff":(staff),
              "lab":(lab),
          "year":(selectedYear1),
          "name_staff":(staffname),
          "department": (branch),
          "date":(date),
          "comment":(comment),
          
          ...ratings
          
         

            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data,"responseupload")}
            if(data["Status"]=="1"){
              toast.success("Successfully Submitted")
            }
            else if(data["Status"]=="0"){
              toast.error("Something went wrong")
            }
          
           
          
           
          
          
          })
           .catch(error=>console.log(error))
        }
        else{
          toast.error("Please fill all the rating")
        }
        }
        else{
          toast.error("Please fill the date")
        }
        }
        else{
          toast.error("Please fill the year")
        }
        }
        else{
          toast.error("Please choose your branch")
        }
        }
        else{
          toast.error("Please fill the lab name")
        }
        }
        else{
          toast.error("Please fill the staff name")
        }

          //  toast.error("Something went wrong")


         
        }
 
         const handlePageChange = (pageNumber) => {
         
          setCurrentPage(pageNumber);
  const indexOfLastQuestion = pageNumber * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
  setCurrentQuestions(currentQuestions_1);
        };
      
      
         const Pagination = ({ currentPage, totalPages, onPageChange }) => {
          const pageNumbers = [];
      
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
      
          return (

         
          
            <Box
           
            display="flex" justifyContent="center" mt="20px"
           
          >
              {pageNumbers.map((pageNumber) => (
                 <Button variant="contained"
                  key={pageNumber}
                  onClick={() => onPageChange(pageNumber)}
                  disabled={currentPage === pageNumber}
                  className={classes.customButton}
                  sx={{  justifyContent: "center"}}
   
                >
                  {pageNumber}
                  </Button>
              ))}
            </Box>
          );
        };
      
      
      
      
      
      
      


  return (
   <>
      <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <div className="app1">
                <Sidebar isSidebar={isSidebar} feedback_type={"technician"}/>
                <main className="content1">
                    <Topbar2 setIsSidebar={setIsSidebar} feedback_type={"Non-Teaching/Technician Feedback"}/>
 

                    <Box m="20px"
       display="grid"
       gap="7px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      //  sx={{
      //    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      //  }
      //  }
       >

              {/* <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Staff"
                   value={staff}             
                  
                  
                   onChange={(event) => {handleStaff(event.target.value)}}
                   name="staff"
                   sx={{ gridColumn: "span 2" }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              /> */}
               <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Staff Name"
                   value={staffname}
                  

                   onChange={(e) => {handleStaffName(e.target.value)}}
                   name="Staff Name"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                 
                  <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Lab"
                   value={lab}             
                  
                  
                   onChange={(event) => {handleLab(event.target.value)}}
                   name="Lab"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />



   
                      <FormControl variant="filled" sx={{ gridColumn: "span 2"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={branch}
          onChange={(e) => {handlebranch(e.target.value)}}
          sx={{
            height: '42px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          <MenuItem value={"Civil"}>Civil</MenuItem>
          <MenuItem value={"Mechanical"}>Mechanical</MenuItem>
          <MenuItem value={"ECE"}>ECE</MenuItem>
          <MenuItem value={"EEE"}>EEE</MenuItem>
          <MenuItem value={"CSE"}>CSE</MenuItem>
         
        </Select>
      </FormControl>



    {/* <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Year"
                   value={selectedYear1}             
                  
                  
                   onChange={(event) => {handleChangeYear(event.target.value)}}
                   name="Lab"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              /> */}

<FormControl variant="filled" sx={{ gridColumn: "span 2"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Year</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectedYear1}
          onChange={(e) => {handleYear(e.target.value)}}
          sx={{
            height: '42px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          <MenuItem value={"1"}>1</MenuItem>
          <MenuItem value={"2"}>2</MenuItem>
          <MenuItem value={"3"}>3</MenuItem>
          <MenuItem value={"4"}>4</MenuItem>
       
         
        </Select>
      </FormControl>

              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Date"
                   value={date}
                 

                   onChange={(e) => {handleDate(e.target.value)}}
                   name="Date"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                
            

     
     
    
   
                <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" ,paddingTop:"1%",paddingBottom:"1%"}} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>


      {isNonMobile?
      <div className="stars" style={{display: 'flex', gap: '10px'}}>
      <div className="">
      <h6>5 - Excellent</h6>
      </div>
      <div className="">
      <h6>4 - Satisfied</h6>
      </div>
      <div className="">
      <h6>3 - Good</h6>
      </div>
      <div className="">
      <h6>2 - Not Satisfied</h6>
      </div>
      <div className="">
      <h6>1 - Poor</h6>
      </div>
      </div>:

<div className="stars" style={{display: 'block', gap: '10px'}}>
<div className="">
<h6>5 - Excellent</h6>
</div>
<div className="">
<h6>4 - Satisfied</h6>
</div>
<div className="">
<h6>3 - Good</h6>
</div>
<div className="">
<h6>2 - Not Satisfied</h6>
</div>
<div className="">
<h6>1 - Poor</h6>
</div>
</div>
      
      }
      <h6 style={{fontSize: '18px', fontWeight: '600'}}>Please give your valuable feedback in a scale</h6>
      {/* <h6 style={{fontSize: '15px'}}> &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest</h6> */}
        
      
      </FormControl>

      {currentQuestions.map((item) => {
  const questionParts = item.Question.split("//");
  const firstPart = questionParts.shift(); 
  return (
    <FormControl variant="filled" sx={{ gridColumn: 'span 4' }} key={item.Ques_id}>
    
      <div className="forform" style={{ gap: '15px'}}>
            <div className="col-md-7 col-lg-7" >
              <div className="English-fnt" style={{fontSize: '16px', lineHeight: '2'}}>
            {/* <Typography variant="body1"> */}
        {`${item.Ques_id + 1}. ${firstPart}`}
      {/* </Typography> */}
       </div>
      {questionParts.map((part, index) => (
          // <Typography key={index} variant="body1" style={{ whiteSpace: 'pre-line' }}>
           <div className="Tamil-fnt" style={{fontSize: '15px', lineHeight: '2'}}>
          {part}
          </div>
        //  {/* </Typography> */}
      ))}

</div>
<div className="col">
              <div className="forinsidestar" style={{display: 'flex', gap: '7px'}}>
                <div className="col">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
    
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '1' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '1')}
            >
              <i
                className={`bi bi-1-circle${ratings2[item.Ques_id] === '1' ? ' selected' : ''}`}
                aria-label="1"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '2' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '2')}
            >
              <i
                className={`bi bi-2-circle${ratings2[item.Ques_id] === '2' ? ' selected' : ''}`}
                aria-label="2"
              />
         
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '3' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '3')}
            >
              <i
                className={`bi bi-3-circle${ratings2[item.Ques_id] === '3' ? ' selected' : ''}`}
                aria-label="3"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '4' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '4')}
            >
              <i
                className={`bi bi-4-circle${ratings2[item.Ques_id] === '4' ? ' selected' : ''}`}
                aria-label="4"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '5' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '5')}
            >
              <i
                className={`bi bi-5-circle${ratings2[item.Ques_id] === '5' ? ' selected' : ''}`}
                aria-label="5"
              />
             
             </div>
            </div>
                </div>
                <div className="col">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{fontSize: '18px', color: "red"}}>{ratings2[item.Ques_id] === '1' && <Box>{labels[0]}</Box>}</div>
      <div   style={{fontSize: '18px', color: "#75746f"}}>{ratings2[item.Ques_id] === '2' && <Box>{labels[1]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#0039e6"}}>{ratings2[item.Ques_id] === '3' && <Box>{labels[2]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#e5a004"}}>{ratings2[item.Ques_id] === '4' && <Box>{labels[3]}</Box>}</div>
       <div  style={{fontSize: '18px', color: "#03b634"}}>{ratings2[item.Ques_id] === '5' && <Box>{labels[4]}</Box>}</div>
     
       </div>
                </div>
              
            
            
            </div>
            </div>
          </div>
          
     
    </FormControl>
  );
})}
      
       {currentPage==totalPages && (
      <TextField
      fullWidth
      variant="filled"
      type="text1"
      label="Comment"
      value={comment}
    

      onChange={(e) => {handleComment(e.target.value)}}
      name="Comment"
      sx={{ gridColumn: "span 4",
      "& .MuiInputBase-root.MuiFilledInput-root": {

                              border: "1px solid grey",
        
                              borderRadius: "3px",
        
                              backgroundColor: "transparent",
        
                            },
        
                            "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
        
                              backgroundColor: "transparent",
        
                              borderColor: "#01134d",
        
                            },
        
                            "& .MuiInputBase-root.MuiFilledInput-root:hover": {
        
                              backgroundColor: "transparent",
        
                              borderColor: "#01134d",
        
                            },
        
                            "& .MuiInputBase-root.MuiFilledInput-root:before": {
        
                              borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        
                            },
        
                            "& .MuiInputBase-root.MuiFilledInput-root:after": {
        
                              borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        
                            },
    }}
      inputProps={{
       style: {
         height: "9px",
       },
     }}
 />
    )} 

      {currentPage==totalPages && (
      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>

<Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'},}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button}`} 
   onClick={handleSubmit}>
                          Submit
                          </Button> 
                          <ToastContainer />
                      {/* </Box> 
              <ToastContainer /> */}
    </Box>
    </FormControl>
    )} 

<FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>
   {console.log(currentPage,"cureent",totalPages)}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
     </FormControl>
     
             
    </Box>   
    </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
 
  
   </>
  );
}
export default Non_Teaching;
