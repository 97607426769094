

import { BrowserRouter ,Routes, Route} from 'react-router-dom';
import Student_Login_Form from "./scenes/student-login-page";
import Tabs_student from "./scenes/student-login-page/Tabs.jsx"
import Bio from "./Home_Page/Main.js"
import Student_Exit from './scenes/Student_Exit.jsx/student_exit.jsx'
import Student_Discontinue from './scenes/student_discontinue/Student_Discontinue.jsx'
import Student_Suggestion from './scenes/student_suggestion/Student_Suggestion.jsx'
import Non_Teaching from './scenes/Non-Teaching/Non_Teaching.jsx'
import Placement from "./scenes/Placement/placement.jsx"
import Faculty_Login_Form from './scenes/Faculty_Login/index.jsx'
import Alumni from "./scenes/Alumni/Alumni.jsx"
import Employer from "./scenes/Employer/Employer.jsx"
import Parent from './scenes/Parent/Parent.jsx'
import Consultancy from "./scenes/Consultancy/consultancy.jsx"
import Collaborator from "./scenes/Collaborator/collaborator.jsx"
import Supplier from "./scenes/Supplier/Supplier.jsx"
import Admin_Login_Form from './scenes/Admin/index.jsx'
import Admin_Form from "./scenes/Admin/form.jsx"
import Add_Faculty from "./scenes/Admin/AddFaculty.jsx"
import Remove_Faculty from './scenes/Admin/RemoveFaculty.jsx'
import Faculty_Feedback_Final from "./scenes/Faculty_Login/faculty.jsx"
import Excel from "./scenes/Admin/Student-Excel.jsx"
import ChangePassword from "./scenes/Admin/ChangePassword"
// import './App.css';
import { useState } from "react";

// import Topbar from "./scenes/global/Topbar";
// import Sidebar from "./scenes/global/Sidebar";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { ColorModeContext, useMode } from "./theme";

function App() {
  // const [theme, colorMode] = useMode();
  // const [isSidebar, setIsSidebar] = useState(true);
  return (
   
  <BrowserRouter>
  <Routes>
      {/* <Route path="/" exact element={<Home2 />} /> */}
      {/* <Route path="/login/student" element={<Student />} /> */}
      <Route path="/login/faculty" element={<Faculty_Login_Form />} />
      <Route path="/login/admin" element={<Admin_Login_Form />} />
      <Route path="/admin_form" element={<Admin_Form />} />
      <Route path="/admin/student_excel" element={<Excel />} />
      <Route path="/admin/addfaculty" element={< Add_Faculty />} />
      <Route path="/admin/removefaculty" element={< Remove_Faculty />} />
      <Route path="/admin/changepassword" element={< ChangePassword />} />

     
      <Route path="/student_exit_feedback" element={<Student_Exit/>} />
      <Route path="/alumni_feedback" element={<Alumni />} />
      <Route path="/employer_feedback" element={<Employer />} />
      <Route path="/parent_feedback" element={<Parent />} />
      <Route path="/consultancy_feedback" element={<Consultancy />} />
      <Route path="/collaborator_feedback" element={<Collaborator />} />
      <Route path="/supplier_feedback" element={<Supplier />} />
      <Route path="/suggestion_feedback" element={<Student_Suggestion />} />
      <Route path="/teaching_feedback" element={<Non_Teaching />} />
      <Route path="/placement_feedback" element={<Placement />} />
      <Route path="/faculty_feedback" element={<Faculty_Feedback_Final />} />
     
      
      <Route path="/" exact element={<Bio/>} />
      <Route path="/login/student" exact element={<Student_Login_Form/>} />
      <Route path="/student_discontinue_feedback" element={<Student_Discontinue />} />   
     
      <Route path="/tabs" element={<Tabs_student />} />   
     
  </Routes> 
  </BrowserRouter>


  );
}

export default App;


