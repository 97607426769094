import { Box, IconButton, useTheme , Typography,Grid,Menu,MenuItem} from "@mui/material";
import { useContext } from "react";
import { useState,useEffect } from "react";
import { ColorModeContext, tokens,useLanguage } from "../../theme";
import { LanguageModeContext} from "../../theme";
import { Link } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useMode,LanguageMode } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Supplier from "../../scenes/Supplier/Supplier"

import React, { createContext} from 'react';

const ValueContext = createContext();



const Topbar = ({ feedback_type }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [selected, setSelected] = useState("Dashboard");
  // const langaugeMode = useContext(LanguageModeContext);

  // const [languagemode,languageMode] = useLanguage();


  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();




  // useEffect(() => {
   
  //   localStorage.setItem("language_setting", JSON.stringify(language));
  // }, [language])




  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
// const   handleClick = () => {
// if(language=="English"){
//   setLanguage("Tamil")
// }
// else{
//   setLanguage("English")
// }



  const [isTnClicked, setIsTnClicked] = useState(false);

//   const handleClick = () => {
//     setIsTnClicked(!isTnClicked);
//     if(language=="English"){
//   setLanguage("Tamil")
// }
// else{
//   setLanguage("English")
// }

//   };

//   const tnStyle = {
//     color: isTnClicked ? 'red' : 'blue',
//   };

//   const enStyle = {
//     color: isTnClicked ? 'blue' : 'red',
//   };


   
    
  
 

  const handleMenuItemClick = () => {
    // Handle menu item click here
    localStorage.removeItem('user_details');
    toast.success('Logged out successfully!', {
      position: toast.POSITION.TOP_CENTER,
    });

    navigate('/');
   
  };
 
  return (
   
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
       <Box
        display="flex"
        // backgroundColor={colors.primary[400]}
        borderRadius="3px"
        fontSize="35px"
        color="#01134d"
        opacity="10%"
        fontWeight="bold"
      > 
     {feedback_type}
     
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" /> */}
        {/* <IconButton type="button" sx={{ p: 1 }}> */}
          {/* <SearchIcon /> */}
        {/* </IconButton> */}
       </Box> 
{/* <Typography  onClick={handleClick}>
<span style={tnStyle}>Tamil</span>/<span style={enStyle}>English</span>

</Typography> */}


   
            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
          Mode
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            > */}
             
            {/* </Typography>
             <Item
              title="Dark/Light Mode"
             
              icon={languagemode === "English" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
              selected={langaugeMode.toggleLanguageMode}
              setSelected={langaugeMode.toggleLanguageMode}
            /> */}
              {/* <IconButton onClick={langaugeMode.toggleLanguageMode}>
          {languagemode === "English" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}

      {/* ICONS */}
      {/* <Supplier varOne={language}/> */}
     </Box>


   
  );
};

export default Topbar;
