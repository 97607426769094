import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { useState } from "react";
import Sidebar2 from "../../scenes/global/Sidebar2";
import Topbar from "../../scenes/global/Topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import { tokens } from "../../theme";

import {  Button, TextField,Select ,MenuItem,InputLabel,OutlinedInput,FormControl} from "@mui/material";
import { Formik,Form,Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate,useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiButtonBase-root.MuiRadio-root.Mui-checked':{
      color: '#f58941 !important',     
    },
  },
  button: {
    width: "150px !important",
  },
}));

const Student_Login_Form = () => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [themes, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [showOTPField,setShowOTPFIeld]=useState(false);
  const [showFirstButton, setShowFirstButton] = useState(true);
  const [showSecondButton, setShowSecondButton] = useState(false);
  const [regno,setRegNo]=useState("");
  const [email,setEmailID]=useState("");
  const [otp,setOTP]=useState("");
  const [ug,setUG]=useState("");
  const [student_name,setStudentName]=useState("");
  let ValidEmail="";
  const navigate = useNavigate();
  const emailRegex = 
  new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/);
  const regRegex=new RegExp(/[0-9]/)

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(false);
  const handleRegChange=(e)=>{
    setRegNo(e.target.value)
  }

 const handleOTPChange=(e)=>{
    setOTP(e.target.value)
  }

  const handleUGChange=(e)=>{
    setUG(e.target.value)
  }

  const handleEmailChange=(e)=>{
     setEmailID(e.target.value)
  }

  const handleOTPSubmit=(e)=>{

    const url = 'https://feedbackapi.rcktechiees.com/api/student/verifyotp';
    const data = {
      registernumber: (regno),
      OTP: (otp)
    };
    
    const formData = new URLSearchParams();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data["OTP Status"],"verify");
      if(data["OTP Status"]==1){
        
        toast.success("Login successful")

        let user_values={
          Register_Number: (regno), 
          Email: (email),
          Student_Name: (student_name),
          UG: (ug),

        }
        localStorage.setItem("user_details", JSON.stringify(user_values));
        navigate("/tabs")
      }
      else{
        toast.error("Invalid OTP")
      }
      

    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
    


   
   
  
  }

  const handleFirstButtonClick = (e) => {
    setIsLoading(true)
    let OTP_status;
  if(ug!=""){
  {console.log(email,"Email ID")}
 
  if(emailRegex.test(email)){   
   
    

      if(regRegex.test(regno) && regno.length>=7){
        {console.log(regRegex.test(regno),"Email ID")}
        {console.log(regRegex.test(regno),"Email ID")}
        let student_login_details={
              RegisterNo: (regno),
              UG_PG:(ug),
              Email_ID: (email)
    }


 
    const params = {
      registerNumber: (regno),
      email: (email),
    };
    
    const queryString = Object.entries(params).map(([key, value]) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }).join('&');
    
    fetch('https://feedbackapi.rcktechiees.com/api/Student/otpgenerate/{registerNumber}/{email}'+ '?' + queryString, {
      method: 'POST'
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data);
      console.log(data["Otp Status"],"resposne")
      OTP_status=data["Otp Status"]
      if(OTP_status==1){
        toast.success("OTP sent to your Email ID")
       setStudentName(data["Student name"])

        setShowFirstButton(false);
        setShowSecondButton(true);
        setIsLoading(false)}
      else{
        toast.error("Invalid Credentials")
        setIsLoading(false)
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      setIsLoading(false)
    });
   // get reguation api

    // fetch('https://feedbackapi.rcktechiees.com/api/Student/GetRegulation', {
    //   headers: {
    //     'Referrer-Policy': 'strict-origin-when-cross-origin',
    //     "Access-Control-Allow-Origin": "*"
    //   }
    // })
    // .then(response => response.json())
    // .then(data => console.log(data))
    // .catch(error => console.error(error));

    {console.log("values",student_login_details)}
}
    else{
     toast.error("Invalid Register Number")
    }
  
  }
  else{
     toast.error("Invalid Email")
  }

}
else{
  toast.error("Please fill all the details")
}
  }

  const handleResend= (e) => {


    //call the same api
//     let student_login_details={
//       RegisterNo: (regno),
//       UG_PG:(ug),
//       Email_ID: (email)
//     }
//     if(emailRegex.test(email) && regRegex.test(regno)){   
// toast.success("OTP sent to your email successfully")
//     }
//     else{
//       toast.success("Something went wrong")
//   }
   
    // {console.log("values",student_login_details)}
  }




  return (
     <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={themes}>
      <CssBaseline />
      <div className="app1">
        <Sidebar2 isSidebar={isSidebar} feedback_type={"student_login"}/>
        <main className="content1">
          

        
            <div className="forstudentlogin" style={{width: '40%', padding: '5px 30px', margin: '85px auto', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', background: '#fff', borderRadius: '3px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Topbar setIsSidebar={setIsSidebar} feedback_type={"Student Login"}/>
            </div>
            <div>
    <Box m="20px"
       display="grid"
       gap="7px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      
       >


    <FormControl component="fieldset" className={classes.root}  sx={{  marginBottom: '15px', '& .Mui-checked': {  color: '#f58941 !important', }, gridColumn: "span 12",  }}>
                 <label id="demo-row-radio-buttons-group-label" style={{fontSize:"24px", fontWeight: '600', marginBottom: '15px'}}  >Choose Appropriate Option</label>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="ug_pg"
                      sx={{   '& .Mui-checked': {   color: '#f58941 !important',  }}}
                      onClick={handleUGChange} >
                  <FormControlLabel value="Regular" control={<Radio  />} label="Regular" />
                  <FormControlLabel value="Lateral" control={<Radio />} label="Lateral" />
                  <FormControlLabel value="Part Time" control={<Radio />} label="Part Time" />
        
                  </RadioGroup>
            </FormControl>
              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Register Number"
                   onChange={handleRegChange}  
                   name="regno"
                   sx={{ gridColumn: "span 12",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
              /><br></br>
              
            
         
    
              <TextField
                fullWidth
                variant="filled"
                type="text1"
                label="Email"
                onChange={handleEmailChange}
                name="email"
                sx={{ gridColumn: "span 12",
                "& .MuiInputBase-root.MuiFilledInput-root": {

                                        border: "1px solid grey",
                  
                                        borderRadius: "3px",
                  
                                        backgroundColor: "transparent",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                  
                                        backgroundColor: "transparent",
                  
                                        borderColor: "#01134d",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                  
                                        backgroundColor: "transparent",
                  
                                        borderColor: "#01134d",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:before": {
                  
                                        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:after": {
                  
                                        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                  
                                      },
              }}
              />
               {showSecondButton && (<span sx={{ gridColumn: "span 12"}} style={{color:"#FF5722",fontSize:"14px", whiteSpace: 'nowrap', padding:"5px", fontWeight: '600'}}>OTP sent to your Email ID will be valid for 10 minutes.</span>)}


               {showSecondButton && (
              <TextField
                fullWidth
                variant="filled"
                type="text1"
                label="Enter OTP"
                onChange={handleOTPChange}
                name="otp"  
                sx={{ gridColumn: "span 12",
                "& .MuiInputBase-root.MuiFilledInput-root": {

                                        border: "1px solid grey",
                  
                                        borderRadius: "3px",
                  
                                        backgroundColor: "transparent",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                  
                                        backgroundColor: "transparent",
                  
                                        borderColor: "#01134d",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                  
                                        backgroundColor: "transparent",
                  
                                        borderColor: "#01134d",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:before": {
                  
                                        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                  
                                      },
                  
                                      "& .MuiInputBase-root.MuiFilledInput-root:after": {
                  
                                        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                  
                                      },
              }}
              />)}
            
     </Box>
          </div> 
               {showFirstButton && (

                      <Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, ...(isLoading && {
          '&.button--loading': { /* styles for loading state */ },
        }),}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button} ${isLoading ? 'button--loading' : ''}`} 
    onClick={handleFirstButtonClick}>
                             Generate OTP
                          </Button> 
                          <ToastContainer />
                      </Box> 
                  )}

                {showSecondButton && (
      
                      <Box display="flex" justifyContent="center" mt="20px"  sx={{  '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important' },}}>
                         <Button sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important' },}}
                           type="button" color="secondary" variant="contained" minWidth="110px !important" className={classes.button} onClick={handleOTPSubmit}>
                           Login 
                          </Button><ToastContainer /><br></br>
                          
                         {/* <Button sx={{  '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important' },color: 'grey',backgroundColor: "white",}}
                           type="button"  style={{color: "darkblue"}} variant="contained" minWidth="110px !important" className={classes.button} onClick={handleResend}>
                           Resend OTP
                        </Button> */}
                        <ToastContainer />
                      </Box>
                      
                      )}
                      {showSecondButton && (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <a onClick={handleFirstButtonClick} style={{color: '#0a58ca', textAlign: 'center', fontSize: '15px', fontWeight: '600', padding: '15px', cursor: 'pointer'}}>Resend OTP</a>
                      </div>
                      )}
                      </div>
    </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};


//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;



 

export default Student_Login_Form;
