
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
// import Button from 'react-bootstrap/Button';
import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "./feedback.css"
import { Icon } from '@mui/material';


import StarIcon from '@mui/icons-material/Star';

import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { makeStyles } from '@material-ui/core/styles';
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const starIcons = [
    '★', '★', '★', '★', '★',
  ];

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));


  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  
  
  
  


  const CustomRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '&.MuiIconButton-root': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      border: '1px solid #ccc',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '8px',
      color: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-checked': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
    },
  }));
  
 
  
    
  
  
  
  
  
  
  

  
  
  const useStyles = makeStyles({
    customButton: {
      borderRadius: '50%',
    },
  });
  
  
  
  



  const customIcons= {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: 'Very Satisfied',
    },
  };
  
  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

// --------------------MONTHLY VIEW TABLE--------------------------//
function Feedback_tab({
  tabss,
  selectDepartment,
  SetselectDepartment,
  selectRegulation,
  SetselectRegulation,
  // selectCourse,
  // SetselectCourse,
  selectUG,
  SetselectUG,
  selectSemester,
  SetselectSemester,
  selectFT,
  SetselectFT,
  selectCourse,
 SetselectCourse,
 selectSubject,
 SetselectSubject,
 selectCode,
 SetselectCode,
 SetCourseID,
 courseID
}) 
{


 const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
     
     const isNonMobile = useMediaQuery("(min-width:600px)");
     const [formValues, setFormValues] = useState(null);
     const [selectedRating, setSelectedRating] = useState(null);
     const [selectedStar, setSelectedStar] = useState({star1:0,star2:0,star3:0,star4:0,star5:0,star6:0,star7:0,star8:0,star9:0,star10:0,star11:0,star12:0});
     const [selectedStar2, setSelectedStar2] = useState(0);
     const [finalValues,setFinalValues] = useState();
     const [name,setName]=useState("");
     const [comment,setComment]=useState("");
     const [programme,setProgramme]=useState("UG");
     const [branch,setBranch]=useState("Civil");
     const [principal,setPrincipal]=useState("");
     const [name_authority,setNameAuthority]=useState("");
     const [year1,setYear1]=useState("");
     const [year2,setYear2]=useState("");
     const [year3,setYear3]=useState("");
     const [isLoading,setIsLoading]=useState(true);
     const [faculty,setFaculty]=useState("");
     const [facultyID,setFacultyID]=useState("")
     let user_details = localStorage.getItem("user_details");
     const[FacultyName,setFacultyName]=useState([]);
     const[QuestionName,setQuestionName]=useState([]);
     const [questionLength,setQuestionLength]=useState();

     const [FacultyDetails,setFacultyDetails]=useState([])
     const [Questionset_api,setQuestions]=useState([])


     const [value1, setValue1] = useState(2);
     const [hover, setHover] = useState(-1);
     const questionsPerPage = 5;
  
     const [currentPage, setCurrentPage] = useState(1);
     const indexOfLastQuestion = currentPage * questionsPerPage;
     const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
     const [currentQuestions,setCurrentQuestions]=useState([]);
     const [totalPages,setTotalPages]=useState();
    
     const classes = useStyles();


     


     
     if (user_details) {
      user_details = JSON.parse(user_details);
      console.log(user_details.Email);
    } else {
      console.log("user_details not found in local storage");
    }
  

    const [star1,setStar1]=useState();
    const [star2,setStar2]=useState();
    const [star3,setStar3]=useState();
    const [star4,setStar4]=useState();
    const [star5,setStar5]=useState();
    const [star6,setStar6]=useState(); 
    const [star7,setStar7]=useState();
    const [star8,setStar8]=useState();
    const [star9,setStar9]=useState();
    const [star10,setStar10]=useState();
    const [star11,setStar11]=useState();
    const [star12,setStar12]=useState();


    const handleChange1=(e)=>{
    
     setStar1(e)
   }
   const handleChange2=(e)=>{
    
    setStar2(e)
  }
  const handleChange3=(e)=>{
    
    setStar3(e)
  }
  const handleChange4=(e)=>{
    
    setStar4(e)
  
  }
  const handleChange5=(e)=>{
    
    setStar5(e)
  }
  const handleChange6=(e)=>{
    
    setStar6(e)
  }
  const handleChange7=(e)=>{
    
    setStar7(e)
  }
  const handleChange8=(e)=>{
    
    setStar8(e)
  }
  const handleChange9=(e)=>{
    
    setStar9(e)
  }
  const handleChange10=(e)=>{
    
    setStar10(e)
  }
  const handleChange11=(e)=>{
    
    setStar11(e)
  }
  const handleChange12=(e)=>{
    
    setStar12(e)
  }
  const [ratings, setRatings] = useState({});
  const [ratings2, setRatings2] = useState({});

     const handleStar = (index,id) => {
        if(id=="1"){setSelectedStar(prevState => ({...prevState,star1: (index),}))}
        if(id=="2"){setSelectedStar(prevState => ({...prevState,star2: (index), }))}
        if(id=="3"){setSelectedStar(prevState => ({...prevState,star3: (index), }))}
        if(id=="4"){setSelectedStar(prevState => ({...prevState,star4: (index), }))}
        if(id=="5"){setSelectedStar(prevState => ({...prevState,star5: (index), }))}
        if(id=="6"){setSelectedStar(prevState => ({...prevState,star6: (index), }))}
        if(id=="7"){setSelectedStar(prevState => ({...prevState,star7: (index), }))}
        if(id=="8"){setSelectedStar(prevState => ({...prevState,star8: (index), }))}
        if(id=="9"){setSelectedStar(prevState => ({...prevState,star9: (index), }))}
        if(id=="10"){setSelectedStar(prevState => ({...prevState,star10: (index), }))}
        if(id=="11"){setSelectedStar(prevState => ({...prevState,star11: (index), }))}
        if(id=="12"){setSelectedStar(prevState => ({...prevState,star12: (index), }))}
        
              
         }

         console.log(selectSubject,"subject_code")
         const handleRegulation=(e)=>{
            // setRegulation(e.target.value)
             SetselectRegulation(e)
         }
    
         const handleDepartment=(e)=>{
            console.log(e,"e")
            SetselectDepartment(e)
         }
    
        //  const handleSemester=(e)=>{
        //   SetselectSemester(e)
        //  }
    
        //  const handleCourse=(e)=>{
        //   SetselectCourse(e)
        //  }
    
         const handleUG=(e)=>{
          SetselectUG(e)
         }
         const handleFaculty=(e)=>{
       setFaculty(e)


       for(let i=0;i<FacultyDetails.length;i++){
        if(FacultyDetails[i].Faculty_name==e){
          setFacultyID(FacultyDetails[i].Faculty_id)
        }
       }
      
      //  if(e=="Dr.A.Sivanantha Raja"){
      

      //   setFacultyID("1021")

      //  }
      // else if(e=="D.Helena Margaret"){
      //   setFacultyID("1196")
      //   {console.log("hello")}
      //  }
      // else if(e=="C.Mahendran"){
      //   setFacultyID("1215")
      //  }
      //  else if(e=="B.RajeshKanna"){
      //   setFacultyID("1248")
      //  }
      //  else if(e=="G.Dhevanandhini"){
      //   setFacultyID("1249")
      //  }
      //  else if(e=="C.Karthika Pragadheeswari"){
      //   setFacultyID("1250")
      //  }
      //  else if(e=="C.Somasundharam"){
      //   setFacultyID("1253")
      //  }
      //  else if(e=="P.Ramya"){
      //   setFacultyID("1258")
      //  }

      //  else if(e=="C.Sinduja"){
      //   setFacultyID("2095")
      //  }
      //  else if(e=="P.Sezhina Kanakambika"){
      //   setFacultyID("2167")
      //  }
      //  else if(e=="M.Aruna Rani"){
      //   setFacultyID("2186")
      //  }
      //  else if(e=="P.Keerthana"){
      //   setFacultyID("2202")
      //  }
      //  else if(e=="X.Merlin Sheeba"){
      //   setFacultyID("2203")
      //  }
       
  
         }
    
    
         const handleFT=(e)=>{
          SetselectFT(e)
         }
         const handleName = (event) => {
            setName(event.target.value);
         
        
          };
          const handleComment = (event) => {
            setComment(event.target.value);
         
        
          };
           
          const handlePrincipal = (event) => {
            setName(event.target.value);
         
        
          };
           
          const handleNameAuthority = (event) => {
            setName(event.target.value);
         
        
          };
           
         
          
          const handleBranch = (event) => {
            setBranch(event.target.value);
         
        
          };
     
     
       
        
         
          const handleYear1 = (event) => {
            setYear1(event.target.value);
         
        
          };
          const handleYear2 = (event) => {
            setYear2(event.target.value);
         
        
          };
        
          const handleYear3 = (event) => {
            setYear3(event.target.value);
         
        
          };

          const handleRegister = (event) => {
           
         
        
          };
          const handleProgramme = (event) => {
           
         
        
          };
          const handleSemester = (event) => {
           
         
        
          };
          const handleCourse = (event) => {
           
         
        
          };
          const handleSubject = (event) => {
           
         
        
          };

          const handleRatingChange = (quesId, value) => {
           
            
            const questionKey = `q${quesId + 1}`;
            setRatings((prevRatings) => ({
              ...prevRatings,
              [questionKey]: value,
            }));

            setRatings2((prevRatings2)=>({
              ...prevRatings2,
              [quesId]: value,
            }))
            console.log(ratings, "ratingsss");
          };
        

          

          useEffect(()=>{
            setIsLoading(true)
            setFacultyID("")
            setRatings([])
            setRatings2([])

            fetch('https://feedbackapi.rcktechiees.com/api/Student/GetFormData', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              
              "department_id": (selectDepartment),
              "semester": (selectSemester),
              "reg_no": (user_details.Register_Number),
              "subject_code":(selectSubject),
              "subject_name":(selectCode),
              "type_of_course":(selectCourse),
              "course_type_id":(courseID),
            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            console.log(data.Faculty_details,"get data");
            console.log(typeof(data),"resposne1111111111")

            // const facultyDetails=data.Faculty_details
            // const facultyDetailsArray = facultyDetails.map(faculty => ({
            //   id: faculty.Faculty_id,
            //   name: faculty.Faculty_name
            // }));
            // const myArray = Object.entries(data.Faculty_details);

            // setFacultyName(facultyDetailsArray)              
            let facultyDetails_api=data.Faculty_details

            setFacultyDetails(facultyDetails_api)

            let Questionset_api=data.Questions
            setQuestions(Questionset_api)
            {console.log(Questionset_api,"Questionset_api")}

           let currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
            setCurrentQuestions(currentQuestions_1)

         
           let totalPages_1 = Math.ceil(Questionset_api.length / questionsPerPage);
           setTotalPages(totalPages_1)



            const QuestionName_api = Questionset_api.map((question) => question.Question);
            setQuestionName(QuestionName_api)
            {console.log(QuestionName_api,"QuestionName_apiQuestionName_api")}
            setQuestionLength(QuestionName_api.length)
            {console.log(questionLength,"QuestionName_apiQuestionName_api")}



 {console.log("Faculty Name",FacultyDetails)}
            const facultyNames_api = facultyDetails_api.map((faculty) => faculty.Faculty_name);

            setFacultyName(facultyNames_api)

            {console.log("Faculty Name",FacultyName)}
           
         
                 
                 
                 
           
              setIsLoading(false)
          
         
          })
           .catch(error=>console.log(error))
          },[selectDepartment,selectRegulation,selectSemester,selectFT,selectUG,selectSubject,selectCode,selectCourse]);
          
        

         const handleSubmit=()=>{
          const isAnyRatingNull = Object.values(ratings).some((rating) => rating === "");
          // alert(isAnyRatingNull)
          let nullRating=false;
  
          // Loop through the questions from q1 to q16
          for (let i = 1; i <= questionLength; i++) {
            const question = "q" + i;
            const rating = ratings[question];
            if (rating==undefined){
              nullRating=true;
              break
            }

            
          
          }

          if(facultyID==""){
            toast.error("Please Choose the Faculty")
          }
      
  else if (nullRating) {
    // At least one rating is null
    toast.error("Please fill all the details")
  } 
          else{

          fetch('https://feedbackapi.rcktechiees.com/api/student/UploadQuesDetails', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              "reg_no":(user_details.Register_Number),
          "sub_code":(selectSubject),
          "sub_name":(selectCode),
          "email":(user_details.Email),
          "faculty_id":(facultyID),
          ...ratings
          
          // "q1":(star1),
          // "q2":(star2),
          // "q3":(star3),
          // "q4":(star4),
          // "q5":(star5),
          // "q6":(star6),
          // "q7":(star7),
          // "q8":(star8),
          // "q9":(star9),
          // "q10":(star10),
          // "q11":(star11),
          // "q12":(star12)

            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data,"responseupload")}
            if(data["Upload status"]=="1"){
              toast.success("Successfully Submitted")
            }
            else if(data["Upload status"]=="0"){
              toast.error("Something went wrong")
            }
          
           
          
           
          
          
          })
           .catch(error=>console.log(error))
        


          //  toast.error("Something went wrong")


         }

        }
 
         const handlePageChange = (pageNumber) => {
          // setCurrentPage(pageNumber);
          setCurrentPage(pageNumber);
  const indexOfLastQuestion = pageNumber * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
  setCurrentQuestions(currentQuestions_1);
        };
      
      
         const Pagination = ({ currentPage, totalPages, onPageChange }) => {
          const pageNumbers = [];
      
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
      
          return (
            <Box
           
            display="flex" justifyContent="center" mt="20px"
           
          >
              {pageNumbers.map((pageNumber) => (
                 <Button variant="contained"
                  key={pageNumber}
                  onClick={() => onPageChange(pageNumber)}
                  disabled={currentPage === pageNumber}
                  className={classes.customButton}
                  sx={{  justifyContent: "center"}}
   
                >
                  {pageNumber}
                  </Button>
              ))}
            </Box>
          );
        };
      
      
      
      
      
      
      


  return (
   <>
   {console.log(currentQuestions,"upreturn")}
 {isLoading || FacultyName==null || currentQuestions==undefined ? <Spinner/>: (
<Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
   
              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Reg No"
                   value={user_details.Register_Number}             
                   disabled      
                  
                   onChange={(event) => {handleRegister(event.target.value)}}
                   name=""
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
    <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Programme"
                   value={"B.E"}
                   disabled   

                   onChange={(e) => {handleProgramme(e)}}
                   name="Programme"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                  <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Semester"
                   value={selectSemester}
                   disabled   
                   onChange={(e) => {handleSemester(e)}}
                   name="Semester"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                  <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Course Code"
                  disabled   
                   value={selectSubject}
                   onChange={(e) => {handleCourse(e)}}
                   name="Course"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                  <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Course Title"
                   value={selectCode}
                   disabled   
                  
                   onChange={(e) => {handleSubject(e)}}
                   name="Subject"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />   
               <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Email ID"
                   value={user_details.Email}     
                   disabled                 
                  
                   onChange={(e) => {handleName(e)}}
                   name="Programme"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />   

              <FormControl variant="filled" sx={{ gridColumn: "span 2"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Faculty</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
         
          onChange={(e) => {handleFaculty(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
            height: '42px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >

        {FacultyName.map((item) => (
      <MenuItem key={item} value={ item} >
        {item}
      </MenuItem>
   ))}
        
        {/* <MenuItem value={"Dr.A.Sivanantha Raja"}>Dr.A.Sivanantha Raja</MenuItem>

        <MenuItem value={"D.Helena Margaret"}>D.Helena Margaret</MenuItem>
        <MenuItem value={"C.Mahendran"}>C.Mahendran</MenuItem>
        <MenuItem value={"B.RajeshKanna"}>B.RajeshKanna</MenuItem> 
        <MenuItem value={"G.Dhevanandhini"}>G.Dhevanandhini</MenuItem>
        <MenuItem value={"C.Karthika Pragadheeswari"}>C.Karthika Pragadheeswari</MenuItem>
        <MenuItem value={"C.Somasundharam"}>C.Somasundharam</MenuItem>
        <MenuItem value={"P.Ramya"}>P.Ramya</MenuItem>
        <MenuItem value={"C.Sinduja"}>C.Sinduja</MenuItem>
        <MenuItem value={"P.Sezhina Kanakambika"}>P.Sezhina Kanakambika</MenuItem>
        <MenuItem value={"M.Aruna Rani"}>M.Aruna Rani</MenuItem>
        <MenuItem value={"P.Keerthana"}>P.Keerthana</MenuItem>
        <MenuItem value={"X.Merlin Sheeba"}>X.Merlin Sheeba</MenuItem> */}

         
        </Select>
      </FormControl>
    
 
    
   
      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>


      {isNonMobile?
      <div className="stars" style={{display: 'flex', gap: '10px'}}>
      <div className="">
      <h6>5 - Excellent</h6>
      </div>
      <div className="">
      <h6>4 - Satisfied</h6>
      </div>
      <div className="">
      <h6>3 - Good</h6>
      </div>
      <div className="">
      <h6>2 - Not Satisfied</h6>
      </div>
      <div className="">
      <h6>1 - Poor</h6>
      </div>
      </div>:

<div className="stars" style={{display: 'block', gap: '10px'}}>
<div className="">
<h6>5 - Excellent</h6>
</div>
<div className="">
<h6>4 - Satisfied</h6>
</div>
<div className="">
<h6>3 - Good</h6>
</div>
<div className="">
<h6>2 - Not Satisfied</h6>
</div>
<div className="">
<h6>1 - Poor</h6>
</div>
</div>
      
      }
      <h6 style={{fontSize: '18px', fontWeight: '600'}}>Please give your valuable feedback in a scale</h6>
      {/* <h6 style={{fontSize: '15px'}}> &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest</h6> */}
        
      
      </FormControl>


      {/* {currentQuestions.map((item) => (
        <FormControl variant="filled" sx={{ gridColumn: 'span 4' }} key={item.Ques_id}>
          {item.Ques_id + 1}. {item.Question}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '1' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '1')}
            >
              <i
                className={`bi bi-1-circle${ratings2[item.Ques_id] === '1' ? ' selected' : ''}`}
                aria-label="1"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '2' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '2')}
            >
              <i
                className={`bi bi-2-circle${ratings2[item.Ques_id] === '2' ? ' selected' : ''}`}
                aria-label="2"
              />
         
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '3' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '3')}
            >
              <i
                className={`bi bi-3-circle${ratings2[item.Ques_id] === '3' ? ' selected' : ''}`}
                aria-label="3"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '4' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '4')}
            >
              <i
                className={`bi bi-4-circle${ratings2[item.Ques_id] === '4' ? ' selected' : ''}`}
                aria-label="4"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '5' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '5')}
            >
              <i
                className={`bi bi-5-circle${ratings2[item.Ques_id] === '5' ? ' selected' : ''}`}
                aria-label="5"
              />
             
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{color: "green"}}>{ratings2[item.Ques_id] === '1' && <Box>{labels[0]}</Box>}</div>
      <div  style={{color: "green"}}>{ratings2[item.Ques_id] === '2' && <Box>{labels[1]}</Box>}</div>
       <div  style={{color: "green"}}>{ratings2[item.Ques_id] === '3' && <Box>{labels[2]}</Box>}</div>
       <div  style={{color: "green"}}>{ratings2[item.Ques_id] === '4' && <Box>{labels[3]}</Box>}</div>
       <div  style={{color: "green"}}>{ratings2[item.Ques_id] === '5' && <Box>{labels[4]}</Box>}</div>
     
    </div>
            </div>
        </FormControl>
      ))} */}
     
     {currentQuestions.map((item) => {
  const questionParts = item.Question.split("/");
  const firstPart = questionParts.shift(); 
  return (
    <FormControl variant="filled" sx={{ gridColumn: 'span 4' }} key={item.Ques_id}>
    
      <div className="forform" style={{ gap: '15px'}}>
            <div className="col-md-7 col-lg-7" >
              <div className="English-fnt" style={{fontSize: '16px', lineHeight: '2'}}>
            {/* <Typography variant="body1"> */}
        {`${item.Ques_id + 1}. ${firstPart}`}
      {/* </Typography> */}
       </div>
      {questionParts.map((part, index) => (
          // <Typography key={index} variant="body1" style={{ whiteSpace: 'pre-line' }}>
           <div className="Tamil-fnt" style={{fontSize: '15px', lineHeight: '2'}}>
          {part}
          </div>
        //  {/* </Typography> */}
      ))}

</div>
<div className="col">
              <div className="forinsidestar" style={{display: 'flex', gap: '7px'}}>
                <div className="col">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
    
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '1' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '1')}
            >
              <i
                className={`bi bi-1-circle${ratings2[item.Ques_id] === '1' ? ' selected' : ''}`}
                aria-label="1"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '2' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '2')}
            >
              <i
                className={`bi bi-2-circle${ratings2[item.Ques_id] === '2' ? ' selected' : ''}`}
                aria-label="2"
              />
         
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '3' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '3')}
            >
              <i
                className={`bi bi-3-circle${ratings2[item.Ques_id] === '3' ? ' selected' : ''}`}
                aria-label="3"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '4' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '4')}
            >
              <i
                className={`bi bi-4-circle${ratings2[item.Ques_id] === '4' ? ' selected' : ''}`}
                aria-label="4"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '5' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '5')}
            >
              <i
                className={`bi bi-5-circle${ratings2[item.Ques_id] === '5' ? ' selected' : ''}`}
                aria-label="5"
              />
             
             </div>
            </div>
                </div>
                <div className="col">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{fontSize: '18px', color: "red"}}>{ratings2[item.Ques_id] === '1' && <Box>{labels[0]}</Box>}</div>
      <div   style={{fontSize: '18px', color: "#75746f"}}>{ratings2[item.Ques_id] === '2' && <Box>{labels[1]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#0039e6"}}>{ratings2[item.Ques_id] === '3' && <Box>{labels[2]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#e5a004"}}>{ratings2[item.Ques_id] === '4' && <Box>{labels[3]}</Box>}</div>
       <div  style={{fontSize: '18px', color: "#03b634"}}>{ratings2[item.Ques_id] === '5' && <Box>{labels[4]}</Box>}</div>
     
       </div>
                </div>
              
            
            
            </div>
            </div>
          </div>
          
     
    </FormControl>
  );
})}

      {/* {currentQuestions.map((item)=>(
  
  <FormControl variant="filled" sx={{ gridColumn: "span 4"}} inputProps={{
                    style: {
                      height: "9px",
                       
                    },
              }}>
                   {item.Ques_id+1}. {item.Question}
                   <div style={{ display: 'flex', alignItems: 'center',justifyContent:"center" }}>

        <Rating
        sx={{  justifyContent: "center"}}
        name={`rating-${item.Ques_id}`}
     value={ratings2[item.Ques_id] || null}
       key={item.Ques_id}
        onChange={(event) => handleRatingChange(item.Ques_id, event.target.value)}
         
          precision={1}
          getLabelText={() => null} 
        
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
      {ratings2[item.Ques_id] !== null && (
      <Box>{labels[hover !== -1 ? hover : ratings2[item.Ques_id]]}</Box>
    )}
    </div>
    
      
      </FormControl>
      ))} */}
      {currentPage==totalPages && (
      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>

     
<Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'},}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button}`} 
   onClick={handleSubmit}>
                          Submit
                          </Button> 
                          <ToastContainer />
                      {/* </Box> 
              <ToastContainer /> */}
    </Box>
    </FormControl>)}

      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>



   
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
     </FormControl>

      
      
     
             
              
            
           
    
             


            
             
    </Box>   )
        }
 
  
   </>
  );
}
export default Feedback_tab;
