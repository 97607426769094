import { useState,useContext } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ColorModeContext,tokens,LanguageModeContext } from "../../theme";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LanguageIcon from "@mui/icons-material/LanguageOutlined"
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({feedback_type}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

console.log(feedback_type,"type")
 
  const colorMode = useContext(ColorModeContext);
  // const langaugeMode = useContext(LanguageModeContext);


  // const [isTnClicked, setIsTnClicked] = useState(false);

//   const handleClick = () => {
//     setIsTnClicked(!isTnClicked);
//     if(language=="English"){
//   setLanguage("Tamil")
// }
// else{
//   setLanguage("English")
// }

//   };

//   const tnStyle = {
//     color: isTnClicked ? 'red' : 'blue',
//   };

//   const enStyle = {
//     color: isTnClicked ? 'blue' : 'red',
//   };

  

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#fff !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
           icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >

            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              {/* <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={User}
                  style={{ cursor: "pointer", borderRadius: "50%" ,color:"white"}}
                />
              </Box> */}
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                 ACGCET FEEDBACK PORTAL
                </Typography>
                {/* <Typography variant="h5" color={colors.greenAccent[500]}>
                Email ID
                </Typography> */}
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "2%"}>
          {feedback_type!="student"?<>
{isCollapsed?
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
             Form 
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             
            </Typography>} 
            <Item
              title="Student"
              to="/tabs"
              icon={<QuestionAnswerIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             </> : null}
             {feedback_type!="exit"?<>
             {isCollapsed?
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
              Form 
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             
            </Typography>}
            <Item
              title="Student Exit"
              to="/student_exit_feedback"
              icon={<QuestionAnswerIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              </> : null}
              {feedback_type!="discontinue"?<>
             {isCollapsed?
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
              Form 
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             
            </Typography>}
            <Item
              title="Student Discontinue"
              to="/student_discontinue_feedback"
              icon={<QuestionAnswerIcon />}
              selected={selected}
              setSelected={setSelected}
            />
                </> : null}
                {feedback_type!="suggestion"?<>
             {isCollapsed?
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
              Form 
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >

             
            </Typography>}
            <Item
              title="Student Suggestion"
              to="/suggestion_feedback"
              icon={<QuestionAnswerIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              </> : null}
              {feedback_type!="technician"?<>
 {isCollapsed?
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
          Form 
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             
            </Typography>}
           
            <Item
              title="Non Teaching/Technician"
              to="/teaching_feedback"
              icon={<QuestionAnswerIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             </> : null}
             {feedback_type!="placement"?<>
             {isCollapsed?
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
          Form 
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             
            </Typography>}
            <Item
              title="Placement Training"
              to="/placement_feedback"
              icon={<QuestionAnswerIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              </> : null}
            
              {/* {isCollapsed?
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
          Mode
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             
            </Typography>}
             <Item
              title="Dark/Light Mode"
             
              icon={theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
              selected={colorMode.toggleColorMode}
              setSelected={colorMode.toggleColorMode}
            /> */}
              {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
             {/* {isCollapsed?
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              selected={selected}
              setSelected={setSelected}
            >
          Language
            </Typography>:<Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             
            </Typography>}
             <Item
              title="Tamil|English"
             
              icon={<LanguageIcon />}
             
            
              selected={colorMode.toggleColorMode}
              setSelected={colorMode.toggleColorMode}
            /> 
               <IconButton onClick={handleClick}>
               <LanguageIcon />
        </IconButton> */}
            
            
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
