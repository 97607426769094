
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Sidebar2 from "../global/Sidebar2";
import Topbar from "../global/Topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Form from "react-bootstrap/Form";
import YearPicker from "../Student_Exit.jsx/yearPicker"

import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "../student-login-page/feedback.css"
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';







import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import Rating, { IconContainerProps } from '@mui/material/Rating';

import { makeStyles } from '@material-ui/core/styles';

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));


  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  
  const useStyles = makeStyles({
    customButton: {
      borderRadius: '50%',
    },
  });
  
 

  const Questionset_api=[
    {Ques_id:0,Question:"Technical knowledge/skill//(தொழில்நுட்ப அறிவு/திறன்)"},
    {Ques_id:1,Question:"Communication skill//(தொடர்பு திறன்)"},
    {Ques_id:2,Question:"Independent thinking and problem solving ability//(சுய‌ சிந்தனை மற்றும் சிக்கலை தீர்க்கும் திறன்)"},
    {Ques_id:3,Question:"Inclination to adopt new technology//(புதிய தொழில்நுட்பத்தை கடைப்பிடிக்க விருப்பம்)"},
    {Ques_id:4,Question:"Ability to take up extra responsibility//(கூடுதல் பொறுப்பை ஏற்கும் திறன்)"},
    {Ques_id:5,Question:"Obligation to work beyond schedule if required//(தேவைப்பட்டால் அட்டவணைக்கு அப்பால் வேலை செய்ய வேண்டிய கடமை)"},
    {Ques_id:6,Question:" Ability to contribute to the goal of the organization//(நிறுவனத்தின் இலக்குக்கு பங்களிக்கும் திறன்)"},
    {Ques_id:7,Question:"Planning and organization skills//(திட்டமிடல் மற்றும் ஒருங்கிணைக்கும் திறன்கள்)"},
    {Ques_id:8,Question:"Possess leadership qualities//(தலைமை பண்புகளை கொண்டவர்)"},

    {Ques_id:9,Question:"Relationship with seniors and subordinates//(மூத்தவர் மற்றும் துணை அதிகாரிகளுடனான உறவு)"},


    // {Ques_id:9,Question:"He/she mingles better with his/her peer group"},
    // {Ques_id:10,Question:"He/she has many professional friends"},
    // {Ques_id:11,Question:"He/she is talented to lead a group"},
    // {Ques_id:12,Question:"He/She has ability to communicate effectively"},
    // {Ques_id:13,Question:"He/She expresses his/her ideas clearly"},
    
    // {Ques_id:14,Question:"His/her actions are found to be more ethical in his/her work"},
    // {Ques_id:15,Question:"He/she is concerned about his/her contributions to the society"},
   
   
     
  ]

 

// --------------------MONTHLY VIEW TABLE--------------------------//
const Employer = () => {


 const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [themes, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
     
     const isNonMobile = useMediaQuery("(min-width:600px)");
    
     let user_details = localStorage.getItem("user_details");
    

    


     const [selectedMenu, setSelectedMenu] = useState('');
     const [selectedYear1, setSelectedYear1] = useState('');
   
     const handleChangeMenu = (event) => {
       setSelectedMenu(event.target.value);
       setSelectedYear1('');
     };
   
     const handleChangeYear = (event) => {
       setSelectedYear1(event);
     };
   
     
     const menuOptions = [
      { label: '2000-2010', subMenu: Array.from({ length: 11 }, (_, index) => 2000 + index) },
      { label: '2011-2020', subMenu: Array.from({ length: 10 }, (_, index) => 2011 + index) },
      { label: '2021-2030', subMenu: Array.from({ length: 10 }, (_, index) => 2021 + index) },
      { label: '2031-2040', subMenu: Array.from({ length: 10 }, (_, index) => 2031 + index) },
      { label: '2041-2050', subMenu: Array.from({ length: 10 }, (_, index) => 2041 + index) },
      { label: '2051-2060', subMenu: Array.from({ length: 10 }, (_, index) => 2051 + index) },
      { label: '2061-2070', subMenu: Array.from({ length: 10 }, (_, index) => 2061 + index) },
      { label: '2071-2080', subMenu: Array.from({ length: 10 }, (_, index) => 2071 + index) },
      { label: '2081-2090', subMenu: Array.from({ length: 10 }, (_, index) => 2081 + index) },
      { label: '2091-3000', subMenu: Array.from({ length: 10 }, (_, index) => 2091 + index) },

      // Add more menu options as needed
    ];
  
   
     // Generate a list of years
     const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() + index);
     const questionsPerPage = 5;
  
     const [currentPage, setCurrentPage] = useState(1);
     const indexOfLastQuestion = currentPage * questionsPerPage;
     const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
     const [currentQuestions,setCurrentQuestions]=useState([]);
     const [totalPages,setTotalPages]=useState();
    
     const classes = useStyles();


     


     
     if (user_details) {
      user_details = JSON.parse(user_details);
      console.log(user_details.Email);
    } else {
      console.log("user_details not found in local storage");
    }
    // const [selectedYear1, setSelectedYear1] = useState(null);

    // const handleYearChange = (date) => {
    //   setSelectedYear1(date.getFullYear());
    // };

   
    const [graduate,setGraduate]=useState("");
    const [branch,setBranch]=useState("");
  const [ratings, setRatings] = useState({});
  const [ratings2, setRatings2] = useState({});
  const [father_name,setFatherName]=useState("");
  const [course_branch,setCourseBranch]=useState("")
  const [adm_no,setAdmNo]=useState("")
  const [alumni_name,setAlumni]=useState("")
  const [programme,setProgramme]=useState("")
  const [city,setCity]=useState("")
  const [higherProgramme,setHigherProgramme]=useState("")
  const [higherInstitution,setHigherInstitution]=useState("")
  const [higherScore,setHigherScore]=useState("")
  
  const [company,setCompany]=useState("")
  const [higherExam,setHigherExam]=useState("")
  const [higherScoreExam,setHigherScoreExam]=useState("")
  const [CompanyAddress,setCompanyAddress]=useState("")
  const [positionentry,setPositionEntry]=useState("")
  const [positioncurrent,setPositionCurrent]=useState("")
  const [responsibility,setResponsibilty]=useState("")
  const [awards,setAward]=useState("")
  const [student_name,setStudentName]=useState("")
  
  const [employer_name,setEmployerName]=useState("")
  const [questionLength,setQuestionLength]=useState();
  
  
  

  useEffect(()=>{
    const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
    setCurrentQuestions(currentQuestions_1);
    const totalPages_1 = Math.ceil(Questionset_api.length / questionsPerPage);
    setTotalPages(totalPages_1)
    setQuestionLength(Questionset_api.length)
  
  },[]);
  
  
  const handleCompanyAddress = (event) => {
    setCompanyAddress(event)
  
 
   };
   const handleEmployer = (event) => {
    setEmployerName(event)
  
 
   };
   const handleName = (event) => {
    setStudentName(event)
  
 
   };
   const handlePositionEntry= (event) => {
    setPositionEntry(event)
  
 
   };
   const handlePositionCurrent = (event) => {
    setPositionCurrent(event)
  
 
   };
   const handleResponsibility = (event) => {
    setResponsibilty(event)
  
 
   };
   const handleAward = (event) => {
    setAward(event)
  
 
   };



          const handleAlumni = (event) => {
           setAlumni(event)
         
        
          };
          const handleProgramme= (event) => {
            setProgramme(event)
          
         
           };
           const handleBranch = (event) => {
            setBranch(event)
          
         
           };
          const handleRegister = (event) => {
            setAlumni(event)
          
         
           };
           const handleHigherProgramme = (event) => {
            setHigherProgramme(event)
          
         
           };
           const handleHigherInstitution = (event) => {
            setHigherInstitution(event)
          
         
           };
           const handleHigherScore = (event) => {
            setHigherScore(event)
          
         
           };
           const handleHigherScoreExam = (event) => {
            setHigherScoreExam(event)
          
         
           };
           const handleHigherExam = (event) => {
            setHigherExam(event)
          
         
           };

           const handleCompany = (event) => {
            setCompany(event)
          
         
           };
           const handleCity = (event) => {
            setCity(event)
          
         
           };
          const handleFatherName = (event) => { 
         
            setFatherName(event);
          };
          const handleCourseBranch = (event) => { 
         
            setCourseBranch(event);
          };
          const handleAdmNo = (event) => { 
         
            setAdmNo(event);
          };
          
        
          const handleGraduate=(event)=>{
            setGraduate(event)
          }
          const handlebranch=(event)=>{
            setBranch(event)
          }

          

          const handleRatingChange = (quesId, value) => {
           
            
            const questionKey = `q${quesId + 1}`;
            setRatings((prevRatings) => ({
              ...prevRatings,
              [questionKey]: value,
            }));

            setRatings2((prevRatings2)=>({
              ...prevRatings2,
              [quesId]: value,
            }))
            console.log(ratings, "ratingsss");
          };
        

         
        

         const handleSubmit=()=>{
       
 // alert(isAnyRatingNull)
 let nullRating=true;
  
 // Loop through the questions from q1 to q16
 for (let i = 1; i <= questionLength; i++) {
   const question = "q" + i;
   const rating = ratings[question];
   if (rating==undefined){
     nullRating=false;
     break
   }
 }

          const isAnyRatingNull = Object.values(ratings).some((rating) => rating === "");
          // alert(isAnyRatingNull)

          if(employer_name!=""){
            if(company!=""){
              if(CompanyAddress!=""){
                if(student_name!=""){
                  if(programme!=""){
                    if(branch!=""){
                      if(selectedYear1!=""){
                        if(positionentry!=""){
                          if(positioncurrent!=""){
                            if(nullRating){
      
 
          
          fetch('https://feedbackapi.rcktechiees.com/api/OtherFeedback/Employer_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              "name_employer":(employer_name),
              "name_graduate":(student_name),
          "branch":(branch),
          "programe":(programme),
          "company":(company),
          "year": (selectedYear1),
          "address_company":(CompanyAddress),
          "position_entry":(positionentry),
          "position_now":(positioncurrent),
          "responsibilities":(responsibility),
          "achivements":(awards),

          
          ...ratings
          
         

            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data,"responseupload")}
            if(data["Status"]=="1"){
              toast.success("Successfully Submitted")
            }
            else if(data["Status"]=="0"){
              toast.error("Something went wrong")
            }
          
           
          
           
          
          
          })
           .catch(error=>console.log(error))
          }
          else{
            toast.error("Please fill all the ratings")
          }
        }
        else{
          toast.error("Please fill the current position of our student in your company")
        }
        }
        else{
          toast.error("Please fill the position at entry level of our student in your company")
        }

        }
        else{
          toast.error("Please fill the joining year")
        }

        }
        else{
          toast.error("Please choose the branch")
        }
        }  
        else{
          toast.error("Please choose the programme")
        }

        }
        else{
          toast.error("Please fill the name of the student")
        }
        }
        else{
          toast.error("Please fill the company address")
        }

        }
        else{
          toast.error("Please fill the company name")
        }

          }
          else{
            toast.error("Please fill the HR name")
          }


          //  toast.error("Something went wrong")


         
        }
 
         const handlePageChange = (pageNumber) => {
         
          setCurrentPage(pageNumber);
  const indexOfLastQuestion = pageNumber * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
  setCurrentQuestions(currentQuestions_1);
        };
      
      
         const Pagination = ({ currentPage, totalPages, onPageChange }) => {
          const pageNumbers = [];
      
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
      
          return (

         
          
            <Box
           
            display="flex" justifyContent="center" mt="20px"
           
          >
              {pageNumbers.map((pageNumber) => (
                 <Button variant="contained"
                  key={pageNumber}
                  onClick={() => onPageChange(pageNumber)}
                  disabled={currentPage === pageNumber}
                  className={classes.customButton}
                  sx={{  justifyContent: "center"}}
   
                >
                  {pageNumber}
                  </Button>
              ))}
            </Box>
          );
        };
      
      
      
      
      
      
      


  return (
   <>
      <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <div className="app1">
                <Sidebar2 isSidebar={isSidebar} feedback_type={"employer"}/>
                <main className="content1">
                    <Topbar setIsSidebar={setIsSidebar} feedback_type={"Employer Feedback"}/>
 

                    <Box m="20px"
       display="grid"
       gap="7px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
       sx={{
         "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
       }
       }
       >


<TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Name of the HR"
                   value={employer_name}             
                      
                  
                   onChange={(event) => {handleEmployer(event.target.value)}}
                   name="Name of the Graduate"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />


<TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Name of the Company"
                   value={company}             
                   
                  
                   onChange={(event) => {handleCompany(event.target.value)}}
                   name="Name of the 
                   Programme
                   "
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />

<TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Address of the Company"
                   value={CompanyAddress}             
                   
                  
                   onChange={(event) => {handleCompanyAddress(event.target.value)}}
                   name="Name of the 
                   Programme
                   "
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
<TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Name of the 
                   Student"
                   value={student_name}             
                      
                  
                   onChange={(event) => {handleName(event.target.value)}}
                   name="Name of the Graduate"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                 <FormControl variant="filled" sx={{ gridColumn: "span 1"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Programme</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={programme}
          onChange={(e) => {handleProgramme(e.target.value)}}
          sx={{
            height: '42px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
          // value={selectRegulation}
          // onChange={handleRegulation}
        
        >
        
          <MenuItem value={"B.E"}>B.E</MenuItem>
          <MenuItem value={"M.E"}>M.E</MenuItem>
          <MenuItem value={"PhD"}>PhD</MenuItem>
          
         
        </Select>
      </FormControl>
      <FormControl variant="filled" sx={{ gridColumn: "span 1"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={branch}
          onChange={(e) => {handleBranch(e.target.value)}}
          sx={{
            height: '42px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }} 
          // value={selectRegulation}
          // onChange={handleRegulation}
        
        >
        <MenuItem value={"civil"}>Civil</MenuItem>
        <MenuItem value={"mech"}>Mechanical</MenuItem>
          <MenuItem value={"ece"}>ECE</MenuItem>
          <MenuItem value={"eee"}>EEE</MenuItem>
          <MenuItem value={"cse"}>CSE</MenuItem>
          
         
        </Select>
      </FormControl>
      <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Year of joining the company"
                   value={selectedYear1}             
                      
                  
                   onChange={(event) => {handleChangeYear(event.target.value)}}
                   name="Name of the Graduate"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
      {/* <FormControl variant="filled" sx={{ gridColumn: "span 1"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
      <InputLabel id="demo-simple-select-filled-label">Select Year</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={selectedMenu}
        onChange={handleChangeMenu}
        style={{ height: '42px' }} 
        
      >
        {menuOptions.map((option, index) => (
          <MenuItem key={index} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {selectedMenu && (
        <Select
          value={selectedYear1}
          onChange={handleChangeYear}
        
        >
          {menuOptions.find((option) => option.label === selectedMenu).subMenu.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
   */}

              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Position at
                   Entry Level
                   
                   "
                   value={positionentry}             
                  
                  
                   onChange={(event) => {handlePositionEntry(event.target.value)}}
                   name="Name of the 
                  Institution
                   "
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Current Position"
                   
                   
                   value={positioncurrent}             
                     
                  
                   onChange={(event) => {handlePositionCurrent(event.target.value)}}
                   name="Name of the 
                   Programme
                   "
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Responsibilities 
                   held"
                   
                   
                   value={responsibility}             
                     
                  
                   onChange={(event) => {handleResponsibility(event.target.value)}}
                   name="Name of the 
                   Programme
                   "
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Achievements/ 
                   Awards"
                   
                   
                   value={awards}             
                     
                  
                   onChange={(event) => {handleAward(event.target.value)}}
                   name="Name of the 
                   Programme
                   "
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />

























            
                
                 

   
     
    
   
<FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" ,paddingTop:"1%",paddingBottom:"1%"}} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>


      {isNonMobile?
      <div className="stars" style={{display: 'flex', gap: '10px'}}>
      <div className="">
      <h6>5 - Excellent</h6>
      </div>
      <div className="">
      <h6>4 - Satisfied</h6>
      </div>
      <div className="">
      <h6>3 - Good</h6>
      </div>
      <div className="">
      <h6>2 - Not Satisfied</h6>
      </div>
      <div className="">
      <h6>1 - Poor</h6>
      </div>
      </div>:

<div className="stars" style={{display: 'block', gap: '10px'}}>
<div className="">
<h6>5 - Excellent</h6>
</div>
<div className="">
<h6>4 - Satisfied</h6>
</div>
<div className="">
<h6>3 - Good</h6>
</div>
<div className="">
<h6>2 - Not Satisfied</h6>
</div>
<div className="">
<h6>1 - Poor</h6>
</div>
</div>
      
      }
      <h6 style={{fontSize: '18px', fontWeight: '600'}}>Please give your valuable feedback in a scale</h6>
      {/* <h6 style={{fontSize: '15px'}}> &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest</h6> */}
        
      
      </FormControl>

      {currentQuestions.map((item) => {
  const questionParts = item.Question.split("//");
  const firstPart = questionParts.shift(); 
  return (
    <FormControl variant="filled" sx={{ gridColumn: 'span 4' }} key={item.Ques_id}>
    
      <div className="forform" style={{ gap: '15px'}}>
            <div className="col-md-7 col-lg-7" >
              <div className="English-fnt" style={{fontSize: '16px', lineHeight: '2'}}>
            {/* <Typography variant="body1"> */}
        {`${item.Ques_id + 1}. ${firstPart}`}
      {/* </Typography> */}
       </div>
      {questionParts.map((part, index) => (
          // <Typography key={index} variant="body1" style={{ whiteSpace: 'pre-line' }}>
           <div className="Tamil-fnt" style={{fontSize: '15px', lineHeight: '2'}}>
          {part}
          </div>
        //  {/* </Typography> */}
      ))}

</div>
<div className="col">
              <div className="forinsidestar" style={{display: 'flex', gap: '7px'}}>
                <div className="col">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
    
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '1' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '1')}
            >
              <i
                className={`bi bi-1-circle${ratings2[item.Ques_id] === '1' ? ' selected' : ''}`}
                aria-label="1"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '2' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '2')}
            >
              <i
                className={`bi bi-2-circle${ratings2[item.Ques_id] === '2' ? ' selected' : ''}`}
                aria-label="2"
              />
         
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '3' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '3')}
            >
              <i
                className={`bi bi-3-circle${ratings2[item.Ques_id] === '3' ? ' selected' : ''}`}
                aria-label="3"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '4' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '4')}
            >
              <i
                className={`bi bi-4-circle${ratings2[item.Ques_id] === '4' ? ' selected' : ''}`}
                aria-label="4"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '5' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '5')}
            >
              <i
                className={`bi bi-5-circle${ratings2[item.Ques_id] === '5' ? ' selected' : ''}`}
                aria-label="5"
              />
             
             </div>
            </div>
                </div>
                <div className="col">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{fontSize: '18px', color: "red"}}>{ratings2[item.Ques_id] === '1' && <Box>{labels[0]}</Box>}</div>
      <div   style={{fontSize: '18px', color: "#75746f"}}>{ratings2[item.Ques_id] === '2' && <Box>{labels[1]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#0039e6"}}>{ratings2[item.Ques_id] === '3' && <Box>{labels[2]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#e5a004"}}>{ratings2[item.Ques_id] === '4' && <Box>{labels[3]}</Box>}</div>
       <div  style={{fontSize: '18px', color: "#03b634"}}>{ratings2[item.Ques_id] === '5' && <Box>{labels[4]}</Box>}</div>
     
       </div>
                </div>
              
            
            
            </div>
            </div>
          </div>
          
     
    </FormControl>
  );
})}
      
      {currentPage==totalPages && (
      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>

<Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'},}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button}`} 
   onClick={handleSubmit}>
                          Submit
                          </Button> 
                          <ToastContainer />
                      {/* </Box> 
              <ToastContainer /> */}
    </Box>
    </FormControl>
    )} 

<FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>
   {console.log(currentPage,"cureent",totalPages)}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
     </FormControl>
     
             
    </Box>   
    </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
 
  
   </>
  );
}
export default Employer;
