
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Sidebar2 from "../global/Sidebar2";
import Topbar_Faculty from "../global/Topbar_faculty";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Form from "react-bootstrap/Form";
import YearPicker from "../Student_Exit.jsx/yearPicker"
import { TextareaAutosize } from '@mui/material';


import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "../student-login-page/feedback.css"
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';







import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import Rating, { IconContainerProps } from '@mui/material/Rating';

import { makeStyles } from '@material-ui/core/styles';
import { SettingsInputHdmiOutlined } from "@mui/icons-material";

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));


  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  const useStyles = makeStyles((theme) => ({
    inputLabel: {
      whiteSpace: "nowrap",
      [theme.breakpoints.down("912")]: {
        whiteSpace: "pre-line",
      },},
    inputLabel: {
      fontSize: "11px", // Adjust the font size here
    },
     textFieldInput: {
    fontSize: "2px", // Adjust the font size here
  },
    customButton: {
        borderRadius: '50%',
      },
    root: {
      width: '60%',
      padding: '2%',
      fontSize: '12px',
      fontFamily: 'Arial',
      borderRadius: '2%',
      border: '1px solid #ccc',
      resize: 'both',
      overflow: 'auto',
      '&:focus': {
        outline: 'none',
      },
    },
    darkTheme: {
        backgroundColor: '#293040',
      color: '#ffffff',
    },
    lightTheme: {
      backgroundColor: '#f2f2f2',
      color: '#000',
    },
  }));
  
  

  
 

  

 

// --------------------MONTHLY VIEW TABLE--------------------------//
const Faculty_Feedback_Final = () => {


 const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [themes, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
     
     const isNonMobile = useMediaQuery("(min-width:600px)");
    
    

    


     const [selectedMenu, setSelectedMenu] = useState('');
     const [selectedYear1, setSelectedYear1] = useState('');
   
     const handleChangeMenu = (event) => {
       setSelectedMenu(event.target.value);
       setSelectedYear1('');
     };
   
     const handleChangeYear = (event) => {
       setSelectedYear1(event.target.value);
     };
   
     
     const menuOptions = [
      { label: '2000-2010', subMenu: Array.from({ length: 11 }, (_, index) => 2000 + index) },
      { label: '2011-2020', subMenu: Array.from({ length: 10 }, (_, index) => 2011 + index) },
      { label: '2021-2030', subMenu: Array.from({ length: 10 }, (_, index) => 2021 + index) },
      { label: '2031-2040', subMenu: Array.from({ length: 10 }, (_, index) => 2031 + index) },
      { label: '2041-2050', subMenu: Array.from({ length: 10 }, (_, index) => 2041 + index) },
      { label: '2051-2060', subMenu: Array.from({ length: 10 }, (_, index) => 2051 + index) },
      { label: '2061-2070', subMenu: Array.from({ length: 10 }, (_, index) => 2061 + index) },
      { label: '2071-2080', subMenu: Array.from({ length: 10 }, (_, index) => 2071 + index) },
      { label: '2081-2090', subMenu: Array.from({ length: 10 }, (_, index) => 2081 + index) },
      { label: '2091-3000', subMenu: Array.from({ length: 10 }, (_, index) => 2091 + index) },

      // Add more menu options as needed
    ];
  
   
     // Generate a list of years
     const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() + index);
    
    
     const classes = useStyles();


     


     let user_details = localStorage.getItem("faculty_details");
    
     if (user_details) {
      user_details = JSON.parse(user_details);
      console.log(user_details.Email);
    } else {
      console.log("user_details not found in local storage");
    }
    // const [selectedYear1, setSelectedYear1] = useState(null);

    // const handleYearChange = (date) => {
    //   setSelectedYear1(date.getFullYear());
    // };

   
    const [graduate,setGraduate]=useState("");
    const [branch,setBranch]=useState("");
  const [ratings, setRatings] = useState({});
  const [ratings2, setRatings2] = useState({});
  const [father_name,setFatherName]=useState("");
  const [course_branch,setCourseBranch]=useState("")
  const [adm_no,setAdmNo]=useState("")
  const [CollegeName,setCollegeName]=useState("");
  const [suggestions,setSuggestions]=useState("");
  
const [designation,setDesignation]=useState("")
const [hod,SetHOD]=useState("")
const [separation,setSeparation]=useState("")
const [last_day,setLastDay]=useState("")
const [decision,setDecision]=useState("")
const [management,setManagement]=useState("")
const[performance,setPerformance]=useState("")
const [reason,setReason]=useState("")
const [motivate,setMotivate]=useState("")
const [position,setPosition]=useState("")
const [adequate,setAdequate]=useState("")

 
  
  const handleDesignation = (event) => {
           setDesignation(event)
         
        
  };
const [hoddept,setHODDEPT]=useState('')
  const handlehodDept=(event)=>{
    setHODDEPT(event)

  }
  const[enhanced,setEnhamnced]=useState("")
  const handleEnhanced=(event)=>{
    setEnhamnced(event)
  }
  const[most,setMost]=useState("")

  const handleMost=(event)=>{
    setMost(event)
  }
  const [least,setLeast]=useState("")
  const handleLeast=(event)=>{
    setLeast(event)
  }
  const [recomment,setRecommend]=useState("")
  const handleRecommend=(event)=>{
    setRecommend(event)
  }
 
const [admission,setAdmission]=useState("")
const handleAdmission=(event)=>{
    setAdmission(event)
  }
  const[lastrating,setLastRating]=useState("")
  const   handleRatingChange=(event,value)=>{
    setLastRating(value)
  }

  const [short,setShort]=useState("")
const handleShort=(event)=>{
    setShort(event)
  }
  
  const handleSeparation = (event) => {
           
       setSeparation(event)
        
  };
  const handleHOD = (event) => {
           
    SetHOD(event)
        
  };
  const handleLastDay = (event) => {
           
         setLastDay(event)
        
  };

          const handleAdequate = (event) => {
           
            setAdequate(event)
        
          };
          const handlePerformance = (event) => {
           
            setPerformance(event)
        
          };
          const handlePosition = (event) => {
            setPosition(event)
         
        
          };
          const handleMotivate = (event) => {
           
            setMotivate(event)
        
          };
          const handleReason = (event) => {
            setReason(event)
         
        
          };
          const handleDecision = (event) => {
           
            setDecision(event)
        
          };
          const handleManagement = (event) => {
           
            setManagement(event)
        
          };
          const handleName = (event) => {
           
         
        
          };
          const handleSuggestion = (event) => { 
         
            setSuggestions(event);
          };
          const handleCollegeName = (event) => { 
         
            setCollegeName(event);
          };
          const handleCourseBranch = (event) => { 
         
            setCourseBranch(event);
          };
          const handleAdmNo = (event) => { 
         
            setAdmNo(event);
          };
          
        
          const handleGraduate=(event)=>{
            setGraduate(event)
          }
          const handlebranch=(event)=>{
            setBranch(event)
          }

          

        //   const handleRatingChange = (quesId, value) => {
           
            
        //     const questionKey = `q${quesId + 1}`;
        //     setRatings((prevRatings) => ({
        //       ...prevRatings,
        //       [questionKey]: value,
        //     }));

        //     setRatings2((prevRatings2)=>({
        //       ...prevRatings2,
        //       [quesId]: value,
        //     }))
        //     console.log(ratings, "ratingsss");
        //   };
        

         
        

         const handleSubmit=()=>{
       


          const isAnyRatingNull = Object.values(ratings).some((rating) => rating === "");
          // alert(isAnyRatingNull)

          
      
 if(branch!=""){
  if(designation!=""){
    if(reason!=""){
    if(lastrating!=""){
          
          fetch('https://feedbackapi.rcktechiees.com/api/Faculty/Upload_date', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              "name":(user_details.Faculty_Name),
             
          "department":(branch),
          "designation":(designation),
          
          "last_work":(last_day),
          "separation":(separation),
          "decision":(decision),
          "main_reason":(reason),
          "q1":(motivate),
          "q2":(position),
          "q3":(adequate),
          "q4":(management),
          "q5":(performance),
          "q6":(hoddept),
          "q7":(enhanced),
          "q8":(most),
          "q9":(least),
          "q10":(recomment),
          "q11":(admission),
          "q12":(short),
          "q13":(lastrating),

          
         
          
         

            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data,"responseupload")}
            if(data["Status"]=="1"){
              toast.success("Successfully Submitted")
            }
            else if(data["Status"]=="0"){
              toast.error("Something went wrong")
            }
          
           
          
           
          
          
          })
           .catch(error=>console.log(error))
        }
        else{
          toast.error("Please fill the rating")
        }
      }
      else{
        toast.error("Please fill the reason to leave the organisation")
      }
        }
        else{
          toast.error("Please fill your designation")
        }
 }
 else{
  toast.error("Please choose your branch")
 }


          //  toast.error("Something went wrong")


         
        }
 
        
      
      
       
          
      
      
      
      
      


  return (
   <>
      <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <div className="app1">
                <Sidebar2 isSidebar={isSidebar} feedback_type={"faculty_login"}/>
                <main className="content1">
                    <Topbar_Faculty setIsSidebar={setIsSidebar} feedback_type={"Faculty Feedback"}/>
 

                    <Box m="20px"
       display="grid"
       gap="7px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
       sx={{
         "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
       }
       }
       >
           <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Name"
                   value={user_details.Faculty_Name}             
                   disabled      
                  
                   onChange={(event) => {handleName(event.target.value)}}
                   name=""
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
    
             
             <FormControl variant="filled" sx={{ gridColumn: "span 1"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={branch}
          onChange={(e) => {handlebranch(e.target.value)}}
          sx={{
            height: '42px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          <MenuItem value={"Civil"}>Civil</MenuItem>
          <MenuItem value={"Mechanical"}>Mechanical</MenuItem>
          <MenuItem value={"ECE"}>ECE</MenuItem>
          <MenuItem value={"EEE"}>EEE</MenuItem>
          <MenuItem value={"CSE"}>CSE</MenuItem>
         
        </Select>
      </FormControl>
      <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Designation"
                   value={designation}             
                   
                  
                   onChange={(event) => {handleDesignation(event.target.value)}}
                   name="Designation"
                   sx={{ gridColumn: "span 1",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
  {/* <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="HOD’s Name"
                   value={hod}             
                   
                  
                   onChange={(event) => {handleHOD(event.target.value)}}
                   name="hod"
                   sx={{ gridColumn: "span 1" }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              /> */}

<TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                  //  label="In reflecting on the above, \nwhich was your main reason for leaving ACGCET? "
                    
                  value={last_day}             
                   
                  
                             
                  label={
                   isNonMobile?
                   "Last year you were present in this institution. " 
                  :
                 
                   <div className={classes.inputLabel}>
                  Last year you were present 
                   <br />
                   in this institution. 
                 </div>
                 }
                 
                 onChange={(event) => {handleLastDay(event.target.value)}} 
                   name="hod"
                   sx={{ gridColumn: "span 2" ,
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                }}
                   inputProps={{
                    classes: {
                      input: classes.textFieldInput,
                    },
                    style: {
                      height: "30px",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                    },
                  }}
              />
           
        

     
<FormControl variant="filled" sx={{ gridColumn: "span 2"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Type of Separation</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={separation}
          onChange={(e) => {handleSeparation(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          <MenuItem value={"Resignation"}>Resignation</MenuItem>
          <MenuItem value={"Involuntary"}>Involuntary
</MenuItem>
          <MenuItem value={"Retirement"}>Retirement</MenuItem>
          <MenuItem value={"Transfer"}>Transfer</MenuItem>
       
         
        </Select>
      </FormControl>
                
                 

  
    
      <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                  //  label="In reflecting on the above, \nwhich was your main reason for leaving ACGCET? "
                   label={
                    isNonMobile?
                    "Enter your main reason for leaving ACGCET? " 
                   :
                  
                    <div className={classes.inputLabel}>
                   Enter your main reason
                    <br />
                    for leaving ACGCET?
                  </div>
                  }
                   value={reason}             
                   
                  
                   onChange={(event) => {handleReason(event.target.value)}}
                   name="hod"
                   sx={{ gridColumn: "span 2" ,
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                }}
                   inputProps={{
                    classes: {
                      input: classes.textFieldInput,
                    },
                    style: {
                      height: "30px",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                    },
                  }}
              />
              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label={
                    isNonMobile?
                    "What motivated you to begin a job search outside ACGCET? " 
                   :
                  
                    <div className={classes.inputLabel}>
                   What motivated you to begin a job
                    <br />
                    search outside ACGCET?
                  </div>
                  }
                  //  label="What motivated you to begin a job search outside ACGCET?"
                   value={motivate}             
                   
                  
                   onChange={(event) => {handleMotivate(event.target.value)}}
                   name="hod"
                   sx={{ gridColumn: "span 2" ,
                   
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  //  ,"& label": {
                  //   fontSize: "8px", 
                  // },
                }}
                   inputProps={{
                    style: {
                      height: "30px",
                    },
                  }}
              />


<FormControl variant="filled" sx={{ gridColumn: "span 2"  ,

                   }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "Was your position at ACGCET what you expected to be? Were your expectations realized?"
        ) : (
          <>
           Whether your expectations 
            <br />
            met at ACGCET?
          </>
        )}
          
        </InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={position}
          onChange={(e) => {handlePosition(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
          
         
        </Select>
      </FormControl>
      <FormControl variant="filled" sx={{ gridColumn: "span 2" 
                
                   }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
                      <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "Were you given adequate Training/ Instruction to succeed in your job?"
        ) : (
          <>
         Were you given adequate Training/
            <br />
            Instruction to succeed in your job?
          </>
        )}
          
        </InputLabel>
    
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={ adequate}
          onChange={(e) => {handleAdequate(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
        <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
          
         
        </Select>
      </FormControl>
      <FormControl variant="filled" sx={{ gridColumn: "span 2"  ,"& label": {
                    fontSize: "14px", // Adjust the font size here
                  }, }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
                     <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "Whether department management had realistic expectations of your work performance?"
        ) : (
          <>
       Whether department management had 
            <br />
            realistic expectations of your work performance?
          </>
        )}
          
        </InputLabel>
        <InputLabel id="demo-simple-select-filled-label"></InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={management}
          onChange={(e) => {handleManagement(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          
        <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
         
        </Select>
      </FormControl>
      <FormControl variant="filled" sx={{ gridColumn: "span 2" ,
                  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
                    <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "Were your performance reviews done in a timely and fair manner?"
        ) : (
          <>
    Were your performance reviews done 
            <br />
            in a timely and fair manner?
          </>
        )}
          
        </InputLabel>
      
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={performance}
          onChange={(e) => {handlePerformance(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
        <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
          
         
        </Select>
      </FormControl>


      
      <FormControl variant="filled" sx={{ gridColumn: "span 2"  
                  
                   }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
                     <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "Was your decision to leave ACGCET influenced by any of the following"
        ) : (
          <>
    Was your decision to leave ACGCET 
            <br />
            influenced by any of the following
          </>
        )}
          
        </InputLabel>
        
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={decision}
          onChange={(e) => {handleDecision(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          <MenuItem value={"Relocation out of area "}>Relocation out of area </MenuItem>
          <MenuItem value={"Salary"}>Salary
</MenuItem>
          <MenuItem value={"Health Reasons"}>Health Reasons</MenuItem>
          <MenuItem value={"Incentives & other Benefits"}>Incentives & other Benefits</MenuItem>
       
          <MenuItem value={"No Medical/ Earned leave"}>No Medical/ Earned leave
</MenuItem>
<MenuItem value={"Feedback on your Performance"}>Feedback on your Performance
</MenuItem>
<MenuItem value={"Family Circumstances"}>Family Circumstances
</MenuItem>
<MenuItem value={"Dissatisfaction with posItion"}>Dissatisfaction with posItion
</MenuItem>
<MenuItem value={"Feel in-secured job"}>Feel in-secured job
</MenuItem>
<MenuItem value={"Dissatisfaction with HOD"}>Dissatisfaction with HOD
</MenuItem>
<MenuItem value={"Better Carrer Oppprtunity"}>Better Carrer Oppprtunity
</MenuItem>
<MenuItem value={"Dissatisfaction with College’s Approach"}>Dissatisfaction with College’s Approach
</MenuItem>
<MenuItem value={"Others"}>Others</MenuItem>
         
        </Select>
      </FormControl>



      
      {/* <FormControl variant="filled" sx={{ gridColumn: "span 2"  
                  
                 }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
                      <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "My head of the Department "
        ) : (
          <>
 My HOD
          </>
        )}
          
        </InputLabel>
       
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={hoddept}
          onChange={(e) => {handlehodDept(e.target.value)}}
          style={{ height: '65px' }} 
         
        
        >
        
          <MenuItem value={"Follows Policies and Procedure  "}>Follows Policies and Procedure  </MenuItem>
          <MenuItem value={"Promotes Teamwork"}>Promotes Teamwork</MenuItem>
          <MenuItem value={"Demonstrates Fair and Equal Treatment"}>Demonstrates Fair and Equal Treatment</MenuItem>
          <MenuItem value={"Demonstrates Fair and Equal Treatment"}>Demonstrates Fair and Equal Treatment</MenuItem>
          <MenuItem value={"Demonstrates Fair and Equal Treatment"}>Demonstrates Fair and Equal Treatment</MenuItem>
          <MenuItem value={"Act as my Role Model "}>Act as my Role Model </MenuItem>

         
        </Select>
      </FormControl> */}
   

     
      <FormControl variant="filled" sx={{ gridColumn: "span 2" 
               
                  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
      
        <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "My experience at ACGCET was enhanced by "
        ) : (
          <>
    My experience at ACGCET
            <br />
            was enhanced by
          </>
        )}
          
        </InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={enhanced}
          onChange={(e) => {handleEnhanced(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
        <MenuItem value={"Opportunity for Advancement"}>Opportunity for Advancement</MenuItem>
        <MenuItem value={"Healthy Environment"}>Healthy Environment</MenuItem>
        <MenuItem value={"Competitive Salary"}>Competitive Salary</MenuItem>
        <MenuItem value={"Good Physical Working Conditions"}>Good Physical Working Conditions</MenuItem>
        <MenuItem value={"Good Benefits Package"}>Good Benefits Package</MenuItem>
        </Select>
      </FormControl>
   
      <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                 
                   label={
                    isNonMobile?
                    "What did you like most about working at ACGCET? " 
                   :
                  
                    <div className={classes.inputLabel}>
                 What did you like most
                    <br />
                    about working at ACGCET?
                  </div>
                  }
                   value={most}             
                   
                  
                   onChange={(event) => {handleMost(event.target.value)}}
                   name="hod"
                   sx={{ gridColumn: "span 2" ,"& label": {
                    
                  },
                
                  "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                }}
                   inputProps={{
                    style: {
                      height: "30px",
                    },
                  }}
              />

<TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                 
                   value={least}             
                   label={
                    isNonMobile?
                    "What did you like least about working at ACGCET? " 
                   :
                  
                    <div className={classes.inputLabel}>
                 What did you like least
                    <br />
                    about working at ACGCET?
                  </div>
                  }
                  
                   onChange={(event) => {handleLeast(event.target.value)}}
                   name="hod"
                   sx={{ gridColumn: "span 2" ,"& label": {
                 
                  },
                
                  "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                }}
                   inputProps={{
                    style: {
                      height: "30px",
                    },
                  }}
              />
                 {/* <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                  
                   value={least}             
                   label={
                    isNonMobile?
                    "What did you like least about working at ACGCET? " 
                   :
                  
                    <div className={classes.inputLabel}>
                 What did you like least
                    <br />
                    about working at ACGCET?
                  </div>
                  }
                  
                   onChange={(event) => {handleLeast(event.target.value)}}
                   name="hod"
                   sx={{ gridColumn: "span 2"  
                  
                  ,}}
                   inputProps={{
                    style: {
                      height: "30px",
                    },
                  }}
              /> */}

<FormControl variant="filled" sx={{ gridColumn: "span 2" ,

"& .MuiInputBase-root.MuiFilledInput-root": {

                        border: "1px solid grey",
  
                        borderRadius: "3px",
  
                        backgroundColor: "transparent",
  
                      },
  
                      "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
  
                        backgroundColor: "transparent",
  
                        borderColor: "#01134d",
  
                      },
  
                      "& .MuiInputBase-root.MuiFilledInput-root:hover": {
  
                        backgroundColor: "transparent",
  
                        borderColor: "#01134d",
  
                      },
  
                      "& .MuiInputBase-root.MuiFilledInput-root:before": {
  
                        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
  
                      },
  
                      "& .MuiInputBase-root.MuiFilledInput-root:after": {
  
                        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
  
                      },
                 
                   }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
                     <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "Would you recommend ACGCET to a friend as a place to work?"
        ) : (
          <>
   Would you recommend ACGCET to a
            <br />
            friend as a place to work?
          </>
        )}
          
        </InputLabel>
      
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={recomment}
          onChange={(e) => {handleRecommend(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
         
        
        >
        
          <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
          
         
        </Select>
      </FormControl>
      
<FormControl variant="filled" sx={{ gridColumn: "span 2" 
                  
                   }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
                              <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "Would you desire to get admission for your son/ daughter in ACGCET?"
        ) : (
          <>
   Would you desire to get admission 
            <br />
            for your son/ daughter in ACGCET?
          </>
        )}
          
        </InputLabel>
        
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={admission}
          onChange={(e) => {handleAdmission(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        
        >
        
          <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
          
         
        </Select>
      </FormControl>
      
<FormControl variant="filled" sx={{ gridColumn: "span 2" 
                 
                  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
                                      <InputLabel id="demo-simple-select-filled-label" className={isNonMobile ? "" : classes.inputLabel}>
        {isNonMobile ? (
          "Would you be interested for short term temporary positions at ACGCET?"
        ) : (
          <>
   Would you be interested for short term
            <br />
            temporary positions at ACGCET?
          </>
        )}
          
        </InputLabel>
       
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={short}
          onChange={(e) => {handleShort(e.target.value)}}
          sx={{
            height: '65px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        
        >
        
          <MenuItem value={"yes"}>Yes</MenuItem>
          <MenuItem value={"no"}>No</MenuItem>
          
         
        </Select>
      </FormControl>
        
<FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" ,paddingTop:"1%",paddingBottom:"1%"}} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>


      {isNonMobile?
      <div className="stars" style={{display: 'flex', gap: '10px'}}>
      <div className="">
      <h6>5 - Excellent</h6>
      </div>
      <div className="">
      <h6>4 - Satisfied</h6>
      </div>
      <div className="">
      <h6>3 - Good</h6>
      </div>
      <div className="">
      <h6>2 - Not Satisfied</h6>
      </div>
      <div className="">
      <h6>1 - Poor</h6>
      </div>
      </div>:

<div className="stars" style={{display: 'block', gap: '10px'}}>
<div className="">
<h6>5 - Excellent</h6>
</div>
<div className="">
<h6>4 - Satisfied</h6>
</div>
<div className="">
<h6>3 - Good</h6>
</div>
<div className="">
<h6>2 - Not Satisfied</h6>
</div>
<div className="">
<h6>1 - Poor</h6>
</div>
</div>
      
      }
      <h6 style={{fontSize: '18px', fontWeight: '600'}}>Please give your valuable feedback in a scale</h6>
      {/* <h6 style={{fontSize: '15px'}}> &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest &nbsp;&nbsp;&nbsp; 1 - Lowest</h6> */}
        
      
      </FormControl>
      <FormControl variant="filled" sx={{ gridColumn: 'span 4' }} >
      <div className="forform" style={{ gap: '15px'}}>
            <div className="col-md-7 col-lg-7" >
              <div className="English-fnt" style={{fontSize: '16px', lineHeight: '2'}}>
       1. . In overall- sense, how do you rate ACGCET by your working experience?  
       </div>
       <div className="Tamil-fnt" style={{fontSize: '15px', lineHeight: '2'}}>
       (ஒட்டுமொத்த அர்த்தத்தில்,உங்கள் பணி அனுபவத்தின் மூலம் ACGCET-ஐ எப்படி மதிப்பிடுகிறீர்கள்?)
       </div>
       </div>
       <div className="col">
              <div className="forinsidestar" style={{display: 'flex', gap: '7px'}}>
                <div className="col">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
    
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: lastrating === '1' ? 'green':"",
              }}
              onClick={(event) => handleRatingChange(event, '1')}
            >
              <i
                className={`bi bi-1-circle${lastrating === '1' ? ' selected' : ''}`}
                aria-label="1"
              />
            
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: lastrating === '2' ? 'green':"",
               
              }}
              onClick={(event) => handleRatingChange(event, '2')}
            >
             <i
                className={`bi bi-2-circle${lastrating === '2' ? ' selected' : ''}`}
                aria-label="1"
              />
         
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: lastrating === '3' ? 'green':"",
              }}
              onClick={(event) => handleRatingChange(event, '3')}
            >
            <i
                className={`bi bi-3-circle${lastrating === '3' ? ' selected' : ''}`}
                aria-label="1"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color:lastrating === '4' ? 'green':"",
               
              }}
              onClick={(event) => handleRatingChange(event, '4')}
            >
             <i
                className={`bi bi-4-circle${lastrating === '4' ? ' selected' : ''}`}
                aria-label="1"
              />
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '20px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: lastrating === '5' ? 'green':"",
              }}
              onClick={(event) => handleRatingChange(event, '5')}
            >
            <i
                className={`bi bi-5-circle${lastrating === '5' ? ' selected' : ''}`}
                aria-label="1"
              />
            </div>
            </div>
                </div>
                <div className="col">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{fontSize: '18px', color: "red"}}>{lastrating  === '1' && <Box>{labels[0]}</Box>}</div>
      <div  style={{fontSize: '18px', color: "#75746f"}}>{lastrating  === '2' && <Box>{labels[1]}</Box>}</div>
       <div  style={{fontSize: '18px', color: "#0039e6"}}>{lastrating  === '3' && <Box>{labels[2]}</Box>}</div>
       <div   style={{fontSize: '18px', color: "#e5a004"}}>{lastrating  === '4' && <Box>{labels[3]}</Box>}</div>
       <div  style={{fontSize: '18px', color: "#03b634"}}>{lastrating  === '5' && <Box>{labels[4]}</Box>}</div>
    
       </div>
                </div>
              
            
            
            </div>
            </div>
          </div>
        </FormControl>
        <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>

<Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'},}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button}`} 
   onClick={handleSubmit}>
                          Submit
                          </Button> 
                          <ToastContainer />
                      {/* </Box> 
              <ToastContainer /> */}
    </Box>
    </FormControl>

     
             
    </Box>   
    </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
 
  
   </>
  );
}
export default Faculty_Feedback_Final;
