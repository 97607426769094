


import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Sidebar2 from "../global/Sidebar2";
import TopbarAdmin from "../global/Topbar-admin";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Form from "react-bootstrap/Form";
import YearPicker from "../Student_Exit.jsx/yearPicker"
import { TextareaAutosize } from '@mui/material';




import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "../student-login-page/feedback.css"
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';


import { Navigate,useNavigate } from "react-router-dom";




import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import Rating, { IconContainerProps } from '@mui/material/Rating';

import { makeStyles } from '@material-ui/core/styles';

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));


  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }
  const useStyles = makeStyles(() => ({
    customButton: {
        borderRadius: '50%',
      },
    root: {
      width: '60%',
      padding: '2%',
      fontSize: '12px',
      fontFamily: 'Arial',
      borderRadius: '2%',
      border: '1px solid #ccc',
      resize: 'both',
      overflow: 'auto',
      '&:focus': {
        outline: 'none',
      },
    },
    darkTheme: {
        backgroundColor: '#293040',
      color: '#ffffff',
    },
    lightTheme: {
      backgroundColor: '#f2f2f2',
      color: '#000',
    },
  }));
  
  

  
 

  

 

// --------------------MONTHLY VIEW TABLE--------------------------//
const Excel = () => {

    const navigate = useNavigate();
    let user_details = localStorage.getItem("employee_details");
    if (user_details) {
      user_details = JSON.parse(user_details);
      
    }
  
    useEffect(() => {
     
      if (user_details) {
       
     
  
        
        
    
       
      } else {
      
        navigate("/login/admin")
      
        console.log("user_details not found in local storage");
      }
    
    }, [])
    const [themes, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    
    const [facultyID,setFacultyID]=useState("")
    const [FacultyDetails,setFacultyDetails]=useState([])
    const[FacultyName,setFacultyName]=useState([]);
    const [name_faculty,setNameFaculty]=useState("")
    const [id,setID]=useState("")
    const [branch,setBranch]=useState("")
    const [designation,setDesignation]=useState("")
    const [isLoading,setIsLoading]=useState(true);
    const [faculty,setFaculty]=useState("");
    const [fileurl,setFileUrl]=useState("");
    const [show,setShow]=useState(true)
    const [download,setDownload]=useState(false)
           
         
           const handleName = (e) => {
               setNameFaculty(e)
               
             };
             
           const handleDesignation = (e) => {
               setDesignation(e)
               
             };
          
             const handleID = (e) => {
            setID(e)
               
             };
             
           const handlebranch = (e) => {
            setBranch(e)
              
             };
             const [selectedSubject,setSelectedSubject]=useState("")
             const handleSubject=(event)=>{
               setSelectedSubject(event)
             }
             
   
    useEffect(()=>{
         setIsLoading(true)
        setFacultyID("")
        // setRatings([])
        // setRatings2([])
        
if(user_details){
  {console.log(user_details.Department,"detauks")}

        fetch('https://feedbackapi.rcktechiees.com/api/Admin/GetFacultyDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          
          "department": (user_details.Department),
         
        })
      })
      .then(response => response.json())
      
      .then(data => {
      
        console.log(data,"get data");
   console.log(typeof(data),"resposne1111111111")

     
   let facultyDetails_api=data.Faculty_details
        setFacultyDetails(facultyDetails_api)



{console.log("Faculty Name",FacultyDetails)}
        const facultyNames_api = facultyDetails_api.map((faculty) => faculty.Faculty_name);

        setFacultyName(facultyNames_api)
        {console.log("Faculty Name",FacultyName)}
       
     
             
             
             
       
      setIsLoading(false)
      
     
      })
       .catch(error=>console.log(error))}
      },[]);
    
        


        //   const isAnyRatingNull = Object.values(ratings).some((rating) => rating === "");
        

          
      
 
         
         const handleSubmit=()=>{
       

         
          fetch('https://feedbackapi.rcktechiees.com/api/Admin/Student_rating', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              
         "sub_name":(selectedSubject),
         
          
          
         
          
         

            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data,"rsub2s")}

            setFileUrl(data.file)

            setShow(false)
            setDownload(true)
           
           
          
           
          
          
          })
           .catch(error=>console.log(error))
        


       


         
        }
 
        


       


         const handleSubmitDownload=(event)=>{
          setShow(true)
          setDownload(false)

         }
      
 
        
      
      
       const [Subject_List,setSubjectList]=useState([]);
          
      
        const handleFaculty=(e)=>{
            setFaculty(e)
     let faculttty="";
     
     
            for(let i=0;i<FacultyDetails.length;i++){
             if(FacultyDetails[i].Faculty_name==e){
               setFacultyID(FacultyDetails[i].Faculty_id)
               faculttty=FacultyDetails[i].Faculty_id
             }
            }
            fetch('https://feedbackapi.rcktechiees.com/api/Admin/Subject_list', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
              },
              body: JSON.stringify({
                
           "emp_id":(faculttty),
           
            
            
           
            
           
  
              })
            })
            .then(response => response.json())
            
            .then(data => {
            
              {console.log(data.Subject_list,"sub")}
              let sub_listapi=data.Subject_list;

             
              const subject_list_api = sub_listapi.map((subject) => subject.subject_name);

              setSubjectList(subject_list_api )
              if(subject_list_api.length<1){
                toast.error("No subjects handled by this faculty")
              
              }
             
            
             
            
             
            
            
            })
             .catch(error=>console.log(error))
          
    
            
       
              }
         
     
      


  return (
   <>
  
   {FacultyName!=="" && FacultyName!==[] && user_details?
     <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <div className="app1">
                {/* <Sidebar isSidebar={isSidebar} feedback_type={"suggestion"}/> */}
                <main className="content1">
                    <TopbarAdmin setIsSidebar={setIsSidebar} feedback_type={"Student Excel Generate"}/>
 
<div className="container container1">
                    <Box m="20px"
       display="grid"
       gap="14px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      //  sx={{
      //    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
      //  }
      //  }
       >
          

<FormControl variant="filled" sx={{ gridColumn: "span 4"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Name of the Faculty</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
         
          onChange={(e) => {handleFaculty(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
            height: '60px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
{console.log(FacultyName,"hellofaculty")}
        {FacultyName.map((item) => (
      <MenuItem key={item} value={ item} >
        {item}
      </MenuItem>
   ))}
      
      <ToastContainer />
        </Select>
      </FormControl>
    <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Faculty ID"
                   value={facultyID}
                   
disabled
                   onChange={(e) => {handleID(e.target.value)}}
                   name="id"
                   sx={{ gridColumn: "span 4",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "25px",
                    },
                  }}
              />
             
             <FormControl variant="filled" sx={{ gridColumn: "span 4"  }} inputProps={{
                    style: {
                      height: "25px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={branch}
          onChange={(e) => {handlebranch(e.target.value)}}
          sx={{
            height: '60px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        
        >
        
          <MenuItem value={"Civil"}>Civil</MenuItem>
          <MenuItem value={"Mechanical"}>Mechanical</MenuItem>
          <MenuItem value={"ECE"}>ECE</MenuItem>
          <MenuItem value={"EEE"}>EEE</MenuItem>
          <MenuItem value={"CSE"}>CSE</MenuItem>
         
        </Select>
      </FormControl>
     
      
      <FormControl variant="filled" sx={{ gridColumn: "span 4"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Subject List</InputLabel>
        {Subject_List!=undefined?
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
         
          onChange={(e) => {handleSubject(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
            height: '60px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
{console.log(Subject_List)}
        {Subject_List.map((item) => (
      <MenuItem key={item} value={ item} >
        {item}
      </MenuItem>

   ))}
      
         
        </Select>:null}
      </FormControl>         
                 

    
   {show?
     
      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>

      <Box display="flex" justifyContent="center" mt="20px">
      <Button variant="primary" onClick={handleSubmit} >
        Generate Excel
        </Button>
              {/* <Button type="button" color="secondary" variant="contained" onClick={handleSubmit}>
          
              </Button> */}
              <ToastContainer />
    </Box>
    </FormControl>:null}

    {download?
    <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>

      <Box display="flex" justifyContent="center" mt="20px">

     
          <Button variant="success" onClick={handleSubmitDownload} >  <a href={fileurl} download style={{color: 'white'}}>
        Download Excel
        </a></Button>
        
              {/* <Button type="button" color="secondary" variant="contained" onClick={handleSubmitDownload}>

              <a href={fileurl} download>
        Download Excel
        </a>
         
              </Button> */}
              <ToastContainer />
    </Box>
    </FormControl>:null}
  


     
             
    </Box>   </div>
    </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>:<Spinner/>}
    
   </>
  );
}
export default Excel;
