
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,Select,MenuItem,FormControl,InputLabel,TextField,Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from "react";
import Sidebar2 from "../global/Sidebar2";
import Topbar from "../global/Topbar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Form from "react-bootstrap/Form";
import YearPicker from "../Student_Exit.jsx/yearPicker"

import { Spinner } from "../../Spinner/Spinner";
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import "../student-login-page/feedback.css"
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';







import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";

import Rating, { IconContainerProps } from '@mui/material/Rating';

import { makeStyles } from '@material-ui/core/styles';

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
  }));


  const labels = ['Poor', 'Not Satisfied', 'Good', 'Satisfied','Excellent'];
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  
  const useStyles = makeStyles({
    customButton: {
      borderRadius: '50%',
    },
  });
  
 

  const Questionset_api=[
    {Ques_id:0,Question:"Consultancy Fee"},
    {Ques_id:1,Question:"Meeting Deadline "},
    {Ques_id:2,Question:"Work Quality"},
    {Ques_id:3,Question:"Approach "},
   
  ]

 

// --------------------MONTHLY VIEW TABLE--------------------------//
const Consultancy = () => {


 const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [themes, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
     
     const isNonMobile = useMediaQuery("(min-width:600px)");
    
     let user_details = localStorage.getItem("user_details");
    

    


     const [selectedMenu, setSelectedMenu] = useState('');
     const [selectedYear1, setSelectedYear1] = useState('');
   
     const handleChangeMenu = (event) => {
       setSelectedMenu(event.target.value);
       setSelectedYear1('');
     };
   
     const handleChangeYear = (event) => {
       setSelectedYear1(event.target.value);
     };
   
     
     const menuOptions = [
      { label: '2000-2010', subMenu: Array.from({ length: 11 }, (_, index) => 2000 + index) },
      { label: '2011-2020', subMenu: Array.from({ length: 10 }, (_, index) => 2011 + index) },
      { label: '2021-2030', subMenu: Array.from({ length: 10 }, (_, index) => 2021 + index) },
      { label: '2031-2040', subMenu: Array.from({ length: 10 }, (_, index) => 2031 + index) },
      { label: '2041-2050', subMenu: Array.from({ length: 10 }, (_, index) => 2041 + index) },
      { label: '2051-2060', subMenu: Array.from({ length: 10 }, (_, index) => 2051 + index) },
      { label: '2061-2070', subMenu: Array.from({ length: 10 }, (_, index) => 2061 + index) },
      { label: '2071-2080', subMenu: Array.from({ length: 10 }, (_, index) => 2071 + index) },
      { label: '2081-2090', subMenu: Array.from({ length: 10 }, (_, index) => 2081 + index) },
      { label: '2091-3000', subMenu: Array.from({ length: 10 }, (_, index) => 2091 + index) },

      // Add more menu options as needed
    ];
  
   
     // Generate a list of years
     const years = Array.from({ length: 10 }, (_, index) => new Date().getFullYear() + index);
     const questionsPerPage = 7;
  
     const [currentPage, setCurrentPage] = useState(1);
     const indexOfLastQuestion = currentPage * questionsPerPage;
     const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
     const [currentQuestions,setCurrentQuestions]=useState([]);
     const [totalPages,setTotalPages]=useState();
    
     const classes = useStyles();


     


     
     if (user_details) {
      user_details = JSON.parse(user_details);
      console.log(user_details.Email);
    } else {
      console.log("user_details not found in local storage");
    }
    // const [selectedYear1, setSelectedYear1] = useState(null);

    // const handleYearChange = (date) => {
    //   setSelectedYear1(date.getFullYear());
    // };

   
    const [graduate,setGraduate]=useState("");
    const [branch,setBranch]=useState("");
  const [ratings, setRatings] = useState({});
  const [ratings2, setRatings2] = useState({});
  const [staff,setStaff]=useState("");
  const [staffname,setStaffName]=useState("");
  const [lab,setLab]=useState("");
  const [work,setWork]=useState("")
  const [organisation,setOrganisation]=useState("")
  const [datestart,setDateStart]=useState("")
  const [dateend,setDateEnd]=useState("")
  const [comment,setComment]=useState("")
  const [fee,setFee]=useState("")
  const [deadline,setDeadline]=useState("")
  const [quality,setQuality]=useState("")
  const [approach,setApproach]=useState("")


  useEffect(()=>{
    const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
    setCurrentQuestions(currentQuestions_1);
    const totalPages_1 = Math.ceil(Questionset_api.length / questionsPerPage);
    setTotalPages(totalPages_1)
  
  },[]);

  //needs to comment
  useEffect(()=>{
    setRatings((prevRatings) => ({
        ...prevRatings,
        ['q5']: "0",
      }));
   
  
  },[]);
  
  


          const handleStaff = (event) => {
            setStaff(event)
         
        
          };
          const handleComment = (event) => {
            setComment(event)
         
        
          };
          
          
          const handleDateStart = (event) => {
            setDateStart(event)
         
        
          };
          
          const handleOrganisation = (event) => {
            setOrganisation(event)
         
        
          };
          const handleLab= (event) => {
            setLab(event)
         
        
          };
          const handleWork= (event) => {
            setWork(event)
         
        
          };
         
          const handleDateEnd= (event) => {
            setDateEnd(event)
         
        
          };
          const handleProgramme = (event) => { 
         
        
          };
          
        
          const handleGraduate=(event)=>{
            setGraduate(event)
          }
          const handlebranch=(event)=>{
            setBranch(event)
          }
          
          const handleFeeChange=(event)=>{
            setFee(event.target.value)
          }
          const handleDeadlineChange=(event)=>{
            setDeadline(event.target.value)
          }
          const handleQualityChange=(event)=>{
            setQuality(event.target.value)
          }
          const handleApproachChange=(event)=>{
            setApproach(event.target.value)
          }

          

          const handleRatingChange = (quesId, value) => {
           
            
            const questionKey = `q${quesId + 1}`;
            setRatings((prevRatings) => ({
              ...prevRatings,
              [questionKey]: value,
            }));

            setRatings2((prevRatings2)=>({
              ...prevRatings2,
              [quesId]: value,
            }))
            console.log(ratings, "ratingsss");
          };
        

        
        

         const handleSubmit=()=>{
       


          const isAnyRatingNull = Object.values(ratings).some((rating) => rating === "");

          if(lab!=""){
            if(organisation!=""){

              if(work!=""){
              if(datestart!=""){


                if(dateend!=""){
                  if(fee!=""){
                    if(deadline!=""){
                      if(quality!=""){
                        if(approach!=""){
          // alert(isAnyRatingNull)

          
      
 
          
          fetch('https://feedbackapi.rcktechiees.com/api/OtherFeedback/consultancy_feedback', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
              "work":(work),
              "lab_department":(lab),
          "name_organisation":(organisation),
          "date_commence":(datestart),
          "date_complete":(dateend),
        "q1":(fee),
        "q2":(deadline),
        "q3":(quality),
        "q4":(approach),
      
          
         
          
         

            })
          })
          .then(response => response.json())
          
          .then(data => {
          
            {console.log(data,"responseupload")}
            if(data["Status"]=="1"){
              toast.success("Successfully Submitted")
            }
            else if(data["Status"]=="0"){
              toast.error("Something went wrong")
            }
          
           
          
           
          
          
          })
           .catch(error=>console.log(error))
        }
        else{
          toast.error("Please choose the right option for approach")
        }
        }
        else{
          toast.error("Please choose the right option for work quality")
        }
        }
        else{
          toast.error("Please choose the right option for meeting deadline")
        }
        }
        else{
          toast.error("Please choose the right option for consulting fee")
        }

        }
        else{
          toast.error("Please fill the completion data")
        }
        }
        else{
          toast.error("Please fill the commencement data")
        }
      }
      else{
        toast.error("Please choose the nature of work")
      }
        }
        else{
          toast.error("Please fill the consulting organisation name")
        }
        }
        else{
          toast.error("Please choose the department undertaking the work")
        }
        


          //  toast.error("Something went wrong")


         
        }
 
         const handlePageChange = (pageNumber) => {
         
          setCurrentPage(pageNumber);
  const indexOfLastQuestion = pageNumber * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions_1 = Questionset_api.slice(indexOfFirstQuestion, indexOfLastQuestion);
  setCurrentQuestions(currentQuestions_1);
        };
      
      
         const Pagination = ({ currentPage, totalPages, onPageChange }) => {
          const pageNumbers = [];
      
          for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
      
          return (

         
          
            <Box
           
            display="flex" justifyContent="center" mt="20px"
           
          >
              {pageNumbers.map((pageNumber) => (
                 <Button variant="contained"
                  key={pageNumber}
                  onClick={() => onPageChange(pageNumber)}
                  disabled={currentPage === pageNumber}
                  className={classes.customButton}
                  sx={{  justifyContent: "center"}}
   
                >
                  {pageNumber}
                  </Button>
              ))}
            </Box>
          );
        };
      
      
      
      
      
      
      


  return (
   <>
      <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themes}>
              <CssBaseline />
              <div className="app1">
                <Sidebar2 isSidebar={isSidebar} feedback_type={"consultancy"}/>
                <main className="content1">
                    <Topbar setIsSidebar={setIsSidebar} feedback_type={"Consultancy Feedback"}/>
 

                    <Box m="20px"
       display="grid"
       gap="7px"
       gridTemplateColumns="repeat(4, minmax(0, 1fr))"
       sx={{
         "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
       }
       }
       >
  <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Department undertaking the work"
                   value={lab}             
                  
                  
                   onChange={(event) => {handleLab(event.target.value)}}
                   name="Lab"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Company offering consultancy"
                   value={organisation}             
                  
                  
                   onChange={(event) => {handleOrganisation(event.target.value)}}
                   name="organisation"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                


                <FormControl variant="filled" sx={{ gridColumn: "span 2"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
  
        <InputLabel id="demo-simple-select-filled-label">Nature of Work</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={work}
          onChange={(e) => {handleWork(e.target.value)}}
          sx={{
            height: '42px',
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
        
          <MenuItem value={"Testing"}>Testing</MenuItem>
          <MenuItem value={"Fabrication"}>Fabrication</MenuItem>
          <MenuItem value={"Trouble shooting and services"}>Trouble shooting and services</MenuItem>
          <MenuItem value={"Educational Services"}>Educational Services</MenuItem>
          <MenuItem value={"Auditing"}>Auditing</MenuItem>
          <MenuItem value={" Software development"}> Software development</MenuItem>
          <MenuItem value={" Debugging and Testing"}> Debugging and Testing</MenuItem>
          <MenuItem value={"Others"}>Others</MenuItem>
      
         
        </Select>
      </FormControl>
     
   
              <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Date of Commencement"
                   value={datestart}
                 

                   onChange={(e) => {handleDateStart(e.target.value)}}
                   name="Date of Commencement"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Completion Date"
                   value={dateend}
                 

                   onChange={(e) => {handleDateEnd(e.target.value)}}
                   name="Date of Commencement"
                   sx={{ gridColumn: "span 2",
                   "& .MuiInputBase-root.MuiFilledInput-root": {

                                          border: "1px solid grey",
                    
                                          borderRadius: "3px",
                    
                                          backgroundColor: "transparent",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root.Mui-focused": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:hover": {
                    
                                          backgroundColor: "transparent",
                    
                                          borderColor: "#01134d",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:before": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                    
                                        "& .MuiInputBase-root.MuiFilledInput-root:after": {
                    
                                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    
                                        },
                  }}
                   inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}
              />
                
            

     
     
    
   
      {/* <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>


      <h6 >Please give your valuable feedback in a scale</h6>
      <h6>
    5 - Highest
  </h6>
  <h6>
    1 - Lowest
  </h6>
        
      
      </FormControl> */}



{/* <FormControl component="fieldset" className={classes.root}  sx={{  marginBottom: '15px', '& .Mui-checked': {  color: '#f58941 !important', }, gridColumn: "span 12",  }}>
                 <label id="demo-row-radio-buttons-group-label" style={{fontSize:"24px", fontWeight: '600', marginBottom: '15px'}}  >Choose Appropriate Option</label>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="ug_pg"
                      sx={{   '& .Mui-checked': {   color: '#f58941 !important',  }}}
                      // onClick={handleUGChange} 
                      >
                  <FormControlLabel value="Regular" control={<Radio  />} label="Regular" />
                  <FormControlLabel value="Lateral" control={<Radio />} label="Lateral" />
                  <FormControlLabel value="Part Time" control={<Radio />} label="Part Time" />
        
                  </RadioGroup>
</FormControl> */}
<FormControl
  component="fieldset"
  className={classes.root}
  sx={{
    gridColumn: "span 4",
    display: isNonMobile?"grid":"block",
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'center',
    marginBottom: '15px',
    marginTop: "1%"
  }}
>
<Typography
    id="demo-row-radio-buttons-group-label"
    style={{
      fontSize: '17px',
      fontWeight: '600',
      marginRight: '15px',
    }}
    sx={{
      gridColumn: "span 2",
    }}
  >
    1. Consultancy Fee<br />
    <span style={{ fontSize: '14px' }}>(நிறுவன கட்டணம்)</span>
  </Typography>
  <RadioGroup
    row
    aria-labelledby="demo-row-radio-buttons-group-label"
    name="ug_pg"
    onChange={handleFeeChange}  
    sx={{
      '& .Mui-checked': {
        color: '#f58941 !important',
      },
      gridColumn: "span 2",
    }}
  >
    <FormControlLabel value="Low" control={<Radio />} label="Low" />
    <FormControlLabel value="Medium" control={<Radio />} label="Medium" />
    <FormControlLabel value="High" control={<Radio />} label="High" />
  </RadioGroup>
</FormControl>

<FormControl
  component="fieldset"
  className={classes.root}
  sx={{
    gridColumn: "span 4",
    display: isNonMobile?"grid":"block",
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'center',
    marginBottom: '15px',
    marginTop: "1%"
  }}
>
<Typography
    id="demo-row-radio-buttons-group-label"
    style={{
      fontSize: '17px',
      fontWeight: '600',
      marginRight: '15px',
    }}
    sx={{
      gridColumn: "span 2",
    }}
  >
   2. Meeting Deadline<br />
    <span style={{ fontSize: '14px' }}>(காலக்கெடு)</span>
  </Typography>
  
  <RadioGroup
    row
    aria-labelledby="demo-row-radio-buttons-group-label"
    name="ug_pg"
    onChange={handleDeadlineChange}  
    sx={{
      '& .Mui-checked': {
        color: '#f58941 !important',
      },
      gridColumn: "span 2",
    }}
  >
    <FormControlLabel value="Delayed work" control={<Radio />} label="Delayed work" />
    <FormControlLabel value="Prompt" control={<Radio />} label="Prompt" />
    <FormControlLabel value="Before time" control={<Radio />} label="Before time" />
  </RadioGroup>
</FormControl>
<FormControl
  component="fieldset"
  className={classes.root}
  sx={{
    gridColumn: "span 4",
    display: isNonMobile?"grid":"block",
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'center',
    marginBottom: '15px',
    marginTop: "1%"
  }}
>
<Typography
    id="demo-row-radio-buttons-group-label"
    style={{
      fontSize: '17px',
      fontWeight: '600',
      marginRight: '15px',
    }}
    sx={{
      gridColumn: "span 2",
    }}
  >
    3. Work Quality<br />
    <span style={{ fontSize: '14px' }}>(வேலை தரம்)</span>
  </Typography>

  <RadioGroup
    row
    aria-labelledby="demo-row-radio-buttons-group-label"
    name="ug_pg"
    onChange={handleQualityChange}  
    sx={{
      '& .Mui-checked': {
        color: '#f58941 !important',
      },
      gridColumn: "span 2",
    }}
  >
    <FormControlLabel value="Needs Improvement" control={<Radio />} label="Needs Improvement" />
    <FormControlLabel value="Good" control={<Radio />} label="Good" />
    <FormControlLabel value="Very Good" control={<Radio />} label="Very Good" />
  </RadioGroup>
</FormControl>

<FormControl
  component="fieldset"
  className={classes.root}
  sx={{
    gridColumn: "span 4",
    display: isNonMobile?"grid":"block",
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'center',
    marginBottom: '15px',
    marginTop: "1%"
  }}
>
<Typography
    id="demo-row-radio-buttons-group-label"
    style={{
      fontSize: '17px',
      fontWeight: '600',
      marginRight: '15px',
    }}
    sx={{
      gridColumn: "span 2",
    }}
  >
   4. Approach<br />
    <span style={{ fontSize: '14px' }}>(அணுகுமுறை)</span>
  </Typography>

  <RadioGroup
    row
    aria-labelledby="demo-row-radio-buttons-group-label"
    name="ug_pg"
    onChange={handleApproachChange}  
    sx={{
      '& .Mui-checked': {
        color: '#f58941 !important',
      },
      gridColumn: "span 2",
    }}
  >
    <FormControlLabel value="Needs Improvement" control={<Radio />} label="Needs Improvement" />
    <FormControlLabel value="Fair" control={<Radio />} label="Fair" />
    <FormControlLabel value="Cordial " control={<Radio />} label="Cordial " />
  </RadioGroup>
</FormControl>





      {/* {currentQuestions.map((item) => (
        <FormControl variant="filled" sx={{ gridColumn: 'span 4' }} key={item.Ques_id}>
          {item.Ques_id + 1}. {item.Question}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '1' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '1')}
            >
              <i
                className={`bi bi-1-circle${ratings2[item.Ques_id] === '1' ? ' selected' : ''}`}
                aria-label="1"
              />
            
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '2' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '2')}
            >
              <i
                className={`bi bi-2-circle${ratings2[item.Ques_id] === '2' ? ' selected' : ''}`}
                aria-label="2"
              />
         
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '3' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '3')}
            >
              <i
                className={`bi bi-3-circle${ratings2[item.Ques_id] === '3' ? ' selected' : ''}`}
                aria-label="3"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '4' ? 'green':"",
               
              }}
              onClick={() => handleRatingChange(item.Ques_id, '4')}
            >
              <i
                className={`bi bi-4-circle${ratings2[item.Ques_id] === '4' ? ' selected' : ''}`}
                aria-label="4"
              />
             
            </div>
            <div
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: ratings2[item.Ques_id] === '5' ? 'green':"",
              }}
              onClick={() => handleRatingChange(item.Ques_id, '5')}
            >
              <i
                className={`bi bi-5-circle${ratings2[item.Ques_id] === '5' ? ' selected' : ''}`}
                aria-label="5"
              />
             
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{color: "green"}}>{ratings2[item.Ques_id] === '1' && <Box>{labels[0]}</Box>}</div>
      <div  style={{color: "green"}}>{ratings2[item.Ques_id] === '2' && <Box>{labels[1]}</Box>}</div>
       <div  style={{color: "green"}}>{ratings2[item.Ques_id] === '3' && <Box>{labels[2]}</Box>}</div>
       <div  style={{color: "green"}}>{ratings2[item.Ques_id] === '4' && <Box>{labels[3]}</Box>}</div>
       <div  style={{color: "green"}}>{ratings2[item.Ques_id] === '5' && <Box>{labels[4]}</Box>}</div>
    
    </div>
            </div>
        </FormControl>
      ))}  
       */}

      {currentPage==totalPages && (
      <FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>

<Box display="flex" justifyContent="center" mt="20px" mb="20px"  sx={{ '& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'}, }}>
                          <Button  sx={{'& .MuiButtonBase-root-MuiButton-root':{minWidth: '150px !important'},}} type="button" color="secondary" variant="contained" minWidth="150px !important" className={`${classes.button}`} 
   onClick={handleSubmit}>
                          Submit
                          </Button> 
                          <ToastContainer />
                      {/* </Box> 
              <ToastContainer /> */}
    </Box>
    </FormControl>
    )} 

<FormControl variant="filled" sx={{ gridColumn: "span 4" ,alignItems: "center" }} inputProps={{
                    style: {
                      height: "9px",
                     
                    },
                  }}>
   {console.log(currentPage,"cureent",totalPages)}
   {totalPages==1?null:
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />}
     </FormControl>
     
             
    </Box>   
    </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
 
  
   </>
  );
}
export default Consultancy;
