import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsExclamationTriangle } from "react-icons/bs";
import Popup from "reactjs-popup";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import { Box,  TextField,Typography, useTheme,MenuItem,Select,FormControl,InputLabel } from "@mui/material";
import Button from "@mui/material/Button";

import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
// import Button from 'react-bootstrap/Button';



import "react-widgets/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";



// --------------------MONTHLY VIEW TABLE--------------------------//
function Basic_tab({
  tabss,
  selectDepartment,
  SetselectDepartment,
  selectRegulation,
  SetselectRegulation,
  selectSemester,
  SetselectSemester,
  selectUG,
 SetselectUG,
 selectFT,
 SetselectFT,
 enableNext,
 setenableNext,
 elective=[],
 course_list=[],
 filteredMenuItems=[]


}) 
{
  let department_id;
  let regulation_id;
//  let elective=[];
//  let course_list=[];
//  let filteredMenuItems=[];

  

  let user_details = localStorage.getItem("user_details");
     
if (user_details) {
  user_details = JSON.parse(user_details);
  console.log(user_details.Email);
} else {
  console.log("user_details not found in local storage");
}
    const[regulation,setRegulation]=useState("");
    // const[department,setDepartment]=useState("");
    const[semester,setSemester]=useState("");
    

  
    const handleRegulation=(e)=>{
      SetselectRegulation(e)
     }

     const handleDepartment=(e)=>{
        console.log(e,"e")
        SetselectDepartment(e)
     }

     const handleSemester=(e)=>{
      SetselectSemester(e)
     }
     const handleUG=(e)=>{
      SetselectUG(e)
     }

     const handleFT=(e)=>{
      SetselectFT(e)
      // getSubject()
     }
     const handleSubmit=(e)=>{
       console.log(selectDepartment,"depatrtment",selectRegulation,"regulation",selectSemester,"selectSemester",selectUG,selectFT)
       alert(selectDepartment,selectRegulation,selectSemester,selectUG,selectFT)
     }
     const isNonMobile = useMediaQuery("(min-width:600px)");

     useEffect(() => {

      if(selectRegulation!=="" && selectDepartment!=="" && selectSemester!==""  && selectUG!=="" ){
        setenableNext(1)

      }
      else if(selectRegulation==="" || selectDepartment==="" || selectSemester===""  || selectUG==="" ){
        setenableNext(0)

      }
      
    },[selectRegulation,selectDepartment,selectSemester,selectFT,selectUG]);

//     if(selectDepartment=="ece"){
//       // alert(selectDepartment)
//       department_id=1;
    
//     }
//     else  if(selectDepartment=="cse"){
    
//       department_id=2;
    
//     }
//     else  if(selectDepartment=="eee"){
     
//       department_id=3;
    
//     }
//     else  if(selectDepartment=="civil"){
    
//       department_id=4;
    
//     }
//     else  if(selectDepartment=="mech"){
    
//       department_id=5;
    
//     }
//     else{
    
//     }
//     if(selectRegulation==2019){
//       regulation_id=1;
//     }
   
//     const getSubject=()=>{
//       alert(user_details.Register_Number)
        
    
//       fetch(' https://feedbackapi.rcktechiees.com/api/student/UploadData', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*'
//       },
//       body: JSON.stringify({
//         "regulation_id": (regulation_id),
//         "department_id": (department_id),
//         "ug_pg": (selectUG),
//         "semester": (selectSemester),
//         "reg_no": (user_details.Register_Number)
//       })
//     })
//     .then(response => response.json())
//     // .then(data => console.log(data,"sub"))
//     .then(data => {
    
//       console.log(data);
//       console.log(data,"resposne1111111111")
    
    
    
    
//       // const menuItems = ['Project', 'Professionl elective Group A', 'OPEN ELECTIVE'];
      
//       // create a new array without the "Professionl elective Group A" item
    
     
    
      
//       const result = {};
    
//       for (const key in data) {
//         {console.log(key,"key")}
//         course_list.push(key);
//         console.log(course_list,"course123")
       
//       }
     
//       filteredMenuItems = course_list.filter(item => item !== 'Professionl elective Group A' && item !== 'OPEN ELECTIVE' && item !== 'Professionl elective Group B');
//       elective=course_list.filter(item => item === 'Professionl elective Group A' || item === 'OPEN ELECTIVE' || item === 'Professionl elective Group B');
//       console.log(course_list,"course",filteredMenuItems,"filter11",elective)

   
      
//       console.log(result,"result");
//       if(result["OPEN ELECTIVE"]){
//         console.log(result["OPEN ELECTIVE"],"sub2")
//       }
      
//       // let OTP_status;
//       // OTP_status=data[1];
//       // if(OTP_status){
//       //   console.log(OTP_status,"status")
     
//       // }
//     })
//     .catch(error => console.error(error,"err"))
    
     
//     }




//     useEffect(() => {

    

// if(selectDepartment!=="" && selectRegulation!=="" && selectSemester!=="" && selectUG!==""){
    
//       getSubject();
//     }
      
//     },[selectDepartment,selectRegulation,selectSemester,selectUG]);
  // useEffect(() => {
  
  // }, [selectDepartment]);
  /////////////////////////////////////////////

  return (
   <>

<Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >

<FormControl variant="filled" sx={{ gridColumn: "span 2"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Regulation</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectRegulation}
          onChange={(e) => {handleRegulation(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
           
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
        
          <MenuItem value={1}>2019</MenuItem>
         
        </Select>
      </FormControl>
      <FormControl variant="filled" sx={{ gridColumn: "span 2"  }} inputProps={{
                    style: {
                      height: "9px",
                    },
                  }}>
        <InputLabel id="demo-simple-select-filled-label">Department</InputLabel>
        <Select
        required
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectDepartment}
          onChange={(e) => {handleDepartment(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
            
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
        
          <MenuItem value={4}>Civil</MenuItem>
          <MenuItem value={5}>Mechanical</MenuItem>
          <MenuItem value={1}>ECE</MenuItem>
          <MenuItem value={3}>EEE</MenuItem>
          <MenuItem value={2}>CSE</MenuItem>
         
        </Select>
      </FormControl>
      <FormControl variant="filled" sx={{ gridColumn: "span 2"  }} >
        <InputLabel id="demo-simple-select-filled-label">Semester</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectSemester}
          onChange={(e) => {handleSemester(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
            
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
        
          <MenuItem value={"1"}>1</MenuItem>
          <MenuItem value={"2"}>2</MenuItem>
          <MenuItem value={"3"}>3</MenuItem>
          <MenuItem value={"4"}>4</MenuItem>
          <MenuItem value={"5"}>5</MenuItem>
          <MenuItem value={"6"}>6</MenuItem>
          <MenuItem value={"7"}>7</MenuItem>
          <MenuItem value={"8"}>8</MenuItem>
         
        </Select>
      </FormControl>
      <FormControl variant="filled" sx={{ gridColumn: "span 2"  }} >
        <InputLabel id="demo-simple-select-filled-label">UG/PG</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectUG}
          onChange={(e) => {handleUG(e.target.value)}}
          // value={selectRegulation}
          // onChange={handleRegulation}
          sx={{
           
            '&.MuiFilledInput-root': {
              border: '1px solid grey',
              borderRadius: '3px',
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#01134d',
              },
              '&::before': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
              '&::after': {
                borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
              },
            },
          }}
        >
        
          <MenuItem value={"UG"}>UG</MenuItem>
          <MenuItem value={"PG"}>PG</MenuItem>
          <MenuItem value={"PT"}>Part Time</MenuItem>
         
        </Select>
      </FormControl>
      {/* <FormControl variant="filled" sx={{ gridColumn: "span 2"  }} >
        <InputLabel id="demo-simple-select-filled-label">FT/Non FT</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectFT}
          onChange={(e) => {handleFT(e.target.value)}}
         
        >
        
          <MenuItem value={"FT"}>FT</MenuItem>
          <MenuItem value={"Non-FT"}>Non FT</MenuItem>
         
        </Select>
      </FormControl> */}
   
              {/* <TextField
                   fullWidth
                   variant="filled"
                   type="text1"
                   label="Regulation"
                   onChange={handleRegulation}  
                   name="regulation"
                   sx={{ gridColumn: "span 2" }}
              />
             
              
            
           
    
              <TextField
                fullWidth
                variant="filled"
                type="text1"
                label="Department"
                value={selectDepartment}
                onChange={(e) => {handleDepartment(e.target.value)}}
     
                name="department"
                sx={{ gridColumn: "span 2" }}
              />
            


            
              <TextField
                fullWidth
                variant="filled"
                type="text1"
                label="Semester"
                onChange={handleSemester}
                name="semester"  
                sx={{ gridColumn: "span 2" }}
              /> */}
            
    </Box>
    {/* <Box display="flex" justifyContent="center" mt="20px">
              <Button type="button" color="secondary" variant="contained" onClick={handleSubmit}>
              Submit
              </Button>
            </Box> */}

   </>
  );
}
export default Basic_tab;
